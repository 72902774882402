import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import styles from './style';
import { ContainerLogin } from './style';
import LoginCarousel from './component/carousel';
import RecoverForm from './component/form';
import useUser from 'hook/user';
import { ModalEmailalert } from './component/modalEmails';
import { useHistory } from 'react-router-dom';
const logo = require('asset/image/logo.png');

export default function RecoverEmailPage() {
  const classes = styles();
  const { getEmailUsers, loading, status, data, msg, setMsg } = useUser();
  const onSubmit = (data) => {
    getEmailUsers({ cpf: data.cpf });
  };
  const [modalEmailsx, setModalEmailsx] = React.useState(false);
  React.useEffect(() => {
    if (msg !== '' && status === 200) {
      setModalEmailsx(true);
    }
  }, [msg]);

  const history = useHistory();
  const handleCloseModal = () => {
    history.goBack();
    setMsg('');
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        className="bg-primary bg-onboard_bg_img bg-no-repeat bg-cover bg-center"
      >
        <LoginCarousel />
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <ContainerLogin>
            <img src={logo} alt="" className="mb-11 h-20 w-[100%]" />
            <RecoverForm
              handleSubmit={onSubmit}
              error={null}
              loading={loading}
              status={status}
            />
          </ContainerLogin>
        </div>

        <ModalEmailalert
          messageTitleAlert={
            msg === 'Query made successfully'
              ? 'Seus emails são os seguintes'
              : 'Não possui este cpf cadastrado'
          }
          options={data.length > 0 ? data : []}
          openSendFinish={modalEmailsx}
          handleCloseSendFinish={handleCloseModal}
          error={msg === 'Query made successfully' ? false : true}
        />
      </Grid>
    </Grid>
  );
}
