import React, { useState } from 'react';
import moment from 'moment';
import InvoicesForm from 'shared/forms/InvoicesForm';
import Layout from 'shared/component/Layout';
import { useExportPdf } from 'hook/exportPdf';
import MessageNotification from 'shared/component/messageNotification';
import GeneratePdfExport from 'shared/component/GeneratePdfExport';
import bodyEmployDoctorPdf from 'shared/component/GeneratePdfExport/bodyEmployDoctorPdf';
import bodyEmployPdf from 'shared/component/GeneratePdfExport/bodyEmployPdf';
import bodyPdfInvoices from 'shared/component/GeneratePdfExport/bodyPdfInvoices';
import footerPdf from 'shared/component/GeneratePdfExport/footerPdf ';

const InvoicesPage = () => {
  const [statusError, setStatusError] = useState(false);
  const { getExportPdfListCompany, getExportPdfList } = useExportPdf();

  const onFormSubmit = async (values) => {
    let response = {};
    if (!values.unit) {
      response = await getExportPdfListCompany(values);
    } else {
      response = await getExportPdfList(values);
    }

    if (response.data.data.length === 0) {
      setStatusError(true);
      return;
    }

    const formatDateStart = moment(Date.now()).format('DD/MM/YYYY');
    const isEmploy = values.employ;
    const isType = values.type;
    if (isEmploy === 'none') {
      const logo = values.company.image_url;
      const unitsNames = response.data.data.unit_name;
      const dateStart = values.start_date;
      const dateEnd = values.end_date;

      GeneratePdfExport(
        bodyPdfInvoices(
          response.data.data,
          formatDateStart,
          response.data.total,
          logo,
          unitsNames,
          dateStart,
          dateEnd
        ),
        footerPdf(response.data.total)
      );
    }
    if (isEmploy === 'employ' && isType === '2') {
      GeneratePdfExport(
        bodyEmployDoctorPdf(response.data.data[0]),
        footerPdf(response.data.data[0].total_doctors_unit)
      );
    }
    if (isEmploy === 'employ' && isType === '3') {
      GeneratePdfExport(
        bodyEmployPdf(response.data.data[0]),
        footerPdf(response.data.total)
      );
    }
  };

  return (
    <Layout title="Faturamento" showToday backArrow>
      <InvoicesForm
        onSubmit={onFormSubmit}
        loadingExportPdf={false}
        submitText="Gerar Pdf"
      />
      <MessageNotification
        openNotification={statusError}
        closeNotification={() => setStatusError(false)}
        type={'error'}
        message={'Sem resultados encontrados!'}
        vertical={10}
        horizontal="40vw"
      />
    </Layout>
  );
};

export default InvoicesPage;
