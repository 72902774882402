import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  useStyles,
  SearchContainer,
  TableControl,
  InputContainer,
} from './styles';
import Layout from 'shared/component/Layout';
import DefaultTable from 'shared/component/DefaultTable';
import SearchInputDebounce from 'shared/component/forms/SearchInputDebounce';
import { Button, Typography, TableCell, Tooltip } from '@material-ui/core';
import StatusSwitch from 'shared/component/StatusSwitch';
import CustomSelect from 'shared/component/forms/CustomSelect';
import HasPermission from 'utils/checkPermission';
import { useGroupsUser } from 'hook/groupsUser';
import { ModalForm } from './Components';

export default function GroupsListPage() {
  const classes = useStyles();
  const [statusCompany, setStatusCompany] = useState({
    label: 'Todos',
    value: 'all',
  });

  const permissions = {
    read: 'groups/r',
    create: 'groups/c',
    delete: 'groups/d',
    update: 'groups/u',
  };

  const Agencies = ({ agencies }) => {
    if (!agencies || !agencies.length > 0) return <TableCell>...</TableCell>;
    const names = agencies.map((item) => item?.name).join(' , ');
    return <TableCell>{names}</TableCell>;
  };

  const columns = ({ handleChangeStatus }) => [
    { id: 'id', label: '#', minWidth: 10 },
    { id: 'name', label: 'Nome', minWidth: 170 },
    {
      id: 'type',
      label: 'Tipo',
      minWidth: 10,
      render: (rowData) => (
        <TableCell>{rowData.type === 1 ? 'Admin' : 'Profissional'}</TableCell>
      ),
    },
    {
      id: 'need_regulation_agency',
      label: 'Grupo com conselho regulador',
      minWidth: 100,
      render: (rowData) => (
        <TableCell>{rowData.need_regulation_agency ? 'Sim' : 'Não'}</TableCell>
      ),
    },
    {
      id: 'regulation_agencies',
      label: 'Conselhos reguladores',
      minWidth: 100,
      render: (rowData, index) => (
        <Agencies key={index} agencies={rowData?.regulation_agencies} />
      ),
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 10,
      render: (rowData, index) => (
        <StatusSwitch
          key={index}
          changeStatus={handleChangeStatus}
          rowData={{ ...rowData, status: parseInt(rowData?.status) }}
          permissions={permissions}
        />
      ),
    },
  ];

  const statusCompanyType = [
    { label: 'Todos', value: 'all' },
    { label: 'Admin', value: 1 },
    { label: 'Profissionais', value: 2 },
  ];

  const {
    data,
    total,
    loading,
    deleteLoading,
    getList,
    getDelete,
    toggleStatus,
  } = useGroupsUser();

  const [filter, setFilter] = useState({
    page: 1,
    per_page: 10,
    search: '',
    type: 'all',
  });

  const handleGetGroups = useCallback(() => {
    getList(filter);
  }, [filter]);

  useEffect(() => {
    handleGetGroups();
  }, [filter]);

  const searchGroups = (search) => {
    setFilter((old) => ({ ...old, search }));
  };

  const handleChangePage = (page) => {
    setFilter((old) => ({ ...old, page }));
  };

  const handleDeleteGroup = (id) => {
    getDelete({ id });
  };

  const handleChangeStatusGroup = (val) => {
    setStatusCompany(val);
    setFilter((old) => ({ ...old, type: val?.value }));
  };

  const handleChangeStatus = useCallback((status, data) => {
    toggleStatus({ id: data?.id, data, status });
  });

  const ModalGroupsRef = useRef(null);

  const handleOpenModal = useCallback((item) => {
    ModalGroupsRef.current.openModalGroups(item);
  }, []);

  return (
    <Layout title="Grupos" showToday backArrow>
      <>
        <SearchContainer>
          <InputContainer>
            <SearchInputDebounce
              className={classes.inputWidth}
              onChange={searchGroups}
              placeholder="Busque por nome"
              style={{
                alignItems: 'center',
                height: 48,
                width: '90%',
              }}
            />
          </InputContainer>

          <div
            data-cy="select_input_status"
            style={{ width: 300, backgroundColor: '#fff', borderRadius: 16 }}
          >
            <CustomSelect
              name="type"
              loading={false}
              isProf={false}
              placeholder="Selecione o tipo de grupo"
              handleChange={handleChangeStatusGroup}
              value={statusCompany}
              options={statusCompanyType}
              // isClearable
            />
          </div>

          <Tooltip
            title={
              HasPermission(permissions.create)
                ? 'Cadastrar'
                : 'Você não tem permissão'
            }
            placement="bottom"
            arrow
          >
            <Button
              data-cy="btn_register"
              className={classes.registerButton}
              onClick={
                HasPermission(permissions.delete)
                  ? () =>
                      handleOpenModal({
                        name: '',
                        type: 1,
                        status: 1,
                        need_regulation_agency: 0,
                      })
                  : null
              }
            >
              <Typography className={classes.registerText}>
                Cadastrar grupo
              </Typography>
            </Button>
          </Tooltip>
        </SearchContainer>

        <TableControl data-cy="dataTable_regulation_agencies">
          <DefaultTable
            permissions={permissions}
            dataList={data}
            columns={columns({ handleChangeStatus })}
            loading={loading}
            deleteLoading={deleteLoading}
            totalList={total}
            perPage={filter?.per_page}
            redirectTo="/"
            handleChangePage={handleChangePage}
            onDeleteRequest={handleDeleteGroup}
            showExportButton={false}
            handleOpenModal={handleOpenModal}
          />
        </TableControl>
        <ModalForm ref={ModalGroupsRef} handleGetGroups={handleGetGroups} />
      </>
    </Layout>
  );
}
