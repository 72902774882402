const { makeStyles } = require('@material-ui/core');

const useStyles = makeStyles((theme) => ({
  text: {
    minHeight: 100,
    minWidth: 300,
  },
  error: {
    height: 40,
    textAlign: 'center',
    color: 'red',
  },
  submit: {
    textTransform: 'none',
    color: 'white',
    background: theme.palette.primary.main,
    height: 50,
    marginBottom: 20,
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
  forget_password: {
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  modalEmailalert: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  buttonClose: {
    background: '#0F83AD',
    width: '136px',
    height: '32px',
    fontSize: '13px',
    borderRadius: '4px',
    color: '#ffffff',
    textTransform: 'unset',
    marginTop: '32px',
  },
  group: {
    fontSize: 15,
    weight: 400,
    width: 380,
    height: 31,
    color: '#505255',
    fontFamily: 'Poppins',
  },
  company: {
    fontSize: 15,
    weight: 400,
    width: 380,
    height: 50,
    color: '#505255',
    fontFamily: 'Poppins',
  },
  email: {
    fontSize: 15,
    weight: 400,
    width: 380,
    height: 31,

    color: '#505255',
    fontFamily: 'Poppins',
    marginTop: 10,
  },
  iconError: {
    width: '118px',
    height: '118px',
    marginBottom: '19px',
    color: 'red',
  },
  iconSucess: {
    width: '80px',
    height: '80px',
    marginBottom: '19px',
    color: '#0F83AD',
  },
  modalRoot: {
    width: '450px',
    minHeight: '400px',
    padding: '2rem',
    background: '#FFFFFF',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
}));

export default useStyles;
