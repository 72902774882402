import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Box, FormHelperText } from '@material-ui/core';
import { ErrorMessage } from 'formik';
import { customStyles } from './styles';
import { useGroupsUser } from 'hook/groupsUser';

const CustomSelectRegulationAgencies = ({
  name,
  field,
  placeholder,
  handleChange,
  value,
  menuList,
  errors,
  isMulti = false,
  isClearable = true,
  groupId,
  touched,
  handleBlur,
  setFieldTouched,
  setFieldValue,
  display,
}) => {
  const {
    loading: loadingGroups,
    data: dataGroups,
    getOne: getOneGroup,
  } = useGroupsUser();

  const [formattedLabels, setFormattedLabels] = useState([]);

  useEffect(() => {
    if (groupId) {
      getOneGroup({ id: groupId });
    }
  }, [groupId]);

  useEffect(() => {
    if (dataGroups && dataGroups.name) {
      if (dataGroups.need_regulation_agency) {
        setFieldValue('hasRegulationAgency', true);
      }
      const labelCountry = dataGroups?.regulation_agencies?.map((agency) => ({
        label: agency.name,
        value: agency.name,
      }));
      setFormattedLabels(labelCountry);
    }
  }, [dataGroups]);

  return (
    <Box display={display}>
      <Select
        placeholder={placeholder}
        isLoading={loadingGroups}
        loadingMessage={() => 'Carregando'}
        styles={customStyles(menuList)}
        name={name}
        options={formattedLabels}
        onChange={handleChange}
        value={value}
        noOptionsMessage={() => 'Nenhum resultado encontrado'}
        isMulti={isMulti}
        isClearable={isClearable}
        textFieldProps={{
          error: errors && errors[name] && touched[name],
          // label,
          InputLabelProps: {
            shrink: true,
          },
          style: {
            backgroundColor: '#fff',
          },
        }}
        onBlur={handleBlur}
        onFocus={() => setFieldTouched(name, true, false)}
      />
      {field ? (
        <ErrorMessage name={field.name}>
          {() => (
            <FormHelperText error id="component-error-text">
              {errors}
            </FormHelperText>
          )}
        </ErrorMessage>
      ) : (
        <>
          {errors && (
            <ErrorMessage name={name}>
              {() => (
                <FormHelperText error id="component-error-text">
                  {errors}
                </FormHelperText>
              )}
            </ErrorMessage>
          )}
        </>
      )}
    </Box>
  );
};
export default CustomSelectRegulationAgencies;
