import { useState } from 'react';
import api from 'service/api';
import { useAuth } from 'hook/auth';

export const useBirthdays = () => {
  const { requestIntercept } = useAuth();

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const getList = async (params) => {
    try {
      setLoading(true);
      const response = await requestIntercept(
        api.get,
        `/v2/admin/users/birthdays`,
        params
      );
      if (response.status !== 200) {
        throw response;
      }
      setData(response.data.data);
      setTotal(response.data.pagination.total_items);
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      return error;
    }
  };

  return {
    data,
    total,
    loading,
    getList,
  };
};
