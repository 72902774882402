import React, { useState, useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import FooterButton from './FooterButton';
import { ContainerFormModal, InputContainer, InputItem } from './styles';
import { useRegulationAgencies } from 'hook/regulationAgencies/index';
import { ModalResponseAlert } from 'page/Schedule/WeekSchedule/components/ModalResponseAlert';
import CustomSelectGroups from 'shared/component/forms/CustomSelectGroups';

export const formInitialValues = {
  ids: '',
};

const FormClearSyncUsers = ({ handleCloseModal }) => {
  const { loading, status, getSyncClear } = useRegulationAgencies();
  const [selectedIds, setSelectedIds] = useState(null);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);
  const [openModalSendNotification, setOpenModalSendNotification] =
    useState(false);

  useEffect(() => {
    if (status === 200 || status === 201) setOpenModalSendNotification(true);
  }, [status]);

  const handleSubmit = (formData) => {
    const data = [
      ...(formData.ids?.map((item) => ({
        id: item?.value,
      })) ?? []),
    ];

    getSyncClear({ data })
      .then(() => {
        setMessage('Limpeza realizada com sucesso!');
        setError(false);
      })
      .catch(() => {
        setMessage('Erro ao tentar realizar limpeza.');
        setError(true);
      });
  };

  const schema = Yup.object().shape({
    ids: Yup.mixed().required('Obrigatório!'),
  });

  return (
    <ContainerFormModal>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={schema}
        initialValues={formInitialValues}
        validateOnBlur
        values
      >
        {({ setFieldTouched, setFieldValue, errors }) => (
          <Form>
            <InputContainer>
              <InputItem>
                <Field
                  name="ids"
                  style={{ width: '100%' }}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  placeholder={'Selecione os grupos'}
                  errors={errors && errors.ids}
                  value={selectedIds}
                  handleChange={(data) => {
                    setFieldValue('ids', data);
                    setSelectedIds(data);
                  }}
                  label={'Grupo'}
                  menuList={() => ({
                    height: 116,
                    overflowY: 'auto',
                  })}
                  variant="outlined"
                  type="professionals"
                  isMulti
                  component={CustomSelectGroups}
                />
              </InputItem>
            </InputContainer>
            <FooterButton
              loading={loading}
              handleCloseModal={handleCloseModal}
            />
          </Form>
        )}
      </Formik>
      <ModalResponseAlert
        messageTitleAlert={message}
        openSendFinish={openModalSendNotification}
        handleCloseSendFinish={handleCloseModal}
        error={error}
      />
    </ContainerFormModal>
  );
};

export default FormClearSyncUsers;
