import React from 'react';
import { TableLegendItem, Container } from './styles';

export const TableLegend = () => {
  return (
    <Container>
      <TableLegendItem color="#24B8EC">
        <div />
        Realizado
      </TableLegendItem>
      <TableLegendItem color="#993399">
        <div />
        Validado
      </TableLegendItem>
      <TableLegendItem color="#5AC17F">
        <div />
        Ocorrendo
      </TableLegendItem>
      <TableLegendItem color="#FFFF99">
        <div />
        Lançado
      </TableLegendItem>
      <TableLegendItem color="#1F437F">
        <div />
        Agendado
      </TableLegendItem>
      <TableLegendItem color="#84fa84">
        <div />
        Recebido e Aceito
      </TableLegendItem>
      <TableLegendItem color="#FFA07A">
        <div />
        Encaminhado
      </TableLegendItem>
      <TableLegendItem color="#ff0000">
        <div />
        Cancelado
      </TableLegendItem>
      <TableLegendItem color="#FFD700">
        <div />
        Atrasado
      </TableLegendItem>
      <TableLegendItem color="#708090">
        <div />
        Falta
      </TableLegendItem>
    </Container>
  );
};
