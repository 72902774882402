/* eslint-disable no-unused-vars */
import * as Yup from 'yup';
import validators from 'utils/validators';

const validateAgencies = (value, { has_regulation_agency }) => {
  return !!(has_regulation_agency ? !!value : true);
};

export const firstStepSchema = Yup.object().shape({
  name: Yup.string().required('Obrigatório'),
  last_name: Yup.string(),
  email: Yup.string().email('Email inválido').required('Obrigatório'),
  rg: Yup.string(),
  cpf: Yup.string()
    .min(11, 'cpf incompleto')
    .test(...validators.cpfInvalid('cpf inválido'))
    .required('Obrigatório'),
  cell_phone: Yup.string()
    .min(10, 'Telefone Incompleto')
    .required('Obrigatório'),
  birth_date: Yup.string().min(6, 'data incorreta').required('obrigatório'),
  nationality: Yup.object().shape({
    id: Yup.number().required('Obrigatório'),
  }),
  civil_status: Yup.string().required('obrigatório'),
  genre: Yup.string().required('Selecione'),
  address: Yup.object().shape({
    code_post: Yup.string().required('Obrigatório'),
    street: Yup.string().required('Obrigatório'),
    number: Yup.number().typeError('Apenas numeros').required('Obrigatório'),
    district: Yup.string().required('Obrigatório'),
    complement: Yup.string().required('Obrigatório'),
    city: Yup.object().shape({
      id: Yup.number().typeError('Obrigatório').required('Obrigatório'),
      name: Yup.string().typeError('Obrigatório').required('Obrigatório'),
      state: Yup.object().shape({
        id: Yup.number().typeError('Obrigatório').required('Obrigatório'),
        name: Yup.string().typeError('Obrigatório').required('Obrigatório'),
      }),
    }),
  }),
});

export const secondStepSchema = Yup.object().shape({
  professional_type: Yup.string(),
  profile_receiving: Yup.number(),
  profile_receiving_type: Yup.number(),
  regulation_agency: Yup.object()
    .shape({
      id: Yup.string(),
    })
    .test('agency', 'Campo obrigatório!', function (value) {
      const [_, { value: parent2Value }] = this.from;
      return validateAgencies(value?.id, parent2Value);
    }),
  crm: Yup.object().shape({
    professional_type: Yup.string(),
    number: Yup.string().test(
      'agencyNumber',
      'Campo obrigatório!',
      function (value) {
        const [_, { value: parent2Value }] = this.from;
        return validateAgencies(value, parent2Value);
      }
    ),
    state: Yup.string().test(
      'agencyState',
      'Campo obrigatório!',
      function (value) {
        const [_, { value: parent2Value }] = this.from;
        return validateAgencies(value, parent2Value);
      }
    ),
  }),
  company: Yup.object()
    .shape({
      id: Yup.number().typeError('Obrigatório'),
    })
    .test('company', 'Obrigatório', function (value) {
      return !!value?.id;
    }),
  group: Yup.object().test(
    'professional_type',
    'Campo obrigatório!',
    function (value) {
      return (
        this.parent.professional_type === 'doctor' ||
        (this.parent.professional_type !== 'doctor' && value && value?.id)
      );
    }
  ),
  specialties: Yup.array().test(
    'professional_type',
    'Campo obrigatório!',
    function (value) {
      return (
        this.parent.professional_type !== 'doctor' ||
        (this.parent.professional_type === 'doctor' &&
          value &&
          value.length > 0)
      );
    }
  ),
  units: Yup.array().required('Obrigatório'),
});

export const thirdStepSchema = Yup.object().shape({
  bank: Yup.object().shape({
    bank: Yup.object()
      .shape({
        id: Yup.number(),
      })
      .nullable(),
    agency: Yup.string().nullable(),
    agency_check: Yup.string().nullable(),
    account: Yup.string().nullable(),
    account_check: Yup.string().nullable(),
    type: Yup.string().nullable(),
    doc_type: Yup.string().nullable(),
    doc_number: Yup.string().nullable(),
    account_holder: Yup.string().nullable(),
    pix_type: Yup.string().nullable(),
    pix_key: Yup.string().nullable(),
  }),
});

export const completeSchema = Yup.object().shape({
  // FIRST STEP
  name: Yup.string().required('Obrigatório'),
  last_name: Yup.string(),
  email: Yup.string().email('Email inválido').required('Obrigatório'),
  rg: Yup.string(),
  cpf: Yup.string()
    .min(11, 'cpf incompleto')
    .test(...validators.cpfInvalid('cpf inválido'))
    .required('Obrigatório'),
  cell_phone: Yup.string()
    .min(10, 'Telefone Incompleto')
    .required('Obrigatório'),
  birth_date: Yup.string().min(6, 'data incorreta').required('obrigatório'),
  nationality: Yup.object().shape({
    id: Yup.number().required('Obrigatório'),
  }),
  civil_status: Yup.string().required('obrigatório'),
  genre: Yup.string().required('Selecione'),
  address: Yup.object().shape({
    code_post: Yup.string().required('Obrigatório'),
    street: Yup.string().required('Obrigatório'),
    number: Yup.number().typeError('Apenas numeros').required('Obrigatório'),
    district: Yup.string().required('Obrigatório'),
    complement: Yup.string().required('Obrigatório'),
    city: Yup.object().shape({
      id: Yup.number().typeError('Obrigatório').required('Obrigatório'),
      name: Yup.string().typeError('Obrigatório').required('Obrigatório'),
      state: Yup.object().shape({
        id: Yup.number().typeError('Obrigatório').required('Obrigatório'),
        name: Yup.string().typeError('Obrigatório').required('Obrigatório'),
      }),
    }),
  }),
  //SECOND STEP
  professional_type: Yup.string(),
  profile_receiving: Yup.number(),
  profile_receiving_type: Yup.number(),
  regulation_agency: Yup.object().shape({
    id: Yup.string().test('agency', 'Campo obrigatório!', function (value) {
      const [parent1, { value: parent2Value }] = this.from;
      return validateAgencies(value, parent2Value);
    }),
  }),
  crm: Yup.object().shape({
    professional_type: Yup.string(),
    number: Yup.string().test(
      'agencyNumber',
      'Campo obrigatório!',
      function (value) {
        const [parent1, { value: parent2Value }] = this.from;
        return validateAgencies(value, parent2Value);
      }
    ),
    state: Yup.string().test(
      'agencyState',
      'Campo obrigatório!',
      function (value) {
        const [parent1, { value: parent2Value }] = this.from;
        return validateAgencies(value, parent2Value);
      }
    ),
  }),
  creator_relationship: Yup.string(),
  company: Yup.object()
    .shape({
      id: Yup.number().typeError('Obrigatório').required('Obrigatório'),
    })
    .nullable(),
  group: Yup.object().test(
    'professional_type',
    'Campo obrigatório!',
    function (value) {
      return (
        this.parent.professional_type === 'doctor' ||
        (this.parent.professional_type !== 'doctor' && value && value?.id)
      );
    }
  ),
  specialties: Yup.array().test(
    'professional_type',
    'Campo obrigatório!',
    function (value) {
      return (
        this.parent.professional_type !== 'doctor' ||
        (this.parent.professional_type === 'doctor' &&
          value &&
          value.length > 0)
      );
    }
  ),
  units: Yup.array().required('Obrigatório'),
  // THIRD STEP
  bank: Yup.object().shape({
    bank: Yup.object()
      .shape({
        id: Yup.number(),
      })
      .nullable(),
    agency: Yup.string().nullable(),
    agency_check: Yup.string().nullable(),
    account: Yup.string().nullable(),
    account_check: Yup.string().nullable(),
    type: Yup.string().nullable(),
    doc_type: Yup.string().nullable(),
    doc_number: Yup.string().nullable(),
    account_holder: Yup.string().nullable(),
    pix_type: Yup.string().nullable(),
    pix_key: Yup.string().nullable(),
  }),
});
