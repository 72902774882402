export default function footerPdf(totalExportPdf) {
  let FooterRelatory = '';

  FooterRelatory += `</br></br></br>
    <div class="divHeader">
      <div style="padding-top: 8px" class="columnLeft">TOTAL GERAL:</div>
      <div style="padding-top: 8px" class="columnRight">${
        totalExportPdf || ''
      }</div>
    </div>`;

  const Style = `
    <style>
      .divHeader {
        display: flex; width: 100%; margin-bottom: 5px; min-height: 30px; font-size: 18px; font-weight: bold; color: #18191A;
      }

      .columnLeft {
        width: 40%; background-color: #dbeef4; box-shadow: 0 0 0 1000px #dbeef4 inset; text-align: center; padding-top: 5px; color: #18191A;
      }
  
      .columnRight {
        width: 60%; background-color: #dbeef4; box-shadow: 0 0 0 1000px #dbeef4 inset; text-align: center; color: #18191A; padding-top: 5px;
      }
    </style>`;

  const bodyContent = FooterRelatory + Style;
  return bodyContent;
}
