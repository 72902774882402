import { makeStyles } from '@material-ui/core/styles';
import { appImages } from 'asset';
import styled from 'styled-components';

export const styles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  logo: {
    marginBottom: 85,
    height: '80px',
  },
  image: {
    backgroundColor: '#24B8EC',
    backgroundImage: `url(${appImages.onboard})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(20, 35, 20, 10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const ContainerLogin = styled.div`
  text-align: flex-start;
  margin-left: 14%;

  @media (max-width: 1920px) {
    padding-top: 4%;
    margin-left: 14%;
  }
  @media (max-width: 1440px) {
    padding-top: 8%;
    margin-left: 18%;
  }
  @media (max-width: 1366px) {
    padding-top: 5%;
    margin-left: 14%;
  }
  @media (max-width: 1024px) {
    padding-top: 7%;
    margin-left: 250%;
  }
  @media (max-width: 1600px) {
    padding-top: 7%;
  }
`;
export default styles;
