/* eslint-disable no-unreachable */
import { useState } from 'react';
import api from 'service/api';
import { useAuth } from '../auth';
import moment from 'moment';

export const useAgenda = () => {
  const { requestIntercept } = useAuth();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(0);
  const [status, setStatus] = useState('');
  const processResponseData = (data, period) =>
    new Promise((resolve, reject) => {
      try {
        if (data && data.length > 0) {
          const processedData = data.map((d) => [
            ...d.map((item) => ({
              ...item,
              ...period,
              schedules: [
                ...item?.schedules.map((sched) => [
                  ...sched.map((s) => ({
                    ...s,
                    agenda: item?.agendas.filter(
                      (ag) => ag.schedule_id === s.id
                    ),
                  })),
                ]),
              ],
            })),
          ]);
          resolve(processedData);
        } else {
          resolve([]);
        }
      } catch (err) {
        reject([]);
      }
    });

  const getAgenda = async (params, showLoading = true) => {
    try {
      setLoading(showLoading);
      const response = await requestIntercept(
        api.get,
        `/v2/admin/agendas`,
        params
      );
      if (response.status !== 200) {
        throw response;
      }
      const processedData = await processResponseData(response?.data?.data, {
        date_start: params.date_start,
        date_end: params.date_end,
      });
      setData(processedData);
      setTotal(response?.data?.total);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const createAgenda = async (formData) =>
    new Promise((resolve, reject) => {
      (async function () {
        try {
          setLoading(true);

          const yearMonth = formData.yearMonth;

          const response = await requestIntercept(api.post, `/v1/agendas`, {
            ...formData,
            subsequent: null,
            yearMonth: null,
            month_day_start: moment(
              `${yearMonth?.year}-${yearMonth?.month}`,
              'yyyy-MM'
            )
              .startOf('month')
              .format('yyyy-MM-DD'),
            month_day_end: moment(
              `${yearMonth?.year}-${yearMonth?.month}`,
              'yyyy-MM'
            )
              .endOf('month')
              .format('yyyy-MM-DD'),
          });

          if (response.status !== 200 && response.status !== 201) {
            throw response;
          }

          setTimeout(() => {
            setLoading(false);
            resolve(response);
          }, 500);
        } catch (error) {
          setLoading(false);
          setError(error);
          reject('error');
        }
      })();
    });

  const deleteAgenda = async ({ id, comment }) =>
    new Promise((resolve, reject) => {
      (async function () {
        try {
          setLoading(true);
          const response = await requestIntercept(
            api.delete,
            `/v1/agendas/${id}`,
            {},
            {
              data: {
                comment,
              },
            },
            true
          );

          if (response?.status !== 200 && response?.status !== 201) {
            throw response;
          }
          setTimeout(() => {
            setLoading(false);
            resolve(response);
          }, 500);
        } catch (error) {
          setLoading(false);
          setError(error);
          reject(error);
        }
      })();
    });

  const deleteManyAgendas = async ({ agendas, comment }) =>
    new Promise((resolve, reject) => {
      (async function () {
        try {
          setLoading(true);
          const response = await requestIntercept(
            api.delete,
            `/v2/admin/agendas`,
            {},
            {
              data: {
                comment,
                agendas,
              },
            }
          );

          if (response?.status !== 200 && response?.status !== 201) {
            throw response;
          }
          setTimeout(() => {
            setLoading(false);
            resolve(response);
          }, 500);
        } catch (error) {
          setLoading(false);
          setError(error);
          reject(error);
        }
      })();
    });

  return {
    loading,
    data,
    total,
    error,
    status,
    setData,
    setStatus,
    getAgenda,
    createAgenda,
    deleteAgenda,
    deleteManyAgendas,
  };
};
