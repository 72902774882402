import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import DailyScaleCard from 'shared/component/dailyScaleCard';
import { DashboardV2PageContext } from 'page/dashboardV2/index';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 25,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function BottomDetailsReportGrid() {
  const classes = useStyles();
  const { dataUnitAgenda } = useContext(DashboardV2PageContext);
  const [diaAnterior, setDiaAnterior] = useState([]);
  const [diaAtual, setDiaAtual] = useState([]);
  const [diaSeguinte, setDiaSeguinte] = useState([]);

  const classificarPorDia = () => {
    const dataAtual = moment();
    const novoDiaAnterior = [];
    const novoDiaAtual = [];
    const novoDiaSeguinte = [];

    dataUnitAgenda[0][0].agendas.forEach((item) => {
      const dataItem = moment(item.date_start);
      const isAnterior = dataItem.isBefore(dataAtual, 'day');
      const isAtual = dataItem.isSame(dataAtual, 'day');

      if (item.situation_status === 4 || isAnterior) {
        novoDiaAnterior.push(item);
      } else if (item.situation_status === 3 || isAtual) {
        novoDiaAtual.push(item);
      } else {
        novoDiaSeguinte.push(item);
      }
    });

    setDiaAnterior(novoDiaAnterior);
    setDiaAtual(novoDiaAtual);
    setDiaSeguinte(novoDiaSeguinte);
  };

  useEffect(() => {
    classificarPorDia();
  }, [dataUnitAgenda[0][0].agendas]);

  return (
    <div className={classes.root}>
      <Grid justifyContent="flex-start" container spacing={3}>
        <Grid data-cy="div_metricas" item xs={4}>
          <DailyScaleCard
            title="Anterior"
            details={diaAnterior}
            fullData={dataUnitAgenda[0][0]}
          />
        </Grid>
        <Grid data-cy="div_metricas" item xs={4}>
          <DailyScaleCard
            title="Agora"
            details={diaAtual}
            fullData={dataUnitAgenda[0][0]}
          />
        </Grid>
        <Grid data-cy="div_metricas" item xs={4}>
          <DailyScaleCard
            title="Depois"
            details={diaSeguinte}
            fullData={dataUnitAgenda[0][0]}
          />
        </Grid>
      </Grid>
    </div>
  );
}
