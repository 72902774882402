import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  useStyles,
  TableControl,
  InputContainer,
  InputSelect,
  InputSelectHospital,
} from './styles';
import {
  Button,
  Typography,
  TableCell,
  Tooltip,
  IconButton,
  Icon,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useProfessional } from 'hook/professional/index';
import Layout from 'shared/component/Layout';
import DefaultTable from 'shared/component/DefaultTable';
import TabPanel from 'shared/component/tabPanel';
import SearchInputDebounce from 'shared/component/forms/SearchInputDebounce';
import StatusSwitch from 'shared/component/StatusSwitch';
import { AvatarNameComponent } from 'shared/component/AvatarNameComponent';
import CustomSelect from 'shared/component/forms/CustomSelect';
import '../../shared/fixedtab.css';
import HasPermission from 'utils/checkPermission';
import { ModalResponseAlert } from 'page/Schedule/WeekSchedule/components/ModalResponseAlert';
import moment from 'moment';
import useCompany from 'hook/companies';
import CustomSelectUnits from 'shared/component/forms/CustomSelectUnits';
import { useAuth } from 'hook/auth';

export default function ProfessionalPage() {
  const classes = useStyles();
  const { userLogged } = useAuth();
  const { companies, getCompanies } = useCompany();
  const pageRef = useRef(1);

  const permissions = {
    read: 'professional/r',
    create: 'professional/c',
    delete: 'professional/d',
    update: 'professional/u',
    export: 'professional/export',
  };

  const columns = ({ handleChangeStatus }) => {
    return [
      { id: 'id', label: 'Cód. Profissional', minWidth: 100 },
      {
        id: 'name',
        label: 'Nome',
        minWidth: 100,
        render: (rowData, index) => (
          <AvatarNameComponent key={index} rowData={rowData} />
        ),
      },
      { id: 'cpf', label: 'CPF', minWidth: 100 },
      { id: 'group.name', label: 'Campo de atuação', minWidth: 100 },
      {
        id: 'unit',
        label: 'Hospital',
        minWidth: 100,
        render: (rowData, index) => (
          <SpecialtiesNames key={index} specialties={rowData?.units} />
        ),
      },
      {
        id: 'created_at',
        label: 'Data de criação',
        minWidth: 10,
        render: (rowData) => (
          <TableCell>
            {moment(rowData.created_at).format('DD/MM/yyyy')}
          </TableCell>
        ),
      },
      {
        id: 'status',
        label: 'Status',
        minWidth: 10,
        render: (rowData, index) => (
          <StatusSwitch
            key={index}
            changeStatus={handleChangeStatus}
            rowData={rowData}
            permissions={permissions}
          />
        ),
      },
    ];
  };

  const SpecialtiesNames = ({ specialties }) => {
    if (!specialties || !specialties.length > 0)
      return <TableCell>...</TableCell>;
    const concatedNames = specialties.map((item) => item?.name).join(' , ');
    return <TableCell>{concatedNames}</TableCell>;
  };

  const {
    data: dataProfessionals,
    loading: loadingProfessionals,
    total: totalProfessionals,
    deleteLoading,
    getProfessionals,
    getExcelProfessionals,
    deleteProfessional,
    toggleStatus,
  } = useProfessional();
  const [filterCompany, filterStateCompany] = useState({
    companyPage: 1,
    companyPerPage: 30,
    companySearch: '',
  });

  const { companyPage, companyPerPage, companySearch } = filterCompany;

  const [openModalSendNotification, setOpenModalSendNotification] =
    React.useState(false);

  const handleCloseModal = () => {
    setOpenModalSendNotification(false);
  };
  const [companySelected, setCompanySelected] = React.useState({
    label: 'Todas',
    value: null,
  });
  const [unitSelected, setUnitSelected] = React.useState({
    label: 'Todas',
    value: 'all',
  });

  const { loadingCompanies, dataCompany, totalCompanies } = companies;
  React.useEffect(() => {
    getCompanies(companyPage, companyPerPage, companySearch);
  }, [companyPage, companySearch]);

  const [filterProfessionals, setFilterProfessionals] = useState({
    page: 1,
    search: '',
    per_page: 10,
    company_id: null,
    unit_id: null,
    group_type: 'professional',
    status: 'active',
  });
  const [profissionalType, setProfissionalType] = useState({
    label: 'Todos',
    value: 'professional',
  });

  const [profissionalStatus, setProfissionalStatus] = useState({
    label: 'Ativos',
    value: 'active',
  });

  const searchCompany = (companyValue) => {
    filterStateCompany({ ...filterCompany, companySearch: companyValue });
  };

  const handleChangeCompany = (val) => {
    setCompanySelected(val);
    setFilterProfessionals((oldState) => ({
      ...oldState,
      company_id: val?.value,
    }));
  };

  const handleChangeUnit = (val) => {
    setUnitSelected(val);
    setFilterProfessionals((oldState) => ({
      ...oldState,
      unit_id: val?.value,
    }));
  };

  const companyPagination = () => {
    const nextPage = pageRef.current + 1;
    const totalPage = Math.ceil(totalCompanies / companyPerPage);
    if (pageRef.current < totalPage) {
      filterStateCompany({ ...filterCompany, unitPage: nextPage });

      pageRef.current = nextPage;
    }
  };

  const topCompanyPagination = () => {
    const previousPage = pageRef.current - 1;
    if (pageRef.current > 1) {
      filterStateCompany({ ...filterCompany, companyPage: previousPage });

      pageRef.current = previousPage;
    }
  };

  const formatCompaniesOptions =
    dataCompany?.map((company) => ({
      label: company.name,
      value: company.id,
    })) ?? [];

  const handleChangeProfessional = (val) => {
    setProfissionalType(val);
    setFilterProfessionals((oldState) => ({
      ...oldState,
      group_type: val?.value,
    }));
  };

  const handleChangeProfessionalStatus = (val) => {
    setProfissionalStatus(val);
    setFilterProfessionals((oldState) => ({
      ...oldState,
      status: val?.value,
      page: 1,
    }));
  };

  React.useEffect(() => {
    getProfessionals(filterProfessionals);
  }, [filterProfessionals]);

  const handleChangePage = (page) => {
    setFilterProfessionals({ ...filterProfessionals, page: page });
  };

  const handleSearch = (search) => {
    typeof search === 'string'
      ? setFilterProfessionals((old) => ({ ...old, page: 1, search }))
      : false;
  };

  const handleChangeStatus = useCallback((status, data) => {
    toggleStatus({ id: data?.id, status });
    if (dataProfessionals.length < 2) {
      setFilterProfessionals({
        ...filterProfessionals,
        page: 1,
      });
    }
    setTimeout(() => {
      getProfessionals({
        ...filterProfessionals,
        page: filterProfessionals?.page,
        unit_id: filterProfessionals?.unit_id,
        group_type: filterProfessionals?.group_type,
        per_page: filterProfessionals?.per_page,
        status: filterProfessionals?.status,
      });
    }, 50);
  });

  const typeProfessional = [
    { label: 'Todos', value: 'professional' },
    { label: 'Médicos', value: 'doctor' },
    { label: 'Multiprofissional', value: 'multi' },
  ];

  const typeProfessionalStatus = [
    { label: 'Ativos', value: 'active' },
    { label: 'Inativos', value: 'deactive' },
  ];

  useEffect(() => {
    if (dataProfessionals.length < 1) {
      setFilterProfessionals({
        ...filterProfessionals,
        page: 1,
      });
    }
  }, []);
  useEffect(() => {
    if (unitSelected?.value !== 0) {
      setFilterProfessionals((oldState) => ({
        ...oldState,
        page: 1,
      }));
    }
  }, [unitSelected]);

  const handleDeleteProfessional = (id_professional) => {
    dataProfessionals.length > 1
      ? deleteProfessional({ id_professional, params: filterProfessionals })
      : deleteProfessional({
          id_professional,
          params: {
            ...filterProfessionals,
            page: 1,
          },
        });
  };

  const openExcel = () => {
    getExcelProfessionals({
      type: profissionalType?.value,
      company_id: filterProfessionals?.company_id,
      unitId: filterProfessionals?.unit_id,
      user_status: filterProfessionals?.status,
      search: filterProfessionals?.search,
    });
  };

  useEffect(() => {
    setUnitSelected({ label: 'Todas', value: 'all' });
  }, [companySelected]);

  return (
    <Layout title="Profissionais" showToday backArrow>
      <div style={{ margin: '0 0px 0 30px', backgroundColor: '#EDEDED' }}>
        <div>
          <InputContainer>
            <SearchInputDebounce
              value={filterProfessionals.search}
              className={classes.inputWidth}
              placeholder="Busque por nome ou CPF"
              onChange={handleSearch}
              style={{
                alignItems: 'center',
                height: 48,
                width: '90%',
              }}
            />
          </InputContainer>

          <div
            data-cy="div_professional_filters"
            className={classes.inputContainer}
          >
            {userLogged?.group?.id === 1 && (
              <InputSelect>
                <CustomSelect
                  isProf={false}
                  name="unit"
                  loading={loadingCompanies}
                  placeholder="Selecione a companhia"
                  handleChange={handleChangeCompany}
                  value={companySelected}
                  searchItem={searchCompany}
                  pagination={companyPagination}
                  toTopPagination={topCompanyPagination}
                  options={[
                    { label: 'Todas', value: null },
                    ...formatCompaniesOptions,
                  ]}
                />
              </InputSelect>
            )}

            <InputSelectHospital>
              <CustomSelectUnits
                isProf={false}
                name="unit"
                placeholder="Selecione o hospital"
                handleChange={handleChangeUnit}
                value={unitSelected}
                companyId={companySelected?.value}
                isFilter
                isMyUnits
                customStyle={{
                  control: () => ({
                    display: 'flex',
                    width: '100%',
                    minHeight: 39,
                    // border: '1px solid #A2A5A8',
                    borderRadius: 4,
                    fontSize: 12,
                  }),
                  menu: (props) => ({ ...props, zIndex: 10 }),
                }}
              />
            </InputSelectHospital>

            <InputSelect>
              <CustomSelect
                isProf={false}
                name="group_type"
                loading={false}
                placeholder="Selecione o tipo de profissional"
                handleChange={handleChangeProfessional}
                className={classes.withoutBorder}
                value={profissionalType}
                options={typeProfessional}
              />
            </InputSelect>

            <InputSelect>
              <CustomSelect
                isProf={false}
                name="status"
                loading={false}
                placeholder="Selecione o status do profissional"
                handleChange={handleChangeProfessionalStatus}
                className={classes.withoutBorder}
                value={profissionalStatus}
                options={typeProfessionalStatus}
              />
            </InputSelect>
            <div>
              {HasPermission(permissions.export) &&
              !(userLogged?.group?.id === 1 && !companySelected?.value) ? (
                <Tooltip title={'EXPORTAR EXCEL'} placement="bottom" arrow>
                  <IconButton
                    onClick={openExcel}
                    style={{
                      padding: 0,
                      marginTop: 0,
                    }}
                  >
                    <Icon style={{ fontSize: 30, color: '#0F83AD' }}>
                      article_outlined
                    </Icon>
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    userLogged?.group?.id === 1 && !companySelected?.value
                      ? 'É NECESSÁRIO SELECIONAR UMA COMPANHIA'
                      : 'Você não tem permissão'
                  }
                  placement="bottom"
                  arrow
                >
                  <IconButton
                    style={{
                      padding: 0,
                      marginTop: 0,
                    }}
                  >
                    <Icon style={{ fontSize: 30, color: '#	9D9DA9' }}>
                      article_outlined
                    </Icon>
                  </IconButton>
                </Tooltip>
              )}

              <ModalResponseAlert
                messageTitleAlert="Selecione entre Médico ou Multiprofissional!"
                openSendFinish={openModalSendNotification}
                handleCloseSendFinish={handleCloseModal}
                error
              />

              <Tooltip
                title={
                  HasPermission(permissions.create) !== true
                    ? 'Você não tem permissão'
                    : ''
                }
                placement="bottom"
                arrow
              >
                <Link
                  to={
                    HasPermission(permissions.create) &&
                    `/cadastros/profissional/cadastrar`
                  }
                  style={{ textDecoration: 'none' }}
                >
                  <Button className={classes.registerButton}>
                    <Typography className={classes.registerText}>
                      Cadastrar profissional
                    </Typography>
                  </Button>
                </Link>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className={classes.root}>
          <TabPanel>
            <TableControl data-cy="dataTable">
              <DefaultTable
                permissions={permissions}
                dataList={dataProfessionals}
                pageByProps={filterProfessionals.page}
                columns={columns({ handleChangeStatus })}
                loading={loadingProfessionals}
                totalList={totalProfessionals}
                redirectTo="/cadastros/profissional/editar"
                perPage={filterProfessionals?.perPage}
                handleChangePage={handleChangePage}
                deleteLoading={deleteLoading}
                onDeleteRequest={handleDeleteProfessional}
                showExportButton={false}
                useTimeOut={false}
              />
            </TableControl>
          </TabPanel>
        </div>
      </div>
    </Layout>
  );
}
