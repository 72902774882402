import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Icon,
  Avatar,
  CircularProgress,
} from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { StyledList, useStyles } from './styles';
import { useBirthdays } from 'hook/birthdays';
import { useAuth } from 'hook/auth';

const BirthdayBox = () => {
  const classes = useStyles();
  const { userLogged } = useAuth();
  const { loading, getList } = useBirthdays();
  const [showContent, setShowContent] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({});
  const filter = {
    page: 1,
    per_page: 10,
  };

  const onScrollBottom = (event) => {
    const bottom =
      event.currentTarget.scrollHeight - event.currentTarget.scrollTop <=
      event.currentTarget.clientHeight + 5;
    if (
      bottom &&
      !loading &&
      pagination?.current_page < pagination?.total_pages
    ) {
      const nextPage = pagination?.current_page + 1;

      handleNextPage({ ...filter, page: nextPage });
    }
  };

  useEffect(() => {
    getList({
      page: 1,
      per_page: 10,
    }).then((response) => {
      setData(response?.data?.data);
      setPagination(response?.data?.pagination);
    });
  }, []);

  const handleNextPage = async (filter) => {
    const response = await getList(filter);
    setData((old) => [...old, ...response?.data?.data]);
    setPagination(response?.data?.pagination);
  };

  const handleClick = () => {
    setShowContent(!showContent);
  };

  return (
    <div className={classes.birthdayBox}>
      <Box className={classes.birthdayHeader}>
        <Icon style={{ color: '#24B7EC' }}>cakeIcon</Icon>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>
            Aniversariantes do dia
          </Typography>
          <Box
            width="35px"
            height="35px"
            marginLeft="1em"
            borderRadius="100px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ background: '#24B7EC' }}
          >
            <Typography style={{ fontWeight: 'bold', color: 'white' }}>
              {pagination?.total_items}
            </Typography>
          </Box>
        </Box>
        <IconButton size="small" aria-label="expand" onClick={handleClick}>
          <Icon
            style={{
              color: '#24B7EC',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <KeyboardArrowDown style={{ fontSize: 30 }} />
          </Icon>
        </IconButton>
      </Box>
      {showContent && (
        <Box className={classes.birthdayContent}>
          <StyledList onScroll={(event) => onScrollBottom(event)}>
            {data &&
              data?.length > 0 &&
              data.map((item) => (
                <Box key={item.id} className={classes.birthdayItem}>
                  <Avatar
                    src={item.image}
                    sx={{ bgcolor: '#24B7EC' }}
                    style={{ width: 56, height: 56, bgColor: '#24B7EC' }}
                  />

                  <Typography style={{ marginLeft: '20px' }}>
                    <Typography>
                      {item.name}, {item.age} anos
                    </Typography>
                    <Typography>{item.group}</Typography>
                    <Typography>{item.cellphone}</Typography>
                    {userLogged?.group?.id === 1 && (
                      <Typography>{item.company}</Typography>
                    )}
                  </Typography>
                </Box>
              ))}
          </StyledList>
          <div style={{ minHeight: 20 }}>
            {data?.length === 0 && filter?.search !== '' && (
              <div className={classes.loadingContainer}>
                <Typography>Não há aniversariantes hoje.</Typography>
              </div>
            )}
            {loading && (
              <div className={classes.loadingContainer}>
                <CircularProgress size={25} color="primary" />
              </div>
            )}
          </div>
        </Box>
      )}
    </div>
  );
};

export default BirthdayBox;
