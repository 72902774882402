import React, { useState } from 'react';
import { FastField, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import FooterButton from './FooterButton';
import ModalResponseSuccess from './ModalResponseSuccess';
import {
  ContainerFormModal,
  InputContainer,
  InputItem,
  Legend,
} from './styles';
import CustomSelectUnits from 'shared/component/forms/CustomSelectUnits';
import CalendarMonthYearPicker from 'shared/component/CalendarMonthYearPicker';
import CustomSelectUnitScales from 'shared/component/forms/CustomSelectUnitScales';

import { useReports } from 'hook/reports/exportExcell';
import MessageNotification from 'shared/component/messageNotification';
import {
  RadioInput,
  RadioInputGroup,
} from 'shared/component/maskedField/checkBox';
import { InputMaskField } from 'shared/component/forms/CustomMaskField';
import datePattern from 'utils/regexPatterns';
import { Tooltip } from '@material-ui/core';
import { useAuth } from 'hook/auth';
import CustomSelectSubsidiaries from 'shared/component/forms/CustomSelectSubsidiaries';
import { Checkbox } from 'shared/component/maskedField/checkBox';
const moment = require('moment');

export const formInitialValues = {
  adjusted_price: false,
  type: 'operational',
  typeDate: 'mensal',
  type_report: '1',
  subsidiary: '',
  unit: '',
  scales: '',
  monthYear: {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  },
  date_start: '',
  date_end: '',
  group: { id: '' },
};

const FormGenerateSpreadSheets = ({ handleCloseModal }) => {
  const { userLogged } = useAuth();
  const { loading, getExport } = useReports();
  const [error, setError] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(null);
  const [message, setMessage] = useState(null);

  const handleSubmit = (data) => {
    const params = {
      adjusted_price:
        data?.type === 'financial'
          ? data?.price_per_production
            ? 1
            : undefined
          : undefined,
      subsidiary_id: data?.subsidiary?.value ?? undefined,
      unit_id: data?.unit?.value,
      type_report: data?.type === 'work_hours' ? data?.type_report : null,
      year: data?.typeDate === 'mensal' ? data?.monthYear?.year : null,
      month: data?.typeDate === 'mensal' ? data?.monthYear?.month : null,
      date_start:
        data?.typeDate === 'period'
          ? moment(data?.date_start, 'DD-MM-YYYY').format('YYYY-MM-DD')
          : null,
      date_end:
        data?.typeDate === 'period'
          ? moment(data?.date_end, 'DD-MM-YYYY').format('YYYY-MM-DD')
          : null,
    };
    getExport(
      { type: data?.type, typeDate: data?.typeDate, scales: data?.scales },
      params
    )
      .then(() => {
        setModalSuccess(true);
        setMessage(null);
        setError(false);
      })
      .catch((errorMessage) => {
        setMessage(errorMessage);
        setError(true);
      });
  };

  const [selectedScales, setSelectedScales] = useState(null);

  const schema = Yup.object().shape({
    price_per_production: Yup.boolean(),
    type: Yup.string(),
    typeDate: Yup.string(),
    type_report: Yup.string(),
    subsidiary: Yup.mixed(),
    unit: Yup.mixed().required('Obrigatório!'),
    scales: Yup.mixed().typeError('Obrigatório!').required('Obrigatório!'),
    monthYear: Yup.object().shape({
      year: Yup.number(),
      month: Yup.number(),
    }),
    date_start: Yup.string()
      .matches(datePattern, { message: 'Data inválida' })
      .test('typeDate', 'Informe uma data válida!', function (value) {
        return this.parent.typeDate !== 'period' || value;
      }),
    date_end: Yup.string()
      .matches(datePattern, { message: 'Data inválida' })
      .test('typeDate', 'Informe uma data válida!', function (value) {
        return this.parent.typeDate !== 'period' || value;
      })
      .test(
        'period',
        'A data final deve ser posterior a data inicial!',
        function (value) {
          return (
            this.parent.typeDate !== 'period' ||
            Math.trunc(
              moment
                .duration(
                  moment(value, 'DD/MM/YYYY').diff(
                    moment(this.parent.date_start, 'DD/MM/YYYY')
                  )
                )
                .asDays()
            ) >= 0
          );
        }
      ),
  });

  return (
    <ContainerFormModal>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={schema}
        initialValues={formInitialValues}
        validateOnBlur
        values
      >
        {({
          setFieldValue,
          setFieldTouched,
          errors,
          values,
          handleBlur,
          touched,
        }) => (
          <Form>
            <InputContainer data-cy="inputContainer_modal_gerar_planilhas">
              <InputItem>
                <Legend>Escolha o tipo de planilha</Legend>
                <RadioInputGroup
                  row
                  name="type"
                  handleChange={(e) => {
                    const { value } = e.target;
                    setFieldValue('type', value);
                    // if (value === 'operational' || value === 'operationalMP')
                    setFieldValue('typeDate', 'mensal');
                    setSelectedScales(null);
                    setFieldValue('year', new Date().getFullYear());
                    setFieldValue('month', new Date().getMonth() + 1);
                    setFieldValue('subsidiary', '');
                    setFieldValue('unit', '');
                    setFieldValue('scales', '');
                  }}
                  value={values?.type}
                >
                  <RadioInput label="Operacional" value="operational" />
                  <Tooltip
                    title="Núcleo de qualidade e segurança do paciente"
                    placement="bottom"
                    arrow
                  >
                    <RadioInput
                      label="Operacional NQSP"
                      value="operationalMP"
                    />
                  </Tooltip>
                  {userLogged?.company?.id !== 59 && (
                    <RadioInput label="Financeiro" value="financial" />
                  )}
                  <RadioInput label="Horas trabalhadas" value="work_hours" />
                </RadioInputGroup>
              </InputItem>
            </InputContainer>
            {(values?.type === 'operational' ||
              values?.type === 'financial') && (
              <InputContainer>
                <InputItem data-cy="select_hospital">
                  <Field
                    name="subsidiary"
                    style={{ width: '100%' }}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    placeholder={'Selecione uma filial'}
                    label={'Selecione uma filial'}
                    errors={errors && errors?.unit}
                    handleChange={(data) => {
                      if (data?.value) setFieldValue('subsidiary', data);
                      else setFieldValue('subsidiary', '');
                    }}
                    value={values.subsidiary}
                    menuList={() => ({ height: 116, overflowY: 'auto' })}
                    variant="outlined"
                    component={CustomSelectSubsidiaries}
                    isClearable
                  />
                </InputItem>
              </InputContainer>
            )}
            <InputContainer>
              <InputItem data-cy="select_hospital">
                <Field
                  name="unit"
                  style={{ width: '100%' }}
                  setFieldTouched={setFieldTouched}
                  setFieldValue={setFieldValue}
                  placeholder={'Selecione o hospital'}
                  label={'Selecione o hospital'}
                  errors={errors && errors?.unit}
                  handleChange={(data) => {
                    setFieldValue('scales', '');
                    setSelectedScales(null);
                    if (data?.value) setFieldValue('unit', data);
                    else setFieldValue('unit', '');
                  }}
                  value={values.unit}
                  menuList={() => ({ height: 116, overflowY: 'auto' })}
                  variant="outlined"
                  component={CustomSelectUnits}
                  isClearable
                />
              </InputItem>
            </InputContainer>
            {values?.type === 'work_hours' ? (
              <InputContainer>
                <InputItem id="InputEscala">
                  <Field
                    name="scales"
                    style={{ width: '100%' }}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    placeholder={'Selecione a escala'}
                    label={'Selecione a escala'}
                    errors={errors && errors?.scales}
                    handleChange={(data) => {
                      if (data?.value) setFieldValue('scales', data);
                      else setFieldValue('scales', '');
                    }}
                    value={values.scales}
                    menuList={() => ({ height: 116, overflowY: 'auto' })}
                    variant="outlined"
                    component={CustomSelectUnitScales}
                    unit_id={values?.unit?.value && values.unit.value}
                    isClearable
                  />
                </InputItem>
              </InputContainer>
            ) : (
              <InputContainer>
                <InputItem id="InputEscala">
                  <Field
                    name="scales"
                    style={{ width: '100%' }}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    placeholder={'Selecione a escala'}
                    label={'Selecione a escala'}
                    value={selectedScales}
                    errors={errors && errors?.scales}
                    handleChange={(data) => {
                      setFieldValue('scales', data);
                      setSelectedScales(data);
                    }}
                    menuList={() => ({ height: 116, overflowY: 'auto' })}
                    variant="outlined"
                    component={CustomSelectUnitScales}
                    unit_id={values?.unit?.value && values.unit.value}
                    isMulti
                    isClearable
                  />
                </InputItem>
              </InputContainer>
            )}
            {values?.type === 'financial' && (
              <>
                <InputContainer style={{ margin: '0' }}>
                  <InputItem>
                    <RadioInputGroup
                      row
                      name="typeDate"
                      value={String(values?.typeDate)}
                      handleChange={(value) => {
                        setFieldValue('typeDate', value.target.value);
                      }}
                      formControlStyle={{ height: 25 }}
                    >
                      <RadioInput label="Período" value="period" />
                      <RadioInput label="Mensal" value="mensal" />
                    </RadioInputGroup>
                  </InputItem>
                </InputContainer>
                <InputContainer>
                  <InputItem>
                    <Field
                      component={Checkbox}
                      name="price_per_production"
                      label="Relatório por preço de produção"
                    />
                  </InputItem>
                </InputContainer>
              </>
            )}
            {values?.type === 'work_hours' && (
              <InputContainer style={{ margin: '0' }}>
                <InputItem>
                  <RadioInputGroup
                    row
                    name="type_report"
                    value={String(values?.type_report)}
                    handleChange={(value) => {
                      setFieldValue('type_report', value.target.value);
                    }}
                    formControlStyle={{ height: 25 }}
                  >
                    <RadioInput label="Horas Previstas" value="1" />
                    <RadioInput label="Horas Trabalhadas" value="2" />
                  </RadioInputGroup>
                </InputItem>
              </InputContainer>
            )}
            {values?.typeDate === 'mensal' ? (
              <InputContainer>
                <InputItem id="calendar">
                  <Field
                    name="monthYear"
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    value={values.monthYear}
                    errors={errors && errors?.monthYear?.month}
                    touched={touched}
                    label="Mês/Ano"
                    variant="outlined"
                    component={CalendarMonthYearPicker}
                  />
                </InputItem>
              </InputContainer>
            ) : (
              <InputContainer>
                <InputItem style={{ marginRight: 20 }}>
                  <FastField
                    name="date_start"
                    value={values?.date_start}
                    label="Data inicial"
                    style={{ width: '100%' }}
                    format="99/99/9999"
                    variant="outlined"
                    errors={errors && errors.date_start}
                    touched={touched}
                    component={InputMaskField}
                    onChange={(e) => {
                      setFieldValue('date_start', e.target.value);
                    }}
                  />
                </InputItem>
                <InputItem>
                  <FastField
                    name="date_end"
                    value={values?.date_end}
                    label="Data final"
                    style={{ width: '100%' }}
                    format="99/99/9999"
                    variant="outlined"
                    errors={errors && errors.date_end}
                    touched={touched}
                    component={InputMaskField}
                    onChange={(e) => {
                      setFieldValue('date_end', e.target.value);
                    }}
                  />
                </InputItem>
              </InputContainer>
            )}

            <FooterButton
              loading={loading}
              handleCloseModal={handleCloseModal}
            />
          </Form>
        )}
      </Formik>
      <MessageNotification
        openNotification={error}
        closeNotification={() => setError(false)}
        type={'error'}
        message={message}
        vertical={10}
        horizontal="40vw"
      />
      <ModalResponseSuccess
        openModalSuccess={modalSuccess}
        handleCloseModalSuccess={() => setModalSuccess(false)}
      />
    </ContainerFormModal>
  );
};

export default FormGenerateSpreadSheets;
