import React from 'react';
import { useStyles, InputContainer, InputItem } from './styles';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import { FastField, Form, Formik } from 'formik';
import CustomTextField from 'shared/component/forms/CustomTextField';
import CustomMaskField from 'shared/component/forms/CustomMaskField';
import { schema } from './schema';
import { initialValues } from './initialValues';
import HeaderForm from './components/HeaderForm';
import FooterButton from './components/FooterButton';
import AddressForm from 'shared/component/forms/AddressForm';
import CustomRichText from 'shared/customRichText';

export default function CompanyForm({
  formInitialValues = initialValues,
  loading = false,
  isEdit = false,
  formSubmit = () => {},
}) {
  const classes = useStyles();

  const [fileLogo, setFileLogo] = React.useState(null);

  const handlePrint = (text) => {
    const newWindow = window.open('', '', 'width=800, height=500');

    const document = newWindow.document.open();

    const pageContent = `${
      '<!DOCTYPE html>' +
      '<html>' +
      '<head>' +
      '<meta charset="utf-8" />' +
      '<title>Termo de Privacidade</title>' +
      '</head>' +
      '<body>'
    }${text}</body></html>`;
    try {
      document.write(pageContent);
      document.close();
      newWindow.print();
      // newWindow.close();
    } catch (error) {
      newWindow.close();
    }
  };

  const handleFormSubmit = (data) => {
    formSubmit({ data, fileLogo });
  };

  return (
    <>
      <Typography className={classes.dataHospital}>
        {!isEdit ? 'Cadastrar nova companhia' : 'Editar companhia'}
      </Typography>
      <HeaderForm
        formInitialValues={formInitialValues}
        setFileLogo={setFileLogo}
      />
      <Formik
        onSubmit={handleFormSubmit}
        validationSchema={schema}
        initialValues={formInitialValues}
        validateOnBlur
        enableReinitialize
      >
        {({ setFieldValue, handleChange, values }) => (
          <Form>
            <Typography className={classes.dataHospital}>
              Dados da companhia
            </Typography>
            <InputContainer>
              <InputItem style={{ flexGrow: 2.05 }}>
                <FastField
                  data-cy="input_nome"
                  value={values?.name}
                  label="Nome da companhia"
                  style={{ width: '100%' }}
                  name="name"
                  variant="outlined"
                  component={CustomTextField}
                />
              </InputItem>
              <InputItem>
                <FastField
                  data-cy="input_email"
                  value={values?.email}
                  label="E-mail"
                  style={{ width: '100%' }}
                  name="email"
                  variant="outlined"
                  component={CustomTextField}
                />
              </InputItem>
            </InputContainer>
            <InputContainer>
              <InputItem>
                <FastField
                  data-cy="input_razao_social"
                  value={values?.social_name}
                  label="Razão Social"
                  style={{ width: '100%' }}
                  name="social_name"
                  variant="outlined"
                  component={CustomTextField}
                  marginTop={0}
                />
              </InputItem>
              <InputItem>
                <FastField
                  data-cy="input_cnpj"
                  value={values?.cnpj}
                  label="CNPJ"
                  style={{ width: '100%' }}
                  mask=""
                  format="##.###.###/####-##"
                  name="cnpj"
                  variant="outlined"
                  component={CustomMaskField}
                  onChange={handleChange}
                />
              </InputItem>
              <InputItem>
                <FastField
                  data-cy="input_cell_phone"
                  value={values?.cell_phone}
                  name="cell_phone"
                  id="cell_phone"
                  style={{ width: '100%' }}
                  label="Telefone"
                  mask=""
                  format="(##) #####-####"
                  variant="outlined"
                  component={CustomMaskField}
                  onChange={handleChange}
                />
              </InputItem>
            </InputContainer>

            <Typography className={classes.address}>Endereço</Typography>
            <AddressForm values={values} setFieldValue={setFieldValue} />

            <InputContainer style={{ marginTop: 25, height: 128 }}>
              <div style={{ height: 128, width: '100%' }}>
                <Typography className={classes.title_geolocation}>
                  Observações
                </Typography>
                <FastField
                  data-cy="input_description"
                  value={values?.description}
                  style={{ width: '100%' }}
                  name="description"
                  variant="outlined"
                  multiline
                  rows={3}
                  component={CustomTextField}
                />
              </div>
            </InputContainer>
            <InputContainer
              style={{
                marginTop: 40,
                marginRight: 0,
                height: 'auto',
              }}
            >
              <InputItem style={{ margin: 0 }}>
                <FastField
                  value={values?.description}
                  style={{ width: '100%' }}
                  name="contract"
                  variant="outlined"
                  label="Termo de privacidade"
                  multiline
                  rows={3}
                  component={CustomRichText}
                />
              </InputItem>
            </InputContainer>
            <div>
              <IconButton
                style={{ padding: 0 }}
                onClick={() =>
                  values?.contract &&
                  values?.contract !== '' &&
                  handlePrint(values?.contract)
                }
                aria-label="Imprimir"
              >
                <Tooltip title="Imprimir" arrow>
                  <PrintIcon color="primary" />
                </Tooltip>
              </IconButton>
            </div>
            <div data-cy="footerButton_btns" style={{ marginRight: 15 }}>
              <FooterButton id={values?.id} type="submit" loading={loading} />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
