import React, { useState, useEffect } from 'react';
import { useStyles } from './styles';
import Layout from 'shared/component/Layout';
import { useSystems } from 'hook/system';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import SystemForm from 'shared/forms/SystemForm';

function SystemUpdatePage() {
  const classes = useStyles();

  const { id } = useParams();
  const { getUpdate, getOne, data, loading, status } = useSystems();

  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    if (id) {
      getOne({ id });
    }
  }, []);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setTimeout(() => {
        setInitialValues({
          ...data,
          enterprise:
            data?.enterprise && Object.keys(data?.enterprise).length > 0
              ? data?.enterprise
              : { id: '' },
        });
      }, 750);
    }
  }, [data]);

  useEffect(() => {
    return () => {
      setInitialValues(null);
    };
  }, []);

  const formatRequest = (data) => ({
    name: data?.name,
    email: data?.email,
    cpf: data?.cpf,
    rg: data?.rg,
    cell_phone: data?.cell_phone,
    company: { id: data?.company.id },
    group: { id: data?.group.id },
    units: data?.units,
  });

  const formSubmit = ({ data }) => {
    getUpdate({
      id,
      data: formatRequest(data),
      permissions: data?.permissions.map((item) => ({ id: item?.id })),
    });
  };
  return (
    <Layout
      title="Sistema"
      isLoading={false}
      calendarWeek={false}
      showToday
      backArrow
    >
      <>
        {initialValues ? (
          <SystemForm
            formInitialValues={initialValues}
            formSubmit={formSubmit}
            loading={loading}
            status={status}
            isEdit
          />
        ) : (
          <div className={classes.loading_container}>
            <CircularProgress color="primary" size={50} />
          </div>
        )}
      </>
    </Layout>
  );
}

export default SystemUpdatePage;
