import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import HorizontalScrollMenu from 'shared/component/forms/CustomHorizontalMenu';
import { DashboardV2PageContext } from 'page/dashboardV2/index';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 25,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function CenterReportCardsGrid() {
  const classes = useStyles();
  const { scalesFilter, handleChangeScale } = useContext(
    DashboardV2PageContext
  );
  const formatScalesOptions = scalesFilter?.dataScalesFilter.map((scales) => ({
    label: `${scales.name}\n${
      scales.specialty?.name ? scales.specialty?.name : ''
    }`,
    value: scales.id,
    count_agenda: scales.count_agenda,
  }));

  return (
    <div className={classes.root}>
      <Grid justify="flex-start" container spacing={3}>
        {formatScalesOptions && Object.keys(formatScalesOptions).length > 0 && (
          <Grid data-cy="select_unit" xs={12}>
            <HorizontalScrollMenu
              items={formatScalesOptions}
              onClick={handleChangeScale}
              maxWidth={1600}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
}
