import { useState } from 'react';
import api from 'service/api';
import { useAuth } from 'hook/auth';

export const useProfile = () => {
  const { requestIntercept } = useAuth();

  const [profile, setProfile] = useState([]);
  const [loading, setLoading] = useState(false);

  const getProfile = async () => {
    try {
      setLoading(true);
      const response = await requestIntercept(
        api.get,
        `/v2/admin/users/profile`
      );
      if (response.status !== 200) {
        throw response;
      }
      setProfile(response?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // const getUpdate = ({ id, data, permissions }) =>
  //   new Promise((resolve, reject) => {
  //     (async function () {
  //       try {
  //         setLoading(true);
  //         const response = await requestIntercept(
  //           api.put,
  //           `/v1/users/${id}`,
  //           data,
  //           null,
  //           true
  //         );
  //         if (response.status !== 200 && response.status !== 201) {
  //           throw response;
  //         }

  //         toggleStatusPermission({ user_id: id, permissions });

  //         setTimeout(() => {
  //           setData(response.data.data);
  //           setLoading(false);
  //           resolve(response.data.data);
  //           setStatus(response.status);
  //         }, 500);
  //       } catch (error) {
  //         setStatus(error.status);
  //         setLoading(false);
  //         reject(error);
  //       }
  //     })();
  //   });

  return {
    profile,
    loading,
    getProfile,
  };
};
