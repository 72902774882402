import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import styles from './style';
import { ContainerLogin } from './style';
import LoginCarousel from './component/carousel';
import RecoverForm from './component/form';
import useUser from 'hook/user';
import { ModalResponseAlert } from 'page/Schedule/WeekSchedule/components/ModalResponseAlert';
import { useHistory } from 'react-router-dom';
const logo = require('asset/image/logo.png');

export default function RecoverPasswordPage() {
  const classes = styles();
  const { recoverPass, loading, status, msg } = useUser();
  const onSubmit = (data) => {
    recoverPass({ email: data.email });
  };
  const [modalPassword, setModalPassword] = React.useState(false);
  React.useEffect(() => {
    if (status === 200) setModalPassword(true);
  }, [status]);
  const history = useHistory();

  const handleCloseModal = () => {
    history.goBack();
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        className="bg-primary bg-onboard_bg_img bg-no-repeat bg-cover bg-center"
      >
        <LoginCarousel />
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <ContainerLogin>
            <img
              src={logo}
              style={{ width: '100%' }}
              alt=""
              className="mb-11 h-20 w-[100%]"
            />
            <RecoverForm
              handleSubmit={onSubmit}
              error={null}
              loading={loading}
            />
          </ContainerLogin>
        </div>

        {msg ==
        'Sua senha foi enviada para o telefone e e-mail cadastrado no sistema.' ? (
          <ModalResponseAlert
            messageTitleAlert={msg}
            openSendFinish={modalPassword}
            handleCloseSendFinish={handleCloseModal}
          />
        ) : (
          <ModalResponseAlert
            messageTitleAlert={msg}
            openSendFinish={modalPassword}
            handleCloseSendFinish={handleCloseModal}
            error
          />
        )}
      </Grid>
    </Grid>
  );
}
