import React from 'react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AlarmOff from '@material-ui/icons/AlarmOff';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import TransformIcon from '@material-ui/icons/Transform';
import ReportProblem from '@material-ui/icons/ReportProblem';
import appColors from './appColors';

export const StatusSituationType = (
  classes,
  userOld,
  user_new,
  validated_description
) => [
  // Plantões que foram agendados por um gerente e lançados para o usuário
  {
    id: '10',
    label: 'Falta',
    icon: <AlarmOff className={classes.icon} />,
    color: '#708090',
  },
  {
    id: '11',
    label: 'Lançado',
    icon: <CompareArrowsIcon className={classes.icon} />,
    color: '#FFFF99',
  },
  {
    id: '12',
    label: 'Agendado',
    icon: <AccessTimeIcon className={classes.icon} />,
    color: '#1F437F',
  },
  {
    id: '13',
    label: 'Ocorrendo',
    icon: <AvTimerIcon className={classes.icon} />,
    color: 'green',
  },
  {
    id: '14',
    label: 'Realizado',
    icon: <DoneAllIcon className={classes.icon} />,
    color: validated_description ? '#993399' : appColors.PRIMARY_COLOR,
  },
  {
    id: '17',
    label: 'Solicitado',
    icon: <AccessTimeIcon className={classes.icon} />,
    color: appColors.PRIMARY_COLOR,
  },
  {
    id: '18',
    label: 'Cancelado',
    icon: <DoneAllIcon className={classes.icon} />,
    color: '#ff0000',
  },
  {
    id: '19',
    label: 'Atrasado',
    icon: <ReportProblem className={classes.icon} />,
    color: '#ffd700',
  },
  // Plantões que foram enviados para um colega
  {
    id: '21',
    label: user_new?.name
      ? `Encaminhado para ${user_new?.name}`
      : 'Encaminhado',
    icon: <CompareArrowsIcon className={classes.icon} />,
    color: '#FFA07A',
  },
  {
    id: '22',
    label: 'Enviado e Confirmado',
    icon: <TransformIcon className={classes.icon} />,
    color: appColors.PRIMARY_COLOR,
  },
  {
    id: '23',
    label: 'Enviado e Recusado',
    icon: <NotInterestedIcon className={classes.icon} />,
    color: 'red',
  },

  // Plantões que foram recebidos de um colega na unidade
  {
    id: '31',
    label: userOld?.name ? `Recebido de ${userOld?.name}` : 'Recebido',
    icon: <CompareArrowsIcon className={classes.icon} />,
    color: appColors.PRIMARY_COLOR,
  },
  {
    id: '32',
    label: userOld?.name
      ? `Recebido de ${userOld?.name} e Aceito`
      : 'Recebido e Aceito',
    icon: <TransformIcon className={classes.icon} />,
    color: '#84fa84',
  },
  {
    id: '33',
    label: 'Recebido e em Andamento',
    icon: <AccessTimeIcon className={classes.icon} />,
    color: 'green',
  },
  {
    id: '34',
    label: 'Recebido e Realizado',
    icon: <DoneAllIcon className={classes.icon} />,
    color: validated_description ? '#993399' : appColors.PRIMARY_COLOR,
  },
  {
    id: '35',
    label: 'Recebido e Recusado',
    icon: <NotInterestedIcon className={classes.icon} />,
    color: 'red',
  },
];
