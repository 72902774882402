import React, { useState } from 'react';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import { useStyles, FormContainer } from './styles';
import { useHistory } from 'react-router-dom';
import { ModalResponseAlert } from 'page/Schedule/WeekSchedule/components/ModalResponseAlert';

const FooterButton = ({ type, loading, status }) => {
  const history = useHistory();
  const classes = useStyles();
  const goBack = () => history.goBack();
  const [openModalSucess, setOpenModalSucess] = useState(false);
  React.useEffect(() => {
    if (status === 200 || status === 201) setOpenModalSucess(true);
  }, [status]);
  const handleCloseModal = () => {
    history.push(`/profile`);
  };

  return (
    <FormContainer
      style={{ justifyContent: 'flex-end', marginTop: 48, marginRight: 150 }}
    >
      <Button onClick={goBack} className={classes.cancelButton}>
        <Typography className={classes.cancelText}>Voltar</Typography>
      </Button>
      <Button
        data-cy="btn_salvar_admin_profile"
        disabled={loading}
        type={type}
        className={classes.registerButton}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
        }}
      >
        {loading ? (
          <CircularProgress
            style={{
              color: 'white',
            }}
            size={21}
          />
        ) : (
          <Typography className={classes.registerText}>Salvar</Typography>
        )}
      </Button>
      {openModalSucess && (
        <ModalResponseAlert
          messageTitleAlert="Perfil editado com sucesso!"
          openSendFinish={openModalSucess}
          handleCloseSendFinish={handleCloseModal}
        />
      )}
    </FormContainer>
  );
};

export default FooterButton;
