import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const ContainerFormModal = styled.div`
  margin-top: 50px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 7px;
`;

export const InputItem = styled.div`
  flex: 1;
  flex-grow: ${(props) => props.flexGrow || 1};
  min-width: 150px;
`;

export const TextAgency = styled(Typography)`
  font-family: Poppins;
  font-size: 16px;
  margin-top: 25px;
  font-style: normal;
  font-weight: 400;
  color: #505255;
  margin-bottom: 0px;
`;
