import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import appColors from 'utils/appColors';

export const useStyles = makeStyles(() => ({
  title: {
    fontSize: 18,
    fontWeight: 400,
    textAlign: 'left',
    marginBottom: 5,
  },
  messageContainer: {
    textAlign: 'center',
    marginTop: '12%',
  },
  details: {
    fontSize: 14,
    fontWeight: 400,
    paddingTop: 5,
    textAlign: 'left',
  },
  detailsData: {
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'left',
    paddingTop: 2,
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 10,
    paddingRight: 10,
  },
  rowContainerCenter: {
    marginLeft: 25,
    marginRight: 25,
    display: 'flex',
    flexDirection: 'row',
  },
  rowStatusContainer: {
    display: 'flex',
  },
  ColumnContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 10,
    paddingRight: 10,
  },
  avatar: {
    width: 60,
    height: 60,
    borderColor: '#24B8EC',
    borderStyle: 'solid',
    borderWidth: 1,
    marginTop: 10,
  },
  marginScaleType: {
    color: appColors.PRIMARY_COLOR,
  },
  marginScaleStatusGreen: {
    color: 'green',
  },
  marginScaleStatusBlue: {
    color: 'Blue',
  },
  marginScaleStatusGray: {
    color: 'Gray',
  },
  marginScaleStatusRed: {
    color: appColors.DANGER,
  },
}));

export const StyledDetailsReportCard = styled(Paper)`
  padding: 10px;
  min-height: 624px;
  border-radius: 4px;
`;
