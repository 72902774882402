import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  CircularProgress,
  FormControl,
  Modal,
} from '@material-ui/core';
import CustomSelectProfessionals from 'shared/component/forms/CustomSelectProfessionals';
import moment from 'moment';
import { ScheduleWeekPageContext } from 'page/Schedule/WeekSchedule/index';
import { useAgendaValidation } from 'hook/agenda/validation';
// import axios from 'axios';
// import { formatDate } from 'react-day-picker/moment';

export function ModalSubstutionSchedule({
  open = false,
  title = '',
  // submitConfirm = false,
  close = () => {},
  setStatusError = () => {},
  setResponseMessage = () => {},
  setTitleMessage = () => {},
  // setOpenModalConfirmation = () => {},
  setOpenAlertMessage = () => {},
}) {
  const { loading, putAgendaSubstitution } = useAgendaValidation();

  const {
    selectedUnit = {},
    detailsSchedule = {},
    setAgendaDetailsData = () => {},
    handleGetAgenda = () => {},
  } = useContext(ScheduleWeekPageContext);
  const [professional, setProfessional] = useState(null);
  const [errorVeifyProfessional, setErrorVerifyProfessional] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    return () => {
      setProfessional(null);
      setErrorVerifyProfessional(false);
      setError(false);
    };
  }, []);

  const handleSubmit = () => {
    if (!professional) {
      setError(true);
      return;
    }
    setError(false);
    const formData = {
      id: detailsSchedule?.id,
      substitution_date_start: moment(detailsSchedule.date_start).format(
        'yyyy-MM-DD'
      ),
      substitution_date_end: moment(detailsSchedule.date_end).format(
        'yyyy-MM-DD'
      ),
      unit: { id: selectedUnit?.id },
      scale: { id: detailsSchedule?.scaleData.id },
      group_id: { id: detailsSchedule?.scaleData.group_id },
      user: { id: professional.value },
      user_id: professional.value,
      user_old_id: detailsSchedule?.user?.id,
      substitution: 1,
      schedule_id: detailsSchedule?.schedule_id,
      hour_start: moment(detailsSchedule.date_start).format('HH:mm:ss'),
      hour_end: moment(detailsSchedule.date_end).format('HH:mm:ss'),
      month_day_start: moment(detailsSchedule.date_start)
        .startOf('month')
        .format('yyyy-MM-DD'),
      month_day_end: moment(detailsSchedule.date_end)
        .endOf('month')
        .format('yyyy-MM-DD'),
    };
    putAgendaSubstitution(
      {
        agenda_id: detailsSchedule?.id,
        substitution: 1,
      },
      formData
    )
      .then((data) => {
        close();
        setProfessional(null);
        setTitleMessage('Profissional substituído com sucesso!');
        setResponseMessage('');
        setOpenAlertMessage(true);
        setAgendaDetailsData(data);
        setTimeout(() => {
          handleGetAgenda();
        }, 300);
      })
      .catch(() => {
        setTimeout(() => {
          close();
          setStatusError(true);
          setTitleMessage('Falha ao fazer substituição!');
          setResponseMessage('Conflito de horário');
          setOpenAlertMessage(true);
        }, 300);
      });
  };

  return (
    <Modal
      open={open}
      onClose={close}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <div
        data-cy="modal_substituir_profissional_agendamento"
        style={{
          width: 360,
          height: 'auto',
          background: '#FFFFFF',
          borderRadius: '10px',
          padding: '1.5rem',
        }}
      >
        <p>{title}</p>
        <FormControl
          variant="outlined"
          style={{
            width: '100%',
            marginTop: '16px',
          }}
        >
          <CustomSelectProfessionals
            name="professional"
            id="professional"
            placeholder="Selecione o profissional"
            menuList={() => ({ height: 116, overflowY: 'auto' })}
            handleChange={async (data) => {
              setProfessional(data);
              if (data?.value) {
                setError(false);
                setErrorVerifyProfessional(false);
              }
            }}
            value={professional}
            unit_id={selectedUnit?.id}
            group_id={detailsSchedule?.scaleData?.group_id}
            group_type="custom"
            isClearable
          />
          {error && (
            <span style={{ fontSize: 12, marginTop: 5, color: 'red' }}>
              Obrigatório!
            </span>
          )}
          {errorVeifyProfessional && (
            <span style={{ fontSize: 12, marginTop: 5, color: 'red' }}>
              {errorVeifyProfessional}
            </span>
          )}
        </FormControl>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            style={{
              background: '#0F83AD',
              width: '136px',
              height: '32px',
              fontSize: '12px',
              borderRadius: '4px',
              color: '#ffffff',
              textTransform: 'unset',
              marginTop: 100,
            }}
            onClick={handleSubmit}
          >
            {loading ? (
              <CircularProgress size={20} style={{ color: 'white' }} />
            ) : (
              'Confirmar'
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
