import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './presentation.scss';
import './style.css';
import { Carousel } from 'react-responsive-carousel';
import { useAuth } from 'hook/auth';

const LoginCarousel = () => {
  const { isAuth } = useAuth();

  return (
    <Carousel
      autoPlay={!isAuth}
      infiniteLoop
      swipeScrollTolerance={3}
      showThumbs={false}
      showStatus={false}
      useKeyboardArrows
      className="presentation-mode"
    >
      <div key="content-0" className="my-slide">
        <h2>Facilitando escalas, otimizando tempos</h2>
      </div>
      <div key="content-1" className="my-slide">
        <h2>Facilitando escalas, otimizando tempo</h2>
      </div>
      <div key="content-2" className="my-slide">
        <h2>Facilitando escalas, otimizando tempo</h2>
      </div>
    </Carousel>
  );
};

export default LoginCarousel;
