import React, { useEffect, useRef, useState } from 'react';
import {
  useStyles,
  SearchContainer,
  InputContainer,
  TableControl,
  InputSelectHospital,
} from './styles';
import Layout from 'shared/component/Layout';
import {
  Button,
  Typography,
  Divider,
  Tooltip,
  TableCell,
} from '@material-ui/core';
import { useAdministrators } from 'hook/administrators';
import DefaultTable from 'shared/component/DefaultTable';
import CustomSelect from 'shared/component/forms/CustomSelect';
import SearchInputDebounce from 'shared/component/forms/SearchInputDebounce';
import { AvatarNameComponent } from 'shared/component/AvatarNameComponent';
import '../../../shared/fixedtab.css';
import { useHistory } from 'react-router-dom';
import HasPermission from 'utils/checkPermission';
// import usePersistedState from 'hook/usePersistedState';
import useUnit from 'hook/unit';
import moment from 'moment';

export default function AdminPageList() {
  const { push } = useHistory();
  const { units, getUnits } = useUnit();
  const pageRef = useRef(1);

  const permissions = {
    read: 'user/r',
    create: 'user/c',
    delete: 'user/d',
    update: 'user/u',
  };

  const columns = [
    { id: 'id', label: '#', minWidth: 10 },
    {
      id: 'name',
      label: 'Nome',
      minWidth: 100,
      render: (rowData, index) => (
        <AvatarNameComponent key={index} rowData={rowData} />
      ),
    },
    { id: 'cpf', label: 'CPF', minWidth: 100 },
    {
      id: 'unit',
      label: 'Hospital',
      minWidth: 100,
      render: (rowData, index) => (
        <SpecialtiesNames key={index} specialties={rowData?.units} />
      ),
    },
    { id: 'group.name', label: 'Grupo', minWidth: 100 },
    {
      id: 'created_at',
      label: 'Data de criação',
      minWidth: 10,
      render: (rowData) => (
        <TableCell>{moment(rowData.created_at).format('DD/MM/yyyy')}</TableCell>
      ),
    },
  ];

  const SpecialtiesNames = ({ specialties }) => {
    if (!specialties || !specialties.length > 0)
      return <TableCell>...</TableCell>;
    const concatedNames = specialties.map((item) => item?.name).join(' , ');
    return <TableCell>{concatedNames}</TableCell>;
  };

  const classes = useStyles();
  const {
    data: dataList,
    loading: loadingAdm,
    total: totalAdm,
    deleteLoading,
    getList,
    getDelete,
  } = useAdministrators();
  const [filterUnit, filterStateUnit] = React.useState({
    unitPage: 1,
    unitPerPage: 30,
    unitSearch: '',
  });
  const { unitPage, unitPerPage, unitSearch } = filterUnit;

  const [unitSelected, setUnitSelected] = React.useState({
    label: 'Todos',
    value: 'all',
  });

  const { loadingUnits, dataUnits, totalUnits } = units;

  React.useEffect(() => {
    getUnits(unitPage, unitPerPage, unitSearch);
  }, [unitPage, unitSearch]);

  const [filter, setFilter] = useState({
    page: 1,
    per_page: 10,
    search: '',
    status: 1,
    group_id: 2,
    unit_id: '',
  });

  useEffect(() => {
    getList(filter);
  }, [filter]);

  const handleChangePage = (page) => {
    setFilter({ ...filter, page: page });
  };
  useEffect(() => {
    if (dataList.length < 1) {
      setFilter({ ...filter, page: 1 });
    }
  }, []);

  const handleDeleteProfessional = (id) => {
    dataList.length > 1
      ? getDelete({ id, params: filter })
      : getDelete({
          id,
          params: {
            ...filter,
            page: 1,
          },
        });
  };

  const handleSearch = (search) => {
    typeof search === 'string'
      ? setFilter((old) => ({ ...old, page: 1, search }))
      : false;
  };

  const searchUnit = (unitValue) => {
    filterStateUnit({ ...filterUnit, unitSearch: unitValue });
  };

  const handleChangeUnit = (val) => {
    setUnitSelected(val);

    setFilter((oldState) => ({
      ...oldState,
      unit_id: val?.value,
    }));
  };
  useEffect(() => {
    const { date_start, date_end } = filter;
    if ((date_start, date_end)) getList(filter);
  }, [filter]);

  const unitPagination = () => {
    const nextPage = pageRef.current + 1;
    const totalPage = Math.ceil(totalUnits / unitPerPage);
    if (pageRef.current < totalPage) {
      filterStateUnit({ ...filterUnit, unitPage: nextPage });

      pageRef.current = nextPage;
    }
  };

  const topUnitPagination = () => {
    const previousPage = pageRef.current - 1;
    if (pageRef.current > 1) {
      filterStateUnit({ ...filterUnit, unitPage: previousPage });

      pageRef.current = previousPage;
    }
  };

  const formatUnitsOptions = dataUnits.map((company) => ({
    label: company.name,
    value: company.id,
  }));

  useEffect(() => {
    if (unitSelected?.value !== 0) {
      setFilter((oldState) => ({
        ...oldState,
        page: 1,
      }));
    }
  }, [unitSelected]);
  return (
    <Layout title="Administradores" showToday backArrow>
      <div id="fixed-tab">
        <Divider style={{ paddingTop: 2, marginRight: 20 }} />
      </div>
      <SearchContainer>
        <InputContainer>
          <SearchInputDebounce
            value={filter.search}
            className={classes.inputWidth}
            onChange={handleSearch}
            placeholder="Busque por nome ou CPF"
            style={{
              alignItems: 'center',
              height: 48,
              width: '90%',
            }}
          />
        </InputContainer>
        <InputSelectHospital data-cy="inputSelectHospital">
          <CustomSelect
            isProf={false}
            name="unit"
            loading={loadingUnits}
            placeholder="Selecione o hospital"
            handleChange={handleChangeUnit}
            value={unitSelected}
            searchItem={searchUnit}
            pagination={unitPagination}
            toTopPagination={topUnitPagination}
            options={[{ label: 'Todos', value: 'all' }, ...formatUnitsOptions]}
            isClearable
          />
        </InputSelectHospital>
        <div className={classes.rowContainer}>
          <Tooltip
            title={
              HasPermission(permissions.create) ? '' : 'Você não tem permissão'
            }
            placement="bottom"
            arrow
          >
            <Button
              data-cy="btn_register"
              onClick={
                HasPermission(permissions.create)
                  ? () => push('/cadastros/administradores/cadastrar')
                  : undefined
              }
              className={classes.registerButton}
            >
              <Typography className={classes.registerText}>
                Cadastrar administrador
              </Typography>
            </Button>
          </Tooltip>
        </div>
      </SearchContainer>
      <TableControl data-cy="dataTable">
        <DefaultTable
          dataList={dataList}
          pageByProps={filter.page}
          permissions={permissions}
          columns={columns}
          loading={loadingAdm}
          totalList={totalAdm}
          redirectTo="/cadastros/administradores/editar"
          perPage={filter?.per_page}
          handleChangePage={handleChangePage}
          deleteLoading={deleteLoading}
          onDeleteRequest={handleDeleteProfessional}
          showExportButton={false}
        />
      </TableControl>
    </Layout>
  );
}
