import React, { useEffect } from 'react';
import { useStyles, FormContainer, InputContainer, InputItem } from './styles';
import { Typography } from '@material-ui/core';
import { FastField, Field, Form, Formik } from 'formik';
import CustomTextField from 'shared/component/forms/CustomTextField';
import CustomMaskField from 'shared/component/forms/CustomMaskField';
import SwitchComponent from 'shared/component/switchComponent';
import { schema } from './schema';
import { initialValues } from './initialValues';
import HeaderForm from './components/HeaderForm';
import FooterButton from './components/FooterButton';
import CustomSelect from 'shared/component/forms/CustomSelect';
import DocsImportComponent from './components/DocsImportComponent';
import { useTimezones } from 'hook/timezones';
import AddressForm from 'shared/component/forms/AddressForm';

export default function UnitForm({
  formInitialValues = initialValues,
  isLoading = false,
  isEdit = false,
  status,
  formSubmit = () => {},
}) {
  const classes = useStyles();
  const { data: timezonesList, getList: getListTimezones } = useTimezones();
  const [selectedTimezone, setSelectedTimezone] = React.useState('');
  const [fileLogo, setFileLogo] = React.useState(null);
  const [docs, setDocs] = React.useState(null);

  const mountTimezoneEdit = () => {
    if (isEdit) {
      const { id, name } = formInitialValues?.setting_timezone;
      setSelectedTimezone({ value: id, label: name });
    }
  };

  useEffect(() => {
    getListTimezones({ page: 1, per_page: 24 });
    mountTimezoneEdit();
  }, []);

  const handleFormSubmit = (data) => {
    data.file = fileLogo;
    data.docs = docs;

    formSubmit(data);
  };

  return (
    <>
      <HeaderForm
        formInitialValues={formInitialValues}
        setFileLogo={setFileLogo}
      />
      <Formik
        onSubmit={handleFormSubmit}
        validationSchema={schema}
        initialValues={formInitialValues}
        validateOnBlur
        enableReinitialize
      >
        {({ setFieldValue, handleChange, errors, values }) => (
          <Form>
            <Typography className={classes.dataHospital}>
              Dados do hospital
            </Typography>
            <InputContainer>
              <InputItem style={{ flexGrow: 2.05 }}>
                <FastField
                  data-cy="input_nome_hospital"
                  value={values?.name}
                  label="Nome do hospital"
                  style={{ width: '100%' }}
                  name="name"
                  variant="outlined"
                  component={CustomTextField}
                />
              </InputItem>
              <InputItem>
                <FastField
                  data-cy="input_email_hospital"
                  value={values?.email}
                  label="E-mail"
                  style={{ width: '100%' }}
                  name="email"
                  variant="outlined"
                  component={CustomTextField}
                />
              </InputItem>
            </InputContainer>
            <InputContainer>
              <InputItem>
                <FastField
                  data-cy="input_razao_social_hospital"
                  value={values?.social_name}
                  label="Razão Social"
                  style={{ width: '100%' }}
                  name="social_name"
                  variant="outlined"
                  component={CustomTextField}
                  marginTop={0}
                />
              </InputItem>
              <InputItem>
                <FastField
                  data-cy="input_cnpj_hospital"
                  value={values?.cnpj}
                  label="CNPJ"
                  style={{ width: '100%' }}
                  mask=""
                  format="##.###.###/####-##"
                  name="cnpj"
                  variant="outlined"
                  component={CustomMaskField}
                  onChange={handleChange}
                />
              </InputItem>
              <InputItem>
                <FastField
                  data-cy="input_cell_phone_hospital"
                  value={values?.cell_phone}
                  name="cell_phone"
                  id="cell_phone"
                  style={{ width: '100%' }}
                  label="Telefone"
                  mask=""
                  format="(##) #####-####"
                  variant="outlined"
                  component={CustomMaskField}
                  onChange={handleChange}
                />
              </InputItem>
            </InputContainer>
            <Typography className={classes.address}>Endereço</Typography>
            <AddressForm values={values} setFieldValue={setFieldValue} />

            <FormContainer style={{ marginTop: 48, height: 148 }}>
              <div className={classes.geoContainer}>
                <Typography className={classes.title_geolocation}>
                  Geolocalização (* distância mínima em metros para marcar
                  checkin)
                </Typography>
                <div style={{ height: 20 }} />
                <div className={classes.geo_inputs}>
                  <FastField
                    value={values?.address.geolocation}
                    name="address.geolocation"
                    setFieldValue={setFieldValue}
                    active="Geolocalização ativa"
                    inactive="Geolocalização inativa"
                    component={SwitchComponent}
                  />
                  <div style={{ display: 'flex' }}>
                    <FastField
                      id="Latitude"
                      value={values?.address.latitude}
                      name="address.latitude"
                      style={{ width: 200 }}
                      label="Latitude"
                      variant="outlined"
                      component={CustomTextField}
                    />
                    <div style={{ width: 20 }} />
                    <FastField
                      id="longitude"
                      value={values?.address.longitude}
                      name="address.longitude"
                      label="Longitude"
                      variant="outlined"
                      component={CustomTextField}
                    />
                    <div style={{ width: 20 }} />
                    <FastField
                      value={values?.address.distance}
                      style={{ width: 150 }}
                      type="number"
                      inputProps={{ min: 0 }}
                      name="address.distance"
                      label="Distância"
                      variant="outlined"
                      component={CustomTextField}
                    />
                  </div>
                </div>
              </div>
            </FormContainer>

            <FormContainer style={{ marginTop: 48, height: 148 }}>
              <div className={classes.geoContainer}>
                <Typography className={classes.title_geolocation}>
                  Desabilitar check-in por telefone
                </Typography>
                <div style={{ height: 20 }} />
                <div className={classes.geo_inputs}>
                  <FastField
                    value={values?.phone_checkin_disabled}
                    name="phone_checkin_disabled"
                    setFieldValue={(field, value, shouldValidate) =>
                      setFieldValue(field, value, shouldValidate)
                    }
                    active="Check-in por telefone desabilitado"
                    inactive="Check-in por telefone habilitado"
                    component={SwitchComponent}
                  />
                </div>
              </div>
            </FormContainer>

            <FormContainer style={{ marginTop: 48, height: 200 }}>
              <div className={classes.timesContainer}>
                <Typography className={classes.title_geolocation}>
                  Tolerância de check-in/check-out
                </Typography>
                {/* <div style={{ height: 20 }} /> */}
                <FastField
                  value={!values?.tolerance_disabled}
                  name="tolerance_disabled"
                  setFieldValue={(field, value, shouldValidate) =>
                    setFieldValue(field, !value, shouldValidate)
                  }
                  active="Tolerância ativa"
                  inactive="Tolerância inativa"
                  component={SwitchComponent}
                />
                <div style={{ height: 20 }} />
                <InputContainer>
                  <InputItem>
                    <FastField
                      value={values?.tolerance}
                      style={{ width: '100%' }}
                      type="number"
                      inputProps={{ min: 0 }}
                      name="tolerance"
                      label="Tolerância  (*Tolerância de tempo, em minutos, para marcar checkin)"
                      variant="outlined"
                      component={CustomTextField}
                    />
                  </InputItem>
                  <InputItem>
                    <FastField
                      value={values?.check_out}
                      style={{ width: '100%' }}
                      type="number"
                      inputProps={{ min: 0 }}
                      name="check_out"
                      label="Tolerância  (*Tolerância de tempo, em minutos, para marcar checkout)"
                      variant="outlined"
                      component={CustomTextField}
                    />
                  </InputItem>
                </InputContainer>
              </div>
            </FormContainer>
            <InputContainer>
              <InputItem data-cy="input_timezone_hospital">
                <Field
                  name="setting_timezone.id"
                  placeholder="Fuso horário"
                  options={timezonesList}
                  value={selectedTimezone}
                  setFieldValue={setFieldValue}
                  handleChange={(val) => {
                    setSelectedTimezone(val);
                    setFieldValue('setting_timezone.id', val.value);
                  }}
                  menuList={() => ({ height: 116, overflowY: 'auto' })}
                  errors={
                    errors &&
                    errors.setting_timezone &&
                    errors.setting_timezone.id
                  }
                  component={CustomSelect}
                />
              </InputItem>
            </InputContainer>
            <FormContainer style={{ marginTop: 30, height: 128 }}>
              <div style={{ height: 128, width: '100%' }}>
                <Typography className={classes.title_geolocation}>
                  Observações
                </Typography>
                <FastField
                  data-cy="input_description_hospital"
                  value={values?.description}
                  style={{ width: '100%' }}
                  name="description"
                  variant="outlined"
                  multiline
                  rows={2}
                  component={CustomTextField}
                />
              </div>
            </FormContainer>
            <Typography
              style={{ marginTop: 48, marginBottom: 10 }}
              className={classes.dataHospital}
            >
              Documentos
            </Typography>
            <DocsImportComponent
              setDocs={setDocs}
              setFieldValue={setFieldValue}
            />
            <div data-cy="footerButton" style={{ marginRight: 15 }}>
              <FooterButton
                id={values?.id}
                type="submit"
                loading={isLoading}
                status={status}
                validate={errors}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
