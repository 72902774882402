import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './styles';
import moment from 'moment';

const UserInfoDetails = ({ user, scale }) => {
  const classes = useStyles();

  return (
    <div className={classes.data}>
      <Typography className={classes.name}>{user?.name}</Typography>
      <Typography className={classes.specialty}>{user?.group?.name}</Typography>
      {scale?.date_start && (
        <>
          <Typography className={classes.exchange}>
            {moment(scale?.date_start, 'DD/MM/yyyy HH:mm').format(
              'DD/MM [(]ddd[)]'
            )}
          </Typography>
          <Typography className={classes.exchange}>
            {moment(scale?.date_start, 'DD/MM/yyyy HH:mm').format('HH[h]mm')} às{' '}
            {moment(scale?.date_end, 'DD/MM/yyyy HH:mm').format('HH[h]mm')}
          </Typography>
        </>
      )}
    </div>
  );
};

export default UserInfoDetails;
