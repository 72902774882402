import React, { useEffect, useState } from 'react';
import { InputContainer, InputItem } from './styles';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FastField, Field } from 'formik';
import api from 'service/api';
import { useAuth } from 'hook/auth';
import CustomTextField from 'shared/component/forms/CustomTextField';
import { InputMaskField } from 'shared/component/forms/CustomMaskField';

const initialAddress = {
  city: {
    id: null,
    name: '',
    state: {
      id: null,
      name: '',
    },
  },
  street: '',
  district: '',
};

const AddressForm = ({ values, setFieldValue }) => {
  const { requestIntercept } = useAuth();
  const [address, setAddress] = useState(initialAddress);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [statusMsg, setStatusMsg] = useState();
  const showInput = false;

  useEffect(() => {
    if (address?.city?.id) {
      setFieldValue('address.city.id', address?.city?.id);
      setFieldValue('address.city.name', address?.city?.name);
      setFieldValue('address.city.state.id', address?.city?.state?.id);
      setFieldValue('address.city.state.name', address?.city?.state?.name);
      setFieldValue('address.street', address?.street);
      setFieldValue('address.district', address?.district);
    } else if (!address) {
      setFieldValue('address.city.id', initialAddress?.city?.id);
      setFieldValue('address.city.name', initialAddress?.city?.name);
      setFieldValue('address.city.state.id', initialAddress?.city?.state?.id);
      setFieldValue(
        'address.city.state.name',
        initialAddress?.city?.state?.name
      );
      setFieldValue('address.street', initialAddress?.street);
      setFieldValue('address.district', initialAddress?.district);
    }
  }, [address]);

  const getCep = async (_cep) => {
    setLoading(true);
    const res = await requestIntercept(api.get, `/v2/public/code_post/${_cep}`);

    if (res?.ok) {
      setAddress(res?.data?.data);
      setStatus(res?.status);
      setStatusMsg(res?.data.msg);
    } else {
      setAddress(null);
      setStatus(404);
      setStatusMsg('CEP inválido');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (values?.address?.code_post?.replace(/\D/g, '').length < 8) {
      setAddress(null);
      setIsFirstRender(false);
      return;
    }
    if (!isFirstRender) {
      const timeout = setTimeout(() => {
        getCep(values?.address?.code_post?.replace(/\D/g, ''));
      }, 500);

      return () => {
        clearTimeout(timeout);
      };
    } else {
      setIsFirstRender(false);
    }
  }, [values?.address?.code_post]);

  return (
    <>
      <InputContainer data-cy="inputContainer5_profissionais">
        <InputItem>
          <FastField
            name="address.code_post"
            mask=""
            maskChar=""
            format="99999-999"
            value={values?.address?.code_post}
            variant="outlined"
            label="CEP"
            style={{ width: '100%' }}
            component={InputMaskField}
            onChange={(e) => {
              setFieldValue('address.code_post', e.target.value);
            }}
          />
          {status !== '' && status !== 200 && !loading && (
            <Alert style={{ marginTop: '15px' }} severity="error">
              {statusMsg}
            </Alert>
          )}
          {loading && (
            <Alert
              icon={<CircularProgress />}
              style={{ marginTop: '15px' }}
              severity="info"
            >
              <AlertTitle>Carregando...</AlertTitle>
              Buscando CEP
            </Alert>
          )}
        </InputItem>

        <InputItem>
          <Field
            label="Cidade"
            disabled
            style={{ width: '100%' }}
            name="address.city.name"
            variant="outlined"
            component={CustomTextField}
          />
        </InputItem>
        <InputItem>
          <Field
            label="Estado"
            disabled
            style={{ width: '100%' }}
            name="address.city.state.name"
            variant="outlined"
            component={CustomTextField}
          />
        </InputItem>

        {showInput && (
          <>
            <InputItem>
              <Field
                label="Cidade"
                style={{ display: '' }}
                name="address.city.id"
                variant="outlined"
                component={CustomTextField}
              />
            </InputItem>
            <InputItem>
              <Field
                label="Estado"
                style={{ display: 'none' }}
                name="address.city.state.id"
                variant="outlined"
                component={CustomTextField}
              />
            </InputItem>
          </>
        )}
      </InputContainer>

      <InputContainer data-cy="inputContainer4_profissionais">
        <InputItem>
          <Field
            label="Rua"
            style={{ width: '100%' }}
            name="address.street"
            variant="outlined"
            component={CustomTextField}
          />
        </InputItem>
        <InputItem style={{ flexGrow: 0 }}>
          <Field
            label="Número"
            style={{ width: '100%' }}
            mask="9999"
            name="address.number"
            variant="outlined"
            component={CustomTextField}
          />
        </InputItem>
        <InputItem>
          <Field
            label="Complemento"
            style={{ width: '100%' }}
            name="address.complement"
            variant="outlined"
            component={CustomTextField}
          />
        </InputItem>
        <InputItem>
          <Field
            label="Setor"
            style={{ width: '100%' }}
            name="address.district"
            variant="outlined"
            component={CustomTextField}
          />
        </InputItem>
      </InputContainer>
    </>
  );
};

export default AddressForm;
