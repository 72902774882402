import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles(() => ({
  cancelButton: {
    border: '1px solid #A2A5A8',
    boxSizing: 'border-box',
    borderRadius: 4,
    width: 152,
    marginRight: 24,
  },
  cancelText: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    color: '#A2A5A8',
    textTransform: 'none',
  },
  registerButton: {
    background: '#BBE9F9',
    border: '1px solid #BBE9F9',
    borderRadius: 4,
    width: 152,
    textTransform: 'none',
  },
  saveButton: {
    background: '#0F83AD',
    border: '1px solid #0F83AD',
    borderRadius: 4,
    width: 152,
    textTransform: 'none',
  },
  registerText: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    color: '#0F83AD',
  },
  saveText: {
    fontFamily: 'Poppins',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    color: 'white',
  },
}));

export const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
`;

export const FormContainer = styled.div`
  margin: 0;
`;

export const InputItem = styled.div`
  flex-grow: ${(props) => props.flexGrow || 1};
  margin: 0 10px;
  min-width: 200px;
`;

export const FormSectionTitle = styled.h2`
  margin: 10px;
  font-size: 20px;
  color: #222;
  font-family: Arial;
  font-weight: normal;
`;

export const FormFieldLabel = styled.h2`
  font-size: 16px;
  color: #222;
  font-family: Arial;
  font-weight: bold;
`;
