import { Alert, AlertIcon, Flex } from '@chakra-ui/react';
import { ImageInputComponent } from 'components/forms/image-input';
import InputComponent from 'components/forms/input';
import SelectComponent from 'components/forms/select';
import { useAuth } from 'hook/auth';
import { useCountries } from 'hook/countries';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import api from 'service/api';

function PersonalDataStep() {
  const { requestIntercept } = useAuth();
  const form = useFormContext();
  const codePost = form.watch('address.code_post');
  const { data, total, loading, getList } = useCountries();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [loadingCodePost, setLoadingCodePost] = useState(false);
  const [errorCodePost, setErrorCodePost] = useState({});

  const getCep = async (_cep) => {
    try {
      setLoadingCodePost(true);
      const res = await requestIntercept(
        api.get,
        `/v2/public/code_post/${_cep}`,
        null,
        null,
        true
      );

      if (!res.ok) throw res.data;

      setErrorCodePost({});
      const address = res?.data?.data;

      form.setValue('address.city.id', address?.city?.id);
      form.setValue('address.city.name', address?.city?.name);
      form.setValue('address.city.state.id', address?.city?.state?.id);
      form.setValue('address.city.state.name', address?.city?.state?.name);
      form.setValue('address.city.state.uc', address?.city?.state?.uc);
      form.setValue('address.street', address?.street);
      form.setValue('address.district', address?.district);
      setLoadingCodePost(false);
    } catch (error) {
      setErrorCodePost(error);
      setLoadingCodePost(false);
    }
  };

  function resetAddress() {
    form.resetField('address.city.id', undefined);
    form.resetField('address.city.name', undefined);
    form.resetField('address.city.state.id', undefined);
    form.resetField('address.city.state.name', undefined);
    form.resetField('address.city.state.uc', undefined);
    form.resetField('address.street', undefined);
    form.resetField('address.district', undefined);
  }

  useEffect(() => {
    if (codePost?.replace(/\D/g, '').length < 8) {
      resetAddress();
      return;
    }
    if (!isFirstRender) {
      const timeout = setTimeout(() => {
        getCep(codePost?.replace(/\D/g, ''));
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [codePost]);

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  return (
    <div className="flex flex-col pt-2 gap-4">
      <div>
        <ImageInputComponent name="image" placeholder="Adicionar foto" />
      </div>

      <div className="flex flex-col gap-4">
        <span className="opacity-75 font-bold text-lg">Dados pessoais</span>
        <div className="flex flex-1 gap-4">
          <div className="flex flex-1">
            <InputComponent
              name="name"
              label="Nome"
              placeholder="Nome completo"
            />
          </div>

          <div className="flex flex-1">
            <InputComponent name="email" label="Email" placeholder="Email" />
          </div>
        </div>

        <div className="flex flex-1 gap-4">
          <div className="flex flex-1">
            <SelectComponent
              name="genre"
              label="Sexo"
              placeholder="Sexo"
              options={[
                { value: 'M', label: 'Masculino' },
                { value: 'F', label: 'Feminino' },
              ]}
            />
          </div>

          <div className="flex flex-1">
            <InputComponent
              name="birth_date"
              mask="99/99/9999"
              label="Data de nascimento"
              placeholder="Data de nascimento"
            />
          </div>

          <div className="flex flex-1">
            <InputComponent
              name="cell_phone"
              mask="(99) 9 9999-9999"
              label="Telefone"
              placeholder="Telefone"
            />
          </div>
        </div>

        <div className="flex flex-1 gap-4">
          <div className="flex flex-1">
            <InputComponent
              name="cpf"
              mask="999.999.999-99"
              label="CPF"
              placeholder="CPF"
            />
          </div>

          <div className="flex flex-1">
            <InputComponent name="rg" label="RG" placeholder="RG" />
          </div>
        </div>

        <div className="flex flex-1 gap-4">
          <div className="flex flex-1">
            <SelectComponent
              name="nationality"
              label="Nacionalidade"
              placeholder="Nacionalidade"
              isLoading={loading}
              total={total}
              getOptions={getList}
              options={data ?? []}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            />
          </div>

          <div className="flex flex-1">
            <SelectComponent
              name="civil_status"
              label="Estado civil"
              placeholder="Estado civil"
              options={[
                { value: 1, label: 'Solteiro(a)' },
                { value: 2, label: 'Casado(a)' },
                { value: 3, label: 'Viúvo(a)' },
                { value: 4, label: 'União estável' },
                { value: 5, label: 'Divorciado(a)' },
                { value: 6, label: 'Outros' },
              ]}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-4 mt-5">
        <span className="opacity-75 font-bold text-lg">Endereço</span>
        <div className="flex flex-1 gap-4">
          <div className="flex flex-1 flex-col">
            <InputComponent
              name="address.code_post"
              label="CEP"
              placeholder="CEP"
              isLoading={loadingCodePost}
              mask="99999-999"
            />

            {errorCodePost?.status === 404 && (
              <Flex mt={5} mb={5}>
                <Alert borderRadius="10px" status="error">
                  <AlertIcon />
                  {errorCodePost?.msg}
                </Alert>
              </Flex>
            )}
          </div>

          <div className="flex flex-1">
            <InputComponent
              name="address.city.name"
              label="Cidade"
              placeholder="Cidade"
              disabled
            />
          </div>

          <div className="flex flex-1">
            <InputComponent
              name="address.city.state.name"
              label="Estado"
              placeholder="Estado"
              disabled
            />
          </div>
        </div>

        <div className="flex flex-1 gap-4">
          <div className="flex flex-[2]">
            <InputComponent
              name="address.district"
              label="Bairro"
              placeholder="Bairro"
            />
          </div>

          <div className="flex flex-[2]">
            <InputComponent
              name="address.street"
              label="Rua"
              placeholder="Rua"
            />
          </div>

          <div className="flex flex-[1]">
            <InputComponent
              name="address.number"
              label="Número"
              placeholder="Número"
            />
          </div>

          <div className="flex flex-[2]">
            <InputComponent
              name="address.complement"
              label="Complemento"
              placeholder="Complemento"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalDataStep;
