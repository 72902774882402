import React, {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {
  useStyles,
  StyledList,
  InputContainer,
  ChipsContainer,
} from './styles';
import { CircularProgress, Typography } from '@material-ui/core';
import FooterBox from 'page/welcome/components/footerBox';
import SearchInputDebounce from '../SearchInputDebounce';
import ChipFilterHome from '../chipFilterHome';

const CustomUnitsCheckboxes = forwardRef(
  (
    {
      loadingUnits = false,
      selectedUnits,
      setSelectedUnits,
      getList = () => {},
      onSubmit = () => {},
    },
    ref
  ) => {
    const classes = useStyles();

    const page_ref = useRef(1);
    const filter = {
      page: 1,
      per_page: 20,
      status: 'active',
      search: '',
    };
    const [data, setData] = useState({});
    const [pagination, setPagination] = useState({});

    const [checked, setChecked] = useState(() => {
      let storageUnits = localStorage.getItem('plantãoExtra@hospital');
      if (storageUnits) {
        storageUnits = JSON.parse(storageUnits);
        return [...storageUnits?.map((item) => item.id)];
      }
      return [];
    });

    const handleToggle = (unit_id, unit) => () => {
      const currentIndex = checked.indexOf(unit_id);
      const newChecked = [...checked];
      const newSelectedUnits = [...selectedUnits];

      if (currentIndex === -1) {
        if (checked?.length < 3) {
          newChecked.push(unit_id);
          newSelectedUnits.push(unit);
        } else {
          newChecked.splice(2, 1);
          newChecked.push(unit_id);
          newSelectedUnits.splice(2, 1);
          newSelectedUnits.push(unit);
        }
      } else {
        newChecked.splice(currentIndex, 1);
        newSelectedUnits.splice(currentIndex, 1);
      }

      setChecked(newChecked);
      setSelectedUnits(newSelectedUnits);
    };

    const onScrollBottom = (event) => {
      const bottom =
        event.currentTarget.scrollHeight - event.currentTarget.scrollTop <=
        event.currentTarget.clientHeight + 5;
      if (
        bottom &&
        !loadingUnits &&
        pagination?.current_page < pagination?.total_pages
      ) {
        const nextPage = page_ref.current + 1;

        handleNextPage({ ...filter, page: nextPage });
        page_ref.current = nextPage;
      }
    };

    useEffect(() => {
      getList({
        page: 1,
        per_page: 20,
        status: 'active',
        search: '',
      }).then((response) => {
        setData(response.data.data);
        setPagination(response.data.pagination);
      });
    }, []);

    const handleRemoveAside = (unit_id) => {
      setChecked((old) => [...old.filter((item) => item !== unit_id)]);
      setSelectedUnits((old) => [
        ...old.filter((item) => item?.id !== unit_id),
      ]);
    };

    const handleNextPage = async (filter) => {
      const nextPage = page_ref.current + 1;
      const response = await getList(filter);
      setData((old) => [...old, ...response.data.data]);
      setPagination(response.data.pagination);
      page_ref.current = nextPage;
    };

    const handleSearch = (search) => {
      getList({ page: 1, per_page: 20, status: 'active', search }).then(
        (response) => {
          setData(response.data.data);
          setPagination(response.data.pagination);
        }
      );
    };

    useImperativeHandle(
      ref,
      () => {
        return {
          handleRemoveAside,
        };
      },
      []
    );

    return (
      <>
        <ChipsContainer>
          {selectedUnits &&
            selectedUnits.length > 0 &&
            selectedUnits.map((item) => (
              <ChipFilterHome
                key={item.id}
                name={item.name}
                id={item.id}
                itemLength={Object.keys(item).length}
                onClick={handleToggle(item?.id, item)}
              />
            ))}
        </ChipsContainer>
        <InputContainer>
          <SearchInputDebounce
            className={classes.inputWidth}
            onChange={handleSearch}
            placeholder="Buscar hospitais"
            style={{
              alignItems: 'center',
              height: 48,
              width: '90%',
            }}
          />
        </InputContainer>

        <StyledList onScroll={(event) => onScrollBottom(event)}>
          {data &&
            data?.length > 0 &&
            data.map((item) => {
              const labelId = `checkbox-list-label-${item?.id}`;
              if (checked.indexOf(item?.id) === -1)
                return (
                  <ListItem
                    key={item?.id}
                    role={undefined}
                    dense
                    button
                    onClick={handleToggle(item?.id, item)}
                  >
                    <ListItemText id={labelId} primary={`${item?.name}`} />
                  </ListItem>
                );
            })}
        </StyledList>
        <div style={{ minHeight: 20 }}>
          {data.length === 0 && filter?.search !== '' && (
            <div className={classes.loadingContainer}>
              <Typography>Nenhum resultado encontrado</Typography>
            </div>
          )}
          {loadingUnits && (
            <div className={classes.loadingContainer}>
              <CircularProgress size={25} color="primary" />
            </div>
          )}
        </div>
        <FooterBox
          setSelectedUnits={setSelectedUnits}
          setChecked={setChecked}
          checked={checked}
          selectedUnits={selectedUnits}
          onSubmit={onSubmit}
        />
      </>
    );
  }
);
export default CustomUnitsCheckboxes;
