import React from 'react';
import { Button, Modal } from '@material-ui/core';

export function ModalImage({ open, close, title, image }) {
  return (
    <Modal
      open={open}
      onClose={close}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          width: '40vw',
          height: '80vh',
          padding: '1rem',
          background: '#FFFFFF',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <p className="font-bold text-center">{title}</p>
        <div className="max-h-[85%]">
          <img
            src={image}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            style={{
              background: '#0F83AD',
              width: '136px',
              height: '32px',
              fontSize: '12px',
              borderRadius: '4px',
              color: '#ffffff',
              textTransform: 'unset',
              marginTop: '3px',
            }}
            onClick={close}
          >
            Fechar
          </Button>
        </div>
      </div>
    </Modal>
  );
}
