import { Radio, RadioGroup, Stack } from '@chakra-ui/react';
import InputComponent from 'components/forms/input';
import SelectComponent from 'components/forms/select';
import { useCompany } from 'hook/company';
import { useEnterprise } from 'hook/enterprise';
import { useGroups } from 'hook/groups';
import { useSpecialty } from 'hook/specialty/index';
import { useStates } from 'hook/states';
import { useSubsidiary } from 'hook/subsidiary';
import { useUnits } from 'hook/units';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

function ProfessionalDataStep() {
  const form = useFormContext();
  const companyId = form.watch('company.id');
  const professionalType = form.watch('professional_type');
  const hasRegulationAgency = form.watch('has_regulation_agency');
  const group = form.watch('group');
  const profileReceiving = form.watch('profile_receiving');
  const profileReceivingType = form.watch('profile_receiving_type');
  const {
    data: groupDoctor,
    loading: loadingGroupDoctor,
    getOne: getGroupDoctor,
  } = useGroups();
  const {
    data: dataGroups,
    total: totalGroups,
    loading: loadingGroups,
    getList: getGroups,
  } = useGroups();
  const {
    data: dataStates,
    total: totalStates,
    loading: loadingStates,
    getList: getStates,
  } = useStates();
  const {
    data: dataEnterprises,
    total: totalEnterprises,
    loading: loadingEnterprises,
    getList: getEnterprises,
  } = useEnterprise();
  const {
    data: dataCompanies,
    total: totalCompanies,
    loading: loadingCompanies,
    getList: getCompanies,
  } = useCompany();
  const {
    data: dataSubsidiaries,
    total: totalSubsidiaries,
    loading: loadingSubsidiaries,
    getList: getSubsidiaries,
  } = useSubsidiary();
  const {
    data: dataSpecialties,
    total: totalSpecialties,
    loading: loadingSpecialties,
    getList: getSpecialties,
  } = useSpecialty();
  const {
    data: dataUnits,
    total: totalUnits,
    loading: loadingUnits,
    getList: getUnits,
  } = useUnits({ isMyUnits: false });

  useEffect(() => {
    getGroupDoctor({ id: 3 });
  }, []);

  return (
    <div className="flex flex-col pt-2 gap-4">
      <div className="flex flex-col gap-4">
        <span className="opacity-75 font-bold text-lg">
          Dados profissionais
        </span>
        <div>
          <RadioGroup
            onChange={(value) => {
              form.resetField('regulation_agency', { defaultValue: undefined });
              form.resetField('crm.number', { defaultValue: '' });
              form.resetField('crm.state', { defaultValue: '' });
              form.setValue('crm.professional_type', value);
              form.setValue('professional_type', value);
              if (value === 'multiprofissional') {
                form.resetField('group', { defaultValue: undefined });
                form.setValue('has_regulation_agency', false);
              } else {
                form.resetField('group', { defaultValue: undefined });
                form.setValue('has_regulation_agency', false);
                setTimeout(() => {
                  form.setValue('group.id', 3);
                  form.setValue('has_regulation_agency', true);
                }, 50);
              }
            }}
            value={professionalType || 'doctor'}
          >
            <Stack direction="row" gap={5}>
              <Radio value="doctor">Médico</Radio>
              <Radio value="multiprofissional">Multiprofissional</Radio>
            </Stack>
          </RadioGroup>
        </div>

        {professionalType !== 'doctor' && (
          <div className="flex flex-1">
            <SelectComponent
              name="group"
              label="Grupo"
              placeholder="Selecione o grupo"
              customFilter={{ type: 2 }}
              isLoading={loadingGroups}
              total={totalGroups}
              getOptions={getGroups}
              options={dataGroups ?? []}
              onValueChange={(val) => {
                form.resetField('regulation_agency', {
                  defaultValue: undefined,
                });
                form.resetField('crm.number', { defaultValue: '' });
                form.resetField('crm.state', { defaultValue: '' });
                if (val?.need_regulation_agency)
                  form.setValue('has_regulation_agency', true);
                else form.setValue('has_regulation_agency', false);
              }}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            />
          </div>
        )}

        {hasRegulationAgency && (
          <div className="flex flex-1 gap-4">
            <div className="flex flex-1">
              <SelectComponent
                name="regulation_agency"
                label="Conselho regulador"
                placeholder="Selecione o conselho regulador"
                options={
                  group?.id === 3
                    ? groupDoctor?.regulation_agencies ?? []
                    : group?.regulation_agencies ?? []
                }
                isLoading={loadingGroupDoctor || loadingGroups}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
              />
            </div>

            <div className="flex flex-1">
              <InputComponent
                name="crm.number"
                label="Numero do conselho"
                placeholder="Numero do conselho regulador"
              />
            </div>

            <div className="flex flex-1">
              <SelectComponent
                name="crm.state"
                label="Unidade Federativa"
                placeholder="Selecione o estado"
                isLoading={loadingStates}
                total={totalStates}
                getOptions={getStates}
                options={dataStates ?? []}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
              />
            </div>
          </div>
        )}

        {(professionalType === 'doctor' || companyId === 3) && (
          <div className="flex gap-20">
            {professionalType === 'doctor' && (
              <>
                <div>
                  <span className="opacity-75 font-bold text-md">
                    Recebimento
                  </span>

                  <RadioGroup
                    onChange={(value) => {
                      form.setValue('profile_receiving', parseInt(value));
                      if (value === 1)
                        form.setValue('profile_receiving_type', parseInt(1));
                    }}
                    value={profileReceiving || 1}
                  >
                    <Stack direction="row" gap={5}>
                      <Radio value={1}>Nota Fiscal</Radio>
                      <Radio value={2}>Sócio</Radio>
                    </Stack>
                  </RadioGroup>
                </div>

                {profileReceiving === 2 && (
                  <div>
                    <span className="opacity-75 font-bold text-md">Sócio</span>

                    <RadioGroup
                      onChange={(value) => {
                        form.setValue(
                          'profile_receiving_type',
                          parseInt(value)
                        );
                      }}
                      value={profileReceivingType || 1}
                    >
                      <Stack direction="row" gap={5}>
                        <Radio value={1}>PJ</Radio>
                        <Radio value={2}>PF</Radio>
                      </Stack>
                    </RadioGroup>
                  </div>
                )}
              </>
            )}

            {companyId === 3 && (
              <div className="flex flex-1">
                <InputComponent
                  name="creator_relationship"
                  label="Vínculo"
                  placeholder="Vínculo com a empresa"
                />
              </div>
            )}
          </div>
        )}

        <div className="flex flex-1 gap-4">
          <div className="flex flex-1">
            <SelectComponent
              name="enterprise"
              label="Empresa"
              placeholder="Selecione a empresa"
              isLoading={loadingEnterprises}
              total={totalEnterprises}
              getOptions={getEnterprises}
              options={dataEnterprises ?? []}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            />
          </div>

          <div className="flex flex-1">
            <SelectComponent
              name="company"
              label="Companhia"
              placeholder="Selecione a companhia"
              isLoading={loadingCompanies}
              total={totalCompanies}
              getOptions={getCompanies}
              options={dataCompanies ?? []}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            />
          </div>

          <div className="flex flex-1">
            <SelectComponent
              name="subsidiary"
              label="Filial"
              placeholder="Selecione a filial"
              isLoading={loadingSubsidiaries}
              total={totalSubsidiaries}
              getOptions={getSubsidiaries}
              options={dataSubsidiaries ?? []}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            />
          </div>
        </div>

        {professionalType === 'doctor' && (
          <SelectComponent
            name="specialties"
            label="Especialidades"
            placeholder="Selecione as especialidades"
            isLoading={loadingSpecialties}
            total={totalSpecialties}
            getOptions={getSpecialties}
            options={dataSpecialties ?? []}
            isMulti
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
          />
        )}

        <div className="flex flex-1">
          <SelectComponent
            name="units"
            label="Hospitais permitidos"
            placeholder="Hospitais permitidos"
            isLoading={loadingUnits}
            total={totalUnits}
            getOptions={getUnits}
            options={dataUnits ?? []}
            isMulti
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
          />
        </div>
      </div>
    </div>
  );
}

export default ProfessionalDataStep;
