import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { DashboardV2PageContext } from 'page/dashboardV2/index';
import CustomSelect from 'shared/component/forms/CustomSelect';
import HorizontalScrollMenu from 'shared/component/forms/CustomHorizontalMenu';
import appColors from 'utils/appColors';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 3,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  titleStyle: {
    fontSize: '32px',
    fontFamily: 'Poppins',
    marginLeft: 10,
    color: appColors.GRAY_TEXT_COLOR,
    textAlign: 'start',
    maxWidth: '480px', // Defina o valor máximo desejado para a largura
    overflow: 'hidden',
    textOverflow: 'ellipsis', // Adiciona "..." se o texto ultrapassar o limite
    whiteSpace: 'nowrap', // Impede a quebra de linha
  },
}));

export default function TopSelectsGrid() {
  const classes = useStyles();
  const {
    userLogged,
    loadingCompanies,
    handleChangeCompany,
    company,
    searchCompany,
    companyPagination,
    topCompanyPagination,
    dataCompany,
    dataUnits,
    handleChangeUnit,
  } = useContext(DashboardV2PageContext);

  const formatCompaniesOptions =
    dataCompany &&
    dataCompany.length > 0 &&
    dataCompany.map((company) => ({
      label: company.name,
      value: company.id,
    }));

  const formatUnitsOptions = dataUnits.map((company) => ({
    label: company.name,
    value: company.id,
  }));

  return (
    <div className={classes.root}>
      <Grid justify="flex-start" container spacing={3}>
        {userLogged.group.id === 1 && (
          <Grid data-cy="select_company" item xs={4}>
            <CustomSelect
              name="company"
              loading={loadingCompanies}
              placeholder="Selecione a empresa responsável"
              handleChange={handleChangeCompany}
              value={company}
              searchItem={searchCompany}
              pagination={companyPagination}
              toTopPagination={topCompanyPagination}
              options={formatCompaniesOptions}
              isClearable
            />
          </Grid>
        )}
        {userLogged.group.id !== 1 && (
          <Grid data-cy="select_company" item xs={4}>
            <Typography className={classes.titleStyle}>
              {userLogged?.company?.name}
            </Typography>
          </Grid>
        )}
        {formatUnitsOptions && Object.keys(formatUnitsOptions).length > 0 && (
          <Grid data-cy="select_unit" xs={8}>
            <HorizontalScrollMenu
              items={formatUnitsOptions}
              onClick={handleChangeUnit}
              maxWidth={1100}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
}
