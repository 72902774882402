import moment from 'moment';
import logoPdf from './logoPdf';
import logoUnitPdf from './logoUnitPdf';

export default function Index(
  dataExportPdf,
  formatDateStart,
  totalExportPdf,
  logo,
  unitsNames,
  dateStart,
  dateEnd
) {
  let HeaderRelatory = '';
  const otherData = moment().add(-29, 'days').format('DD/MM/YYYY');

  HeaderRelatory += `
  <div style="display: flex; margin-top: 20px; margin-bottom: 40px; justify-content: space-between; align-items: center; color: white;">   
    ${logoPdf()}
    ${logoUnitPdf(logo) || ''}
  </div>`;

  HeaderRelatory += `<div class="divHeader">
      <div class="columnLeft">Período</div>
      <div class="columnRight">Período entre ${dateStart || otherData} a ${
    dateEnd || formatDateStart
  }</div>
    </div>`;

  HeaderRelatory += `<div class="divHeader">
      <div class="columnLeft">Empresa</div>
      <div class="columnRight">${dataExportPdf[0].company_name || ''}</div>
    </div>`;

  HeaderRelatory += `<div class="divHeader">
      <div class="columnLeft">Unidades</div>
      <div class="columnRight">${unitsNames || 'todas as unidades'}</div>
    </div>`;

  HeaderRelatory += `<div class="divHeader">
      <div style="padding-top: 8px" class="columnLeft">TOTAL</div>
      <div style="padding-top: 8px" class="columnRight">${
        totalExportPdf || ''
      }</div>
    </div>`;

  HeaderRelatory += '</br>';

  let TableRelatory = '';
  // salva o id do primeiro child
  let firstIdChild = dataExportPdf[0].group_id;
  let currentUnit = dataExportPdf[0].unit_id;

  TableRelatory += `<div class="divHeader">
      <div style="padding-top: 8px" class="columnLeft2">Unidade:</div>
      <div style="padding-top: 8px" class="columnRight2">${
        dataExportPdf[0].unit_name || ''
      }</div>
    </div>`;
  TableRelatory += `<div class="divHeader">
      <div style="padding-top: 8px" class="columnLeft2">Especialidade:</div>
      <div style="padding-top: 8px" class="columnRight2">${
        dataExportPdf[0].group_name || ''
      }</div>
    </div>`;
  TableRelatory += '</br>';
  TableRelatory += ` 
      <table style="width:100%; border: none;">
      <tr>
        <th style="width:40%; border: none;">Tipo</th>
        <th>Nome</th>
      </tr>
      <tr>
        <td style="width:40%; border: none;">${
          dataExportPdf[0].specialty_name || ''
        }</td>
        <td>${dataExportPdf[0].user_name || ''}</td>
      </tr>  
  `;

  dataExportPdf.slice(1).map((item) => {
    if (item.unit_id !== currentUnit) {
      TableRelatory += '</table></div>  ';
      TableRelatory += `</br></br></br><div class="divHeader2">
      <div style="padding-top: 8px" class="columnLeft2">Unidade:</div>
      <div style="padding-top: 8px" class="columnRight2">${
        item.unit_name || ''
      }</div>
    </div>`;
      TableRelatory += `<div class="divHeader2">
      <div style="padding-top: 8px" class="columnLeft2">Especialidade:</div>
      <div style="padding-top: 8px" class="columnRight2">${
        item.group_name || ''
      }</div>
    </div>`;
      TableRelatory += ` 
        <table style="width:100%; border: none;">
        <tr>
          <th style="width:40%; border: none;"></th>
          <th></th>
        </tr>
        <tr>
          <td  style="width:40%; border: none;">${
            item.specialty_name || ''
          }</td>
          <td>${item.user_name || ''}</td>
        </tr>  
        `;
      currentUnit = item.unit_id;
    } else if (item.group_id !== firstIdChild) {
      TableRelatory += '</table></div>  ';

      TableRelatory += `</br></br></br><div class="divHeader2">
        <div style="padding-top: 8px" class="columnLeft2">Especialidade:</div>
        <div style="padding-top: 8px" class="columnRight2">${
          item.group_name || ''
        }</div>
      </div>`;

      TableRelatory += `<div class="divHeader2">
        <div style="padding-top: 8px" class="columnLeft2">Tipo:</div>
        <div style="padding-top: 8px" class="columnRight2">${
          item.specialty_name || ''
        }</div>
      </div>`;
      // troco o valor de firstIdChild para comparar no proximo laço
      firstIdChild = item.group_id;
    } else {
      TableRelatory += '</table></div>  ';

      TableRelatory += ` 
        <table style="width:100%; border: none;">
        <tr>
          <th style="width:40%; border: none;"></th>
          <th></th>
        </tr>
        <tr>
          <td  style="width:40%; border: none;">${
            item.specialty_name || ''
          }</td>
          <td>${item.user_name || ''}</td>
        </tr>  
        `;
    }
  });

  TableRelatory += '</table>';

  const Style = `
    <style>
      table {
        font-family: Roboto, sans-serif;
        border: none;
        width: 100%;
      }
  
      td, th {
        border: none;
        text-align: left;
        padding: 5px 3px;
      }
  
      tr:nth-child(even) {
        background-color: rgba(70, 130, 180, 0.1);
        box-shadow: 0 0 0 1000px #DBEEF4 inset;
      }
  
      .divHeader {
        display: flex; width: 100%; margin-bottom: 5px; min-height: 40px; font-size: 20px; font-weight: bold; color: #18191A;
      }

      .columnLeft {
        width: 40%; background-color: #2aabe2; box-shadow: 0 0 0 1000px #2aabe2 inset; text-align: center; color: #18191A; padding-top: 5px; color: #18191A;
      }
  
      .columnRight {
        width: 60%; background-color: #DBEEF4; box-shadow: 0 0 0 1000px #DBEEF4 inset; text-align: center; color: #18191A; padding-top: 5px;
      }

      .divHeader2 {
        display: flex; width: 100%; margin-bottom: 5px; min-height: 20; font-size: 16px; font-weight: bold; color: #18191A;
      }
      
      .columnLeft2 {
        width: 40%; background-color: #E4E4E7; box-shadow: 0 0 0 1000px #E4E4E7 inset; text-align: center; padding-top: 5px; color: #18191A;
      }
  
      .columnRight2 {
        width: 60%; background-color: #E4E4E7; box-shadow: 0 0 0 1000px #E4E4E7 inset; text-align: center; color: #18191A; padding-top: 5px;
      }
    </style>`;

  const bodyContent = HeaderRelatory + TableRelatory + Style;
  return bodyContent;
}
