import { extendTheme } from '@chakra-ui/react';

export const colors = {
  theme: {
    light: {
      body: '#FAFAFA',
      bodySecondary: 'white',
      primary: '#24B8EC',
      secondary: '#ffcc00',
      tertiary: '#0044ff',
    },
    dark: {
      body: '#FAFAFA',
      bodySecondary: 'white',
      primary: '#24B8EC',
      secondary: '#ffcc00',
      tertiary: '#0044ff',
    },
  },

  button: {
    primary: {
      500: '#24B8EC',
    },
  },

  text: {
    light: {
      primary: '#505255',
      secondary: '#24B8EC',
      tertiary: '#BBBDBF',
      divider: '#292b3a',
    },
    dark: {
      primary: '#505255',
      secondary: '#24B8EC',
      tertiary: 'white',
      divider: '#292b3a',
    },
  },
};

export const theme = extendTheme({
  colors,
  fonts: {
    heading: `'Poppins', sans-serif`,
    body: `'Poppins', sans-serif`,
  },
});
