import React, { useEffect } from 'react';
import { Tabs, Tab, styled } from '@material-ui/core';
import appColors from 'utils/appColors';

// Customized Tab component
const CustomTab = styled(Tab)(({ theme }) => ({
  minWidth: 0,
  // marginRight: theme.spacing(2),
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  textTransform: 'none',
  color: '#ffffff', // Customize the text color
  backgroundColor: appColors.PRIMARY_COLOR,
  borderRadius: '4px',
  fontSize: 13,
  '&.Mui-selected': {
    backgroundColor: appColors.SECUNDARY_COLOR, // Customize the background color when selected
  },
}));
const HorizontalScrollMenu = ({ items, onClick, maxWidth }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    onClick(items[0]);
  }, []);

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable auto tabs example"
      style={{
        marginTop: 15,
        maxWidth: maxWidth,
        paddingLeft: 0,
        paddingRight: 0,
      }}
    >
      {items
        ?.filter(
          (scale) =>
            typeof scale.count_agenda !== 'number' || scale.count_agenda > 0
        )
        .map((item) => (
          <div className="flex flex-col items-center gap-2 pb-2 pr-2">
            <CustomTab
              key={item.value}
              label={item.label}
              onClick={() => onClick(item)}
            />
            {item.count_agenda && (
              <span>
                {item.count_agenda}{' '}
                {item.count_agenda > 1 || item.count_agenda === 0
                  ? 'plantões'
                  : 'plantão'}
              </span>
            )}
          </div>
        ))}
    </Tabs>
  );
};

export default HorizontalScrollMenu;
