import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ModalCancelSchedule } from './ModalCancelSchedule';
import MessageNotification from 'shared/component/messageNotification';
import TableCards from '../TableCards';
import { useStyles, TableContent } from './styles';
import { Button, Icon, Box, Tooltip } from '@material-ui/core';
import moment from 'moment';
import ScheduleInfo from '../ScheduleInfo';
import { ModalResponseAlert } from '../ModalResponseAlert';
import { ScheduleWeekPageContext } from '../..';
import HasPermission from 'utils/checkPermission';

const ScheduleTable = ({ schedule, permissions }) => {
  const classes = useStyles();
  const [openModalCancellation, setOpenModalCancellation] = useState(false);
  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [responseMessage, setResponseMessage] = useState();
  const [statusError, setStatusError] = useState(false);
  const [responseStatusAlert, setResponseStatusAlert] = useState(false);
  const [responseStatusError, setResponseStatusError] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDeletingMany, setIsDeletingMany] = useState(false);
  const [selectedCards, setSelectedCards] = useState([]);
  const { handleGetAgenda = () => {} } = useContext(ScheduleWeekPageContext);

  const formatHour = (hour) => moment(hour, 'HHmmss').format('HH:mm');

  const mountShedulesPeriod = useCallback(
    (item) => {
      return `${formatHour(item?.hour_start)} - ${formatHour(item?.hour_end)}`;
    },
    [schedule]
  );

  const countTotal = (total, num) => {
    return total + num;
  };

  const countRemainingVacancies = useCallback(
    (sched) => {
      let ocupped = sched.map((item) => item.agenda.length);

      ocupped = ocupped.length > 0 ? ocupped.reduce(countTotal) : 0;

      let vacancies = sched
        .filter((item) => item?.status === 1)
        .map((item) => item?.quantity_professional);

      vacancies = vacancies.length > 0 ? vacancies.reduce(countTotal) : 0;

      const diference = vacancies - ocupped;
      return diference > 0 ? diference : 0;
    },
    [schedule]
  );

  const checkIfAreAllSelected = (schedules = schedule?.schedules) => {
    let state = true;
    let hasSchedules = false;
    if (schedules === schedule?.schedules) {
      schedules?.forEach((allSchedules) => {
        allSchedules.forEach((hourSchedule) => {
          hourSchedule.agenda.forEach((schedule) => {
            if (selectedCards.includes(schedule.id)) {
              hasSchedules = true;
              return;
            } else state = false;
          });
        });
      });
      return hasSchedules ? state : false;
    } else {
      schedules?.forEach((hourSchedule) => {
        hourSchedule.agenda.forEach((schedule) => {
          if (selectedCards.includes(schedule.id)) {
            hasSchedules = true;
            return;
          } else state = false;
        });
      });
      return hasSchedules ? state : false;
    }
  };

  const handleSelectAll = (schedules = schedule?.schedules) => {
    const ids = [];
    if (schedules === schedule?.schedules) {
      schedules?.forEach((allSchedules) => {
        allSchedules.forEach((hourSchedule) => {
          hourSchedule.agenda.forEach((schedule) => {
            if (!selectedCards.includes(schedule.id)) ids.push(schedule.id);
          });
        });
      });
    } else {
      schedules?.forEach((hourSchedule) => {
        hourSchedule.agenda.forEach((schedule) => {
          if (!selectedCards.includes(schedule.id)) ids.push(schedule.id);
        });
      });
    }
    setSelectedCards((old) => [...old, ...ids]);
  };

  const handleUnselectAll = (schedules) => {
    const removeIds = [];
    if (!schedules) {
      setSelectedCards([]);
    } else {
      schedules?.forEach((hourSchedule) => {
        hourSchedule.agenda.forEach((schedule) => {
          removeIds.push(schedule.id);
        });
      });
      const newIds = selectedCards.filter((value) => {
        return !removeIds.includes(value);
      });
      setSelectedCards(newIds);
    }
  };

  const handleCloseSendFinish = () => {
    setOpenAlertMessage(false);
    setOpenModalCancellation(false);
    if (responseStatusAlert) {
      handleGetAgenda();
    }
  };

  useEffect(() => {
    setSelectedCards([]);
  }, [isDeletingMany]);

  return (
    <div className={classes.root}>
      <div className={classes.deleteMany}>
        <Button
          disabled={schedule?.agendas.length === 0}
          variant="outlined"
          className={classes.expandButton}
          onClick={() => setIsExpanded((old) => !old)}
          startIcon={
            <Icon>
              {isExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
            </Icon>
          }
        >
          {isExpanded ? 'Encolher' : 'Expandir'} cards
        </Button>

        <Tooltip
          title={
            HasPermission(permissions.delete) ? '' : 'Você não tem permissão'
          }
          placement="bottom"
          arrow
        >
          {isDeletingMany ? (
            <Button
              variant="outlined"
              disabled={!HasPermission(permissions.delete)}
              className={classes.selectAllButton}
              style={{
                opacity: HasPermission(permissions.delete) ? 1 : 0.6,
                fontSize: 12,
                background: checkIfAreAllSelected() ? '#0F83AD' : '',
                color: checkIfAreAllSelected() ? '#ffffff' : '',
              }}
              onClick={() => {
                if (checkIfAreAllSelected()) {
                  handleUnselectAll();
                } else {
                  handleSelectAll();
                }
              }}
            >
              {checkIfAreAllSelected() ? 'Desmarcar todos' : 'Marcar todos'}
            </Button>
          ) : (
            <Button
              variant="outlined"
              disabled={!HasPermission(permissions.delete)}
              className={classes.deleteButton}
              onClick={() => setIsDeletingMany((old) => !old)}
              startIcon={<Icon>delete</Icon>}
            >
              Deletar
            </Button>
          )}
        </Tooltip>
      </div>
      {isDeletingMany && (
        <Box
          style={{
            position: 'fixed',
            bottom: 90,
            right: 0,
            padding: 15,
            background: 'white',
            justifyContent: 'flex-end',
            borderRadius: '5px',
            boxShadow: '0 3px 6px #00000050',
            zIndex: 1,
          }}
        >
          <Box
            style={{
              display: 'flex',
              gap: 10,
              justifyContent: 'flex-end',
            }}
          >
            <Button
              variant="outlined"
              className={classes.cancelButton}
              onClick={() => setIsDeletingMany((old) => !old)}
              startIcon={<Icon>cancel</Icon>}
            >
              Cancelar
            </Button>

            <Button
              variant="outlined"
              disabled={selectedCards.length === 0}
              className={classes.confirmButton}
              onClick={() => setOpenModalCancellation(!openModalCancellation)}
              startIcon={<Icon>check</Icon>}
            >
              Confirmar
            </Button>
          </Box>
        </Box>
      )}
      <Box mb={5}>
        <ScheduleInfo schedule={schedule} />
        {schedule &&
          schedule?.schedules.length > 0 &&
          schedule?.schedules
            .filter(
              (sched) =>
                schedule.agendas.some((day) =>
                  sched.some((schedDay) => day.schedule_id === schedDay.id)
                ) ||
                (sched.some((day) => day.quantity_professional > 0) &&
                  sched.some((day) => day.status === 1))
            )
            .map((sched, index) => (
              <div key={index} style={{ padding: 0, margin: 0 }}>
                <div>
                  <p className={classes.subtitle}>
                    {mountShedulesPeriod(sched[0])} -{' '}
                    {countRemainingVacancies(sched)} vagas restantes
                  </p>
                  {isDeletingMany && (
                    <Button
                      variant={'outlined'}
                      className={classes.selectAllButton}
                      style={{
                        marginTop: '15px',
                        maxWidth: '110px',
                        fontSize: 12,
                        background: checkIfAreAllSelected(sched)
                          ? '#0F83AD'
                          : '',
                        color: checkIfAreAllSelected(sched) ? '#ffffff' : '',
                      }}
                      onClick={() => {
                        if (checkIfAreAllSelected(sched)) {
                          handleUnselectAll(sched);
                        } else {
                          handleSelectAll(sched);
                        }
                      }}
                    >
                      {checkIfAreAllSelected(sched)
                        ? 'Desmarcar todos desse horário'
                        : 'Marcar todos desse horário'}
                    </Button>
                  )}
                </div>

                <TableContent>
                  <TableCards
                    isExpanded={isExpanded}
                    isDeletingMany={isDeletingMany}
                    selectedCards={selectedCards}
                    setSelectedCards={setSelectedCards}
                    permissions={permissions}
                    scaleData={schedule}
                    schedule={sched}
                  />
                </TableContent>
              </div>
            ))}
      </Box>
      <ModalCancelSchedule
        open={openModalCancellation}
        close={() => setOpenModalCancellation(!openModalCancellation)}
        title="Remover agendamentos"
        setResponseMessage={setResponseMessage}
        selectedIds={selectedCards}
        setOpenAlertMessage={setOpenAlertMessage}
        setStatusError={setStatusError}
        setResponseStatusError={setResponseStatusError}
        setResponseStatusAlert={setResponseStatusAlert}
      />
      <ModalResponseAlert
        openSendFinish={openAlertMessage}
        messageTitleAlert="Erro ao excluir algum(s) agendamento(s)"
        subtitle={responseMessage}
        handleCloseSendFinish={handleCloseSendFinish}
        alert={responseStatusAlert}
        error={responseStatusError}
      />
      <MessageNotification
        openNotification={statusError}
        closeNotification={() => setStatusError(false)}
        type={'error'}
        message={responseMessage}
        vertical={10}
        horizontal="40vw"
      />
    </div>
  );
};

export default memo(ScheduleTable);
