import { makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import styled from 'styled-components';

export const useStyles = makeStyles(() => ({
  birthdayBox: {
    background: '#FFFFFF',
    width: '100%',
    borderRadius: '5px',
    padding: '15px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    fontFamily: 'Poppins',
    border: '1px solid #EDEDED',
  },
  birthdayHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  birthdayContent: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
  },
  birthdayItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    padding: '5px 15px 5px 15px',
    borderRadius: '10px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    border: '1px solid #EDEDED',
  },
  loadingContainer: {
    textAlign: 'center',
    margin: 5,
  },
}));

export const StyledList = styled(List)`
  width: 100%;
  max-height: 57.5vh;
  overflow-y: auto;
  ::-webkit-scrollbar-track {
    background-color: #f4f4f4;
  }
  ::-webkit-scrollbar {
    width: 5px;
    background: #f4f4f4;
  }
  ::-webkit-scrollbar-thumb {
    background: #a2a5a8;
    border-radius: 10px;
  }
  .MuiListItemIcon-root {
    color: rgba(0, 0, 0, 0.54);
    display: inline-flex;
    min-width: 20px;
    flex-shrink: 0;
  }
`;
