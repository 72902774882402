import React from 'react';
import { Typography } from '@material-ui/core';
import { useStyles, StyledIndividualReportCard } from './styles';
import Tooltip from '@material-ui/core/Tooltip';

const IconReportCardSvg = ({ title, subtitle, icon, permission = true }) => {
  const classes = useStyles(permission);
  return (
    <Tooltip
      title={!permission ? 'Você não tem permissão' : ''}
      placement="top"
      arrow
    >
      <StyledIndividualReportCard className={classes.card}>
        <div className="flex items-center gap-5">
          <div>
            <img className={classes.img} src={icon}></img>
          </div>
          <div className="flex flex-col gap-2">
            <Typography className={classes.title}>{title}</Typography>
            <Typography className={classes.subTitle}>{subtitle}</Typography>
          </div>
        </div>
      </StyledIndividualReportCard>
    </Tooltip>
  );
};

export default IconReportCardSvg;
