import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { CircularProgress, Typography } from '@material-ui/core';
import useStyles from './style';
import { Link } from 'react-router-dom';

const validationSchema = yup.object({
  email: yup
    .string('Digite seu e-mail')
    .email('Digite um e-mail válido')
    .required('Digite seu e-mail'),
});

const RecoverForm = ({ handleSubmit, error, loading }) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          className={classes.text}
          fullWidth
          variant="outlined"
          id="email"
          name="email"
          label="Email"
          placeholder="Insira seu e-mail cadastrado"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <Link to="/recover-email" style={{ textDecoration: 'none' }}>
          <Typography className={classes.forgetEmail}>
            Esqueceu seu email ?
          </Typography>
        </Link>

        <Button
          id="Recover"
          className={classes.submit}
          variant="contained"
          fullWidth
          type="submit"
        >
          {loading ? (
            <CircularProgress style={{ color: 'white' }} />
          ) : (
            'Recuperar senha'
          )}
        </Button>

        {error && (
          <div className={classes.error}>
            <div>{error}</div>
          </div>
        )}

        <Link to="/login" style={{ textDecoration: 'none' }}>
          <Typography className={classes.forget_password}>Voltar</Typography>
        </Link>
      </form>
    </div>
  );
};

export default RecoverForm;
