import React, { useState } from 'react';
import Layout from 'shared/component/Layout';
import MessageNotification from 'shared/component/messageNotification';
import { useHistory } from 'react-router-dom';
import { useSubsidiary } from 'hook/subsidiary';
import SubsidiaryForm from 'shared/forms/SubsidiaryForm';

function SubsidiaryCreatePage() {
  const history = useHistory();

  const { getCreate, loading } = useSubsidiary();

  const [status, setStatus] = useState(false);

  const formatRequest = (data) => ({
    ...data,
    company: undefined,
    company_id: data?.company?.id ?? undefined,
  });

  const formSubmit = ({ data, fileLogo }) => {
    getCreate({ data: formatRequest(data), fileLogo })
      .then(() => {
        setTimeout(() => {
          history.push(`/cadastros/filiais`);
        }, 500);
      })
      .catch(() => setStatus(500));
  };

  const getMessage = (status) => {
    return status === 200 || status === 201
      ? 'Editado com sucesso!'
      : 'Falha ao cadastrar!';
  };

  return (
    <Layout
      title="Filiais"
      isLoading={false}
      calendarWeek={false}
      showToday
      backArrow
    >
      <>
        <SubsidiaryForm formSubmit={formSubmit} loading={loading} />
        <div style={{ position: 'absolute' }}>
          <MessageNotification
            openNotification={status}
            closeNotification={() => setStatus(false)}
            type={(status === 200 || status === 201) && 'success'}
            message={getMessage(status)}
            vertical={10}
            horizontal="40vw"
          />
        </div>
      </>
    </Layout>
  );
}

export default SubsidiaryCreatePage;
