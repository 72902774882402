import React from 'react';
import { useStyles, FormContainer, InputContainer, InputItem } from './styles';
import { Typography } from '@material-ui/core';
import { FastField, Form, Formik } from 'formik';
import CustomTextField from 'shared/component/forms/CustomTextField';
import { InputMaskField } from 'shared/component/forms/CustomMaskField';
import AddressForm from 'shared/component/forms/AddressForm';
import { schema } from './schema';
import FooterButton from './components/FooterButton';

export default function EnterprisesForm({
  formInitialValues,
  isEdit = false,
  loading = false,
  status,
  formSubmit,
}) {
  const classes = useStyles();

  return (
    <>
      <div style={{ marginTop: 30 }}>
        <Typography className={classes.registerTitle}>
          {isEdit ? 'Editar empresa' : 'Cadastrar nova empresa'}
        </Typography>
      </div>
      <Formik
        onSubmit={formSubmit}
        validationSchema={schema}
        initialValues={formInitialValues}
        validateOnBlur
        enableReinitialize
        values
      >
        {({ setFieldValue, touched, errors, values }) => (
          <Form>
            <Typography className={classes.dataHospital}>
              Dados da empresa
            </Typography>
            <InputContainer>
              <InputItem style={{ flexGrow: 2.05 }}>
                <FastField
                  value={values?.name}
                  label="Nome da empresa"
                  style={{ width: '100%' }}
                  name="name"
                  variant="outlined"
                  component={CustomTextField}
                />
              </InputItem>
              <InputItem data-cy="input_cnpj">
                <FastField
                  name="cnpj"
                  label="CNPJ"
                  style={{ width: '100%' }}
                  format="99.999.999/9999-99"
                  variant="outlined"
                  value={values?.cnpj}
                  errors={errors && errors.cnpj}
                  touched={touched}
                  component={InputMaskField}
                  onChange={(e) => {
                    setFieldValue('cnpj', e.target.value);
                  }}
                />
              </InputItem>
            </InputContainer>
            <Typography className={classes.address}>Endereço</Typography>
            <AddressForm values={values} setFieldValue={setFieldValue} />

            <FormContainer style={{ marginTop: 30, height: 128 }}>
              <div style={{ height: 128, width: '100%' }}>
                <Typography className={classes.title_geolocation}>
                  Observações
                </Typography>
                <FastField
                  data-cy="input_description"
                  value={values?.description}
                  style={{ width: '100%' }}
                  name="description"
                  variant="outlined"
                  multiline
                  rows={2}
                  component={CustomTextField}
                />
              </div>
            </FormContainer>
            <div style={{ marginRight: 15 }}>
              <FooterButton
                id={values?.id}
                type="submit"
                status={status}
                loading={loading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
