import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { CircularProgress, Typography } from '@material-ui/core';
import useStyles from './style';
import { Link } from 'react-router-dom';
import validators from 'utils/validators';

const validationSchema = Yup.object({
  cpf: Yup.string()
    .min(11, 'CPF incompleto')
    .test(...validators.cpfInvalid('CPF inválido'))
    .required('Obrigatório'),
});
const RecoverForm = ({ handleSubmit, error, loading }) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      cpf: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          className={classes.text}
          fullWidth
          variant="outlined"
          id="cpf"
          name="cpf"
          label="CPF"
          placeholder="Insira seu CPF cadastrado"
          value={formik.values.cpf}
          onChange={formik.handleChange}
          error={formik.touched.cpf && Boolean(formik.errors.cpf)}
          helperText={formik.touched.cpf && formik.errors.cpf}
        />

        <Button
          className={classes.submit}
          variant="contained"
          fullWidth
          type="submit"
        >
          {loading ? (
            <CircularProgress style={{ color: 'white' }} />
          ) : (
            'Mostrar meu email'
          )}
        </Button>

        {error && (
          <div className={classes.error}>
            <div>{error}</div>
          </div>
        )}

        <Link to="/recover" style={{ textDecoration: 'none' }}>
          <Typography className={classes.forget_password}>Voltar</Typography>
        </Link>
      </form>
    </div>
  );
};

export default RecoverForm;
