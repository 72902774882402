import React, { memo } from 'react';
import { useStyles } from './styles';
import CardCellSchedule from '../CardCellSchedule';
import CardEmptyDay from '../CardEmptyDay';

const TableCards = ({
  isExpanded,
  isDeletingMany,
  selectedCards,
  setSelectedCards,
  scaleData,
  schedule,
  permissions,
}) => {
  const classes = useStyles();
  const SwitchCell = ({ item, index }) => {
    if (
      item?.status === 1 ||
      scaleData?.agendas.some((day) => day.schedule_id === item.id)
    ) {
      return (
        <CardCellSchedule
          isExpanded={isExpanded}
          isDeletingMany={isDeletingMany}
          selectedCards={selectedCards}
          setSelectedCards={setSelectedCards}
          permissions={permissions}
          scaleData={scaleData}
          schedule={schedule}
          agenda={item?.agenda}
          item={item}
          index={index}
        />
      );
    } else return <CardEmptyDay />;
  };

  return (
    <div className={classes.container_days}>
      {schedule.map((item, index) => (
        <SwitchCell key={index} index={index} item={item} />
      ))}
    </div>
  );
};

export default memo(TableCards);
