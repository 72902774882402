import React, { useEffect, memo } from 'react';
import { useStyles, InputContainer, InputItem } from './styles';
import { Typography } from '@material-ui/core';
import { FastField, Field } from 'formik';
import {
  RadioInput,
  RadioInputGroup,
} from 'shared/component/maskedField/checkBox';
import CustomTextField from 'shared/component/forms/CustomTextField';
import CustomSelectEnterprises from 'shared/component/forms/CustomSelectEnterprises';
import CustomSelectCompanies from 'shared/component/forms/CustomSelectCompanies';
import CustomSelectSpecialties from 'shared/component/forms/CustomSelectSpecialties';
import CustomSelectUnits from 'shared/component/forms/CustomSelectUnits';
import CustomSelectStates from 'shared/component/forms/CustomSelectStates';
import CustomSelectGroups from 'shared/component/forms/CustomSelectGroups';
import CustomSelectRegulationAgencies from 'shared/component/forms/CustomSelectRegulationAgencies';
import CustomSelectSubsidiaries from 'shared/component/forms/CustomSelectSubsidiaries';

function SecondStepForm({
  values,
  errors,
  touched,
  isEdit,
  setFieldValue = () => {},
  setFieldTouched = () => {},
}) {
  const classes = useStyles();

  const [selectedState, setSelectedState] = React.useState('');
  const [groupId, setGroupId] = React.useState(null);

  const getFormatedLabel = (item) => {
    return {
      value: item.id,
      label: item.name ?? item.id,
    };
  };

  const getInitialState = (state) => {
    const initialState = {
      value: state.id,
      label: state.name,
    };
    setFieldValue('crm.state', initialState.value);
    setSelectedState(initialState);
  };

  useEffect(() => {
    isEdit && values?.crm && values?.crm?.state
      ? getInitialState(values.crm.state)
      : setFieldValue('crm.state', '');
  }, []);

  const getInitialProfessionalType = () => {
    if (
      (isEdit && values.group && values.group.id === 3) ||
      values.group.id === ''
    ) {
      setFieldValue('professional_type', 'doctor');
      setFieldValue('crm.professional_type', 'doctor');
    } else {
      setFieldValue('professional_type', 'multiprofissional');
      setFieldValue('crm.professional_type', 'multiprofissional');
    }
  };

  const getInitialSpecialties = (specialties) => {
    return specialties.map((item) => getFormatedLabel(item));
  };

  const getInitialUnits = (units) => {
    return units.map((item) => getFormatedLabel(item));
  };

  useEffect(getInitialProfessionalType, []);

  useEffect(() => {
    if (values?.professional_type === 'doctor') {
      setGroupId(3);
    }
  }, [values?.professional_type]);

  useEffect(() => {
    if (values?.group?.id) {
      setGroupId(values?.group?.id);
    }
  }, [values?.group?.id]);

  return (
    <>
      <Typography className={classes.textHeader}>
        Dados Profissionais
      </Typography>

      <InputContainer>
        <InputItem id="crm">
          <RadioInputGroup
            row
            name="crm.professional_type"
            handleChange={(value) => {
              setFieldValue('crm.professional_type', value.target.value);
              setFieldValue('professional_type', value.target.value);
              setFieldValue('regulation_agency.id', '');
              setFieldValue('crm.number', '');
              setFieldValue('crm.state', '');
              if (value.target.value === 'multiprofissional') {
                setFieldValue('group.id', '');
                setFieldValue('group.name', '');
                setGroupId(null);
                setFieldValue('hasRegulationAgency', false);
              } else {
                setFieldValue('hasRegulationAgency', true);
              }
            }}
            value={values?.professional_type || 'doctor'}
          >
            <RadioInput value="doctor" label="Médico" />
            <RadioInput value="multiprofissional" label="Multiprofissional" />
          </RadioInputGroup>
        </InputItem>
      </InputContainer>

      {values?.professional_type !== 'doctor' && (
        <InputContainer data-cy="inputContainer3_informacoes_profissionais">
          <InputItem>
            <Field
              name="group"
              style={{ width: '100%' }}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              placeholder={'Selecione o grupo'}
              errors={errors && errors.group}
              value={
                values && values.group && values.group.id !== ''
                  ? getFormatedLabel(values.group)
                  : null
              }
              handleChange={(val) => {
                setFieldValue('regulation_agency.id', '');
                setFieldValue('crm.number', '');
                setFieldValue('crm.state', '');
                if (val === null) {
                  setFieldValue('group.id', null);
                  setFieldValue('group.name', null);
                  setGroupId(null);
                } else {
                  setFieldValue('group.id', val.value);
                  setFieldValue('group.name', val.label);
                  setGroupId(val.value);
                }
              }}
              label={'Grupo'}
              menuList={() => ({
                height: 116,
                overflowY: 'auto',
              })}
              variant="outlined"
              component={CustomSelectGroups}
            />
          </InputItem>
        </InputContainer>
      )}

      <InputContainer data-cy="inputContainer1_informacoes_profissionais">
        <InputItem>
          <Field
            name="regulation_agency.id"
            style={{ width: '100%' }}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            placeholder={'Selecione o conselho regulador'}
            errors={errors && errors.regulation_agency?.id}
            touched={touched}
            value={
              values &&
              values.regulation_agency &&
              values.regulation_agency?.id !== ''
                ? getFormatedLabel(values.regulation_agency)
                : null
            }
            handleChange={(val) => {
              if (val === null) {
                setFieldValue('regulation_agency.id', '');
              } else {
                setFieldValue('regulation_agency.id', val.label);
              }
            }}
            label={'Conselho regulador'}
            menuList={() => ({
              height: 116,
              overflowY: 'auto',
            })}
            variant="outlined"
            groupId={groupId}
            display={values?.hasRegulationAgency ? 'inline' : 'none'}
            component={CustomSelectRegulationAgencies}
          />
        </InputItem>

        <InputItem>
          <Field
            name="crm.number"
            value={values?.crm?.number}
            errors={errors}
            label="Numero do conselho regulador"
            touched={touched}
            style={{
              width: '100%',
            }}
            display={values?.hasRegulationAgency ? 'inline' : 'none'}
            variant="outlined"
            component={CustomTextField}
          />
        </InputItem>

        <InputItem>
          <Field
            name="crm.state"
            placeholder={'Unidade Federativa'}
            value={selectedState}
            handleChange={(val) => {
              if (val?.value) {
                setSelectedState(val);
                setFieldValue('crm.state', val.value);
              } else {
                setSelectedState(null);
                setFieldValue('crm.state', '');
              }
            }}
            display={values?.hasRegulationAgency ? 'inline' : 'none'}
            menuList={() => ({ height: 116, overflowY: 'auto' })}
            errors={errors && errors?.crm && errors?.crm?.state}
            component={CustomSelectStates}
          />
        </InputItem>
      </InputContainer>

      {(values?.professional_type === 'doctor' ||
        values?.company?.id === 3) && (
        <InputContainer>
          {values?.professional_type === 'doctor' && (
            <>
              <InputItem style={{ flexGrow: 0.5 }}>
                <Typography className={classes.text}>Recebimento</Typography>
                <InputContainer style={{ marginTop: 0 }}>
                  <InputItem>
                    <RadioInputGroup
                      name="profile_receiving"
                      row
                      handleChange={(value) => {
                        setFieldValue(
                          'profile_receiving',
                          parseInt(value.target.value)
                        );
                      }}
                      value={values?.profile_receiving || 1}
                    >
                      <RadioInput value={1} label="Nota Fiscal" />
                      <RadioInput value={2} label="Sócio" />
                    </RadioInputGroup>
                  </InputItem>
                </InputContainer>
              </InputItem>

              {values?.profile_receiving === 2 && (
                <InputItem style={{ flexGrow: 0.5 }}>
                  <Typography className={classes.text}>Sócio</Typography>
                  <InputContainer style={{ marginTop: 0 }}>
                    <InputItem>
                      <RadioInputGroup
                        name="profile_receiving_type"
                        row
                        handleChange={(value) => {
                          setFieldValue(
                            'profile_receiving_type',
                            parseInt(value.target.value)
                          );
                        }}
                        value={values?.profile_receiving_type || 1}
                      >
                        <RadioInput value={1} label="PJ" />
                        <RadioInput value={2} label="PF" />
                      </RadioInputGroup>
                    </InputItem>
                  </InputContainer>
                </InputItem>
              )}
            </>
          )}

          {values?.company?.id === 3 && (
            <InputItem style={{ flexGrow: 0.5, alignSelf: 'center' }}>
              <FastField
                data-cy="creator_relationship"
                label="Vínculo"
                style={{ width: '100%' }}
                name="creator_relationship"
                variant="outlined"
                component={CustomTextField}
                errors={errors}
              />
            </InputItem>
          )}
        </InputContainer>
      )}

      <InputContainer data-cy="inputContainer2_informacoes_profissionais">
        <InputItem>
          <Field
            name="enterprise.id"
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            errors={errors && errors.enterprise && errors.enterprise.id}
            style={{ width: '100%' }}
            placeholder="Empresa"
            label="Empresa"
            value={
              values &&
              values.enterprise &&
              !!values.enterprise?.id &&
              values.enterprise?.id !== ''
                ? getFormatedLabel(values.enterprise)
                : null
            }
            handleChange={(e) => {
              setTimeout(() => {
                if (e === null) {
                  setFieldValue('enterprise.id', null);
                  setFieldValue('enterprise.name', null);
                } else {
                  setFieldValue('enterprise.id', e.value);
                  setFieldValue('enterprise.name', e.label);
                }
              }, 100);
            }}
            menuList={() => ({ height: 116, overflowY: 'auto' })}
            variant="outlined"
            component={CustomSelectEnterprises}
          />
        </InputItem>
        <InputItem>
          <Field
            name="company.id"
            style={{ width: '100%' }}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            placeholder={'Companhia'}
            errors={errors && errors.company && errors.company.id}
            label={'Companhia'}
            value={
              values && values.company && values.company.id !== ''
                ? getFormatedLabel(values.company)
                : null
            }
            handleChange={(e) => {
              if (e === null) {
                setFieldValue('company.id', null);
                setFieldValue('company.name', null);
              } else {
                setFieldValue('company.id', e.value);
                setFieldValue('company.name', e.label);
              }
            }}
            menuList={() => ({ height: 116, overflowY: 'auto' })}
            variant="outlined"
            component={CustomSelectCompanies}
          />
        </InputItem>
        <InputItem>
          <Field
            name="subsidiary.id"
            style={{ width: '100%' }}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            placeholder={'Filial'}
            errors={errors && errors.subsidiary && errors.subsidiary.id}
            label={'Filial'}
            value={
              values && values.subsidiary && values.subsidiary.id !== ''
                ? getFormatedLabel(values.subsidiary)
                : null
            }
            handleChange={(e) => {
              if (e === null) {
                setFieldValue('subsidiary.id', null);
                setFieldValue('subsidiary.name', null);
              } else {
                setFieldValue('subsidiary.id', e.value);
                setFieldValue('subsidiary.name', e.label);
              }
            }}
            menuList={() => ({ height: 116, overflowY: 'auto' })}
            variant="outlined"
            component={CustomSelectSubsidiaries}
          />
        </InputItem>
      </InputContainer>

      {values?.professional_type === 'doctor' && (
        <InputContainer data-cy="inputContainer3_informacoes_profissionais">
          <InputItem>
            <Field
              name="specialties"
              style={{ width: '100%' }}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              placeholder={'Especialidades'}
              errors={errors && errors.specialties}
              value={
                values && values?.specialties && values?.specialties.length > 0
                  ? getInitialSpecialties(values.specialties)
                  : null
              }
              handleChange={(data) => {
                setFieldValue(
                  'specialties',
                  data.map((item) => ({ id: item.value, name: item.label }))
                );
              }}
              label={'Especialidades'}
              menuList={() => ({ height: 116, overflowY: 'auto' })}
              variant="outlined"
              component={CustomSelectSpecialties}
              isMulti
            />
          </InputItem>
        </InputContainer>
      )}

      <InputContainer data-cy="inputContainer4_informacoes_profissionais">
        <InputItem>
          <Field
            name="units"
            style={{ width: '100%' }}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            placeholder={'Hospitais permitidos'}
            label={'Hospitais permitidos'}
            errors={errors && errors.units}
            value={
              values && values?.units && values?.units.length > 0
                ? getInitialUnits(values.units)
                : null
            }
            handleChange={(data) => {
              setFieldValue(
                'units',
                data.map((item) => ({ id: item.value, name: item.label }))
              );
            }}
            menuList={() => ({ height: 116, overflowY: 'auto' })}
            variant="outlined"
            component={CustomSelectUnits}
            isMulti
          />
        </InputItem>
      </InputContainer>
    </>
  );
}

export default memo(SecondStepForm);
