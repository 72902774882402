// import { useState } from 'react';
import api from 'service/api';
import { useAuth } from 'hook/auth';

export const useExportPdf = () => {
  const { requestIntercept } = useAuth();

  // const [data, setData] = useState([]);
  // const [total, setTotal] = useState(0);
  // const [loading, setLoading] = useState(false);

  const getExportPdfList = async (params) => {
    try {
      // setLoading(true);
      const { company, type, group, employ, unit } = params;

      const unitData = [
        {
          unit_id: unit.value,
          unit_name: unit.label,
        },
      ];

      // Agrupado Médico
      if (group === 'unit' && employ === 'employ' && type === '2') {
        const response = await requestIntercept(
          api.post,
          `/v1/reports/unit/doctor`,
          unitData,
          {},
          true
        );
        if (response.status !== 200) {
          throw response;
        }
        return response;
      }

      // Agrupado Profissional
      if (group === 'unit' && employ === 'employ' && type === '3') {
        const response = await requestIntercept(
          api.post,
          `/v1/reports/unit/employ`,
          unitData,
          {},
          true
        );
        if (response.status !== 200) {
          throw response;
        }
        return response;
      } else {
        const response = await requestIntercept(
          api.post,
          `/v1/reports/company/${company.value}/professional?type=${type}`,
          type === 'general' ? [] : unitData,
          {},
          true
        );
        if (response.status !== 200) {
          throw response;
        }
        return response;
      }
      // setData(response.data.data);
      // setTotal(response.data.pagination.total_items);
      // setLoading(false);
      // return response;
    } catch (error) {
      // setLoading(false);
      return error;
    }
  };

  const getExportPdfListCompany = async (params) => {
    try {
      // setLoading(true);
      const { company, type } = params;
      const response = await requestIntercept(
        api.post,
        `/v1/reports/company/${company.value}/professional?type=${type}`,
        [],
        {},
        true
      );
      if (response.status !== 200) {
        throw response;
      }
      // setData(response.data.data);
      // setTotal(response.data.pagination.total_items);
      // setLoading(false);
      return response;
    } catch (error) {
      // setLoading(false);
      return error;
    }
  };

  return {
    // data,
    // total,
    // loading,
    getExportPdfList,
    getExportPdfListCompany,
  };
};
