import React, { useState } from 'react';
import { Stepper } from 'shared/component/Stepper';
import { Button, useToast } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import PersonalDataStep from './steps/personal-data';
import { useProfessional } from 'hook/professional';
import { colors } from 'theme/chakraTheme';
import {
  completeSchema,
  firstStepSchema,
  secondStepSchema,
  thirdStepSchema,
} from './schema';
import ProfessionalDataStep from './steps/professional-data';
import { useAuth } from 'hook/auth';
import { useHistory } from 'react-router-dom';
import BankDataStep from './steps/bank-data';

const ProfessionalFormSteps = {
  PersonalData: 'personal-data',
  ProfessionalData: 'professional-data',
  BankData: 'bank-data',
};

export function NewProfessionalForm() {
  const toast = useToast();
  const history = useHistory();
  const { userLogged } = useAuth();
  const { loading, getCreate } = useProfessional();
  const [step, setStep] = useState(ProfessionalFormSteps.PersonalData);

  const formMethods = useForm({
    defaultValues: {
      last_name: '',
      professional_type: 'doctor',
      company: userLogged.group.id !== 1 ? userLogged.company : undefined,
      profile_receiving: 1,
      profile_receiving_type: 1,
      has_regulation_agency: true,
      group: {
        id: 3,
      },
      bank: undefined,
    },
    resolver: yupResolver(completeSchema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const handleNextStepOrSubmit = async () => {
    formMethods.clearErrors();

    try {
      const values = formMethods.getValues();

      if (step === ProfessionalFormSteps.PersonalData) {
        await firstStepSchema.validate(values, {
          abortEarly: false,
        });
        setStep(ProfessionalFormSteps.ProfessionalData);
        scrollToTop();
        return;
      }
      if (step === ProfessionalFormSteps.ProfessionalData) {
        await secondStepSchema.validate(values, {
          abortEarly: false,
        });
        setStep(ProfessionalFormSteps.BankData);
        scrollToTop();
        return;
      }

      await thirdStepSchema.validate(values, {
        abortEarly: false,
      });
      await getCreate({
        data: {
          ...values,
          image: undefined,
          professional_type: undefined,
          has_regulation_agency: undefined,
          subsidiary: undefined,
          civil_status: values.civil_status.value,
          genre: values.genre.value,
          subsidiary_id: values.subsidiary?.id,
          regulation_agency: values.regulation_agency?.name,
          profile_receiving:
            values.professional_type === 'doctor'
              ? values.profile_receiving
              : undefined,
          profile_receiving_type:
            values.professional_type === 'doctor'
              ? values.profile_receiving_type
              : undefined,
          bank: !values.bank?.bank?.id
            ? { ...values.bank, bank: { id: '' } }
            : {
                ...values.bank,
                doc_type: values.bank.doc_type?.value,
                type: values.bank.type?.value,
              },
        },
        docs: undefined,
        fileLogo: values.image?.[0],
      });
      toast({
        position: 'top-end',
        title: 'Cadastro efetuado com sucesso',
        status: 'success',
        duration: 5000,
      });
      history.push(`/cadastros/profissionais`);
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        for (const e of error.inner) {
          formMethods.setError(e.path, {
            type: 'manual',
            message: e.message,
          });
        }
      } else {
        const err = error;
        toast({
          position: 'top-end',
          title: 'Erro ao cadastrar',
          description: err?.data?.msg,
          status: 'error',
          duration: 5000,
        });
      }
    }
  };

  return (
    <div className="flex flex-1 flex-col max-w-[80%] mx-auto mt-2 container gap-10 pb-28">
      <FormProvider {...formMethods}>
        <div className="flex flex-1 flex-col bg-white p-5 rounded-lg shadow-lg">
          <div className="px-[20%]">
            <Stepper
              position={
                step === ProfessionalFormSteps.PersonalData
                  ? 0
                  : step === ProfessionalFormSteps.ProfessionalData
                  ? 1
                  : 2
              }
              stepsLength={3}
            />
          </div>

          {step === ProfessionalFormSteps.PersonalData && <PersonalDataStep />}
          {step === ProfessionalFormSteps.ProfessionalData && (
            <ProfessionalDataStep />
          )}
          {step === ProfessionalFormSteps.BankData && <BankDataStep />}
        </div>

        <div className="flex gap-5">
          {(step === ProfessionalFormSteps.ProfessionalData ||
            step === ProfessionalFormSteps.BankData) && (
            <Button
              isDisabled={loading}
              onClick={() => {
                setStep(
                  step === ProfessionalFormSteps.BankData
                    ? ProfessionalFormSteps.ProfessionalData
                    : ProfessionalFormSteps.PersonalData
                );
                scrollToTop();
              }}
              bgColor="white"
              borderColor={colors.theme.light.primary}
              color={colors.theme.light.primary}
              w="full"
              variant="outline"
            >
              Voltar
            </Button>
          )}
          <Button
            onClick={handleNextStepOrSubmit}
            _hover={{ opacity: 0.7 }}
            color="white"
            bgColor={colors.theme.light.primary}
            w="full"
            isLoading={loading}
            isDisabled={loading}
          >
            {step === ProfessionalFormSteps.BankData
              ? 'Criar profissional'
              : 'Próximo passo'}
          </Button>
        </div>
      </FormProvider>
    </div>
  );
}
