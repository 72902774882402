import React from 'react';
import { TableCell } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

const StatusChip = ({ rowData = {}, maxWidth = 170 }) => {
  function getStatus() {
    if (rowData.deleted_at !== null) return ['#F44336', '#FFF', 'Deletado'];
    if (rowData.status === 1) return ['#66BB6A', '#FFF', 'Ativo'];
    if (rowData.status === 0) return ['#ffcc00', '#000', 'Desativado'];
    if (rowData.status === 2) return ['#29B6F6', '#FFF', 'Em moderação'];
  }

  const [backgroundColor, color, label] = getStatus();

  return (
    <TableCell style={{ maxWidth }}>
      <Chip label={label} style={{ backgroundColor, color }} />
    </TableCell>
  );
};

export default StatusChip;
