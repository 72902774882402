import React, { useEffect, useState } from 'react';
import { useStyles, TableControl, InputContainer, InputSelect } from './styles';
import { TableCell } from '@material-ui/core';
import Layout from 'shared/component/Layout';
import DefaultTable from 'shared/component/DefaultTable';
import TabPanel from 'shared/component/tabPanel';
import SearchInputDebounce from 'shared/component/forms/SearchInputDebounce';
import { AvatarNameComponent } from 'shared/component/AvatarNameComponent';
import CustomSelect from 'shared/component/forms/CustomSelect';
import '../../shared/fixedtab.css';
import moment from 'moment';
import StatusChip from 'shared/component/StatusChip';
import useReport from 'hook/report';

export default function UsersWithoutAgenda() {
  const classes = useStyles();

  const permissions = {
    read: 'report/r',
  };

  const columns = () => {
    return [
      {
        id: 'name',
        label: 'Nome',
        minWidth: 100,
        render: (rowData, index) => (
          <AvatarNameComponent key={index} rowData={rowData} />
        ),
      },
      { id: 'cpf', label: 'CPF', minWidth: 100 },
      { id: 'group.name', label: 'Campo de atuação', minWidth: 100 },
      {
        id: 'company.name',
        label: 'Companhia',
        minWidth: 100,
      },
      {
        id: 'unit',
        label: 'Hospital',
        minWidth: 100,
        render: (rowData, index) => (
          <SpecialtiesNames key={index} specialties={rowData?.units} />
        ),
      },
      {
        id: 'created_at',
        label: 'Data de criação',
        minWidth: 10,
        render: (rowData) => (
          <TableCell>
            {moment(rowData.created_at).format('DD/MM/yyyy')}
          </TableCell>
        ),
      },
      {
        id: 'status',
        label: 'Status',
        minWidth: 10,
        render: (rowData, index) => (
          <StatusChip key={index} rowData={rowData} />
        ),
      },
    ];
  };

  const SpecialtiesNames = ({ specialties }) => {
    if (!specialties || !(specialties.length > 0))
      return <TableCell>...</TableCell>;
    const concatedNames = specialties.map((item) => item?.name).join(' , ');
    return <TableCell>{concatedNames}</TableCell>;
  };

  const {
    dataProfessional,
    loading: loadingProfessionals,
    totalProfessional,
    getProfessionalsWithoutAgenda,
  } = useReport();

  const [filterProfessionals, setFilterProfessionals] = useState({
    page: 1,
    search: '',
    per_page: 10,
    status: 'all',
  });

  const [profissionalStatus, setProfissionalStatus] = useState({
    label: 'Todos',
    value: 'all',
  });

  const handleChangeProfessionalStatus = (val) => {
    setProfissionalStatus(val);
    setFilterProfessionals((oldState) => ({
      ...oldState,
      status: val?.value,
      page: 1,
    }));
  };

  React.useEffect(() => {
    getProfessionalsWithoutAgenda(filterProfessionals);
  }, [filterProfessionals]);

  const handleChangePage = (page) => {
    setFilterProfessionals({ ...filterProfessionals, page: page });
  };

  const handleSearch = (search) => {
    typeof search === 'string'
      ? setFilterProfessionals((old) => ({ ...old, page: 1, search }))
      : false;
  };

  const typeProfessionalStatus = [
    { label: 'Todos', value: 'all' },
    { label: 'Ativos', value: 'active' },
    { label: 'Inativos', value: 'deactive' },
    { label: 'Em moderação', value: 'moderating' },
    { label: 'Deletados', value: 'deleted' },
  ];

  useEffect(() => {
    if (dataProfessional?.length < 1) {
      setFilterProfessionals({
        ...filterProfessionals,
        page: 1,
      });
    }
  }, []);

  return (
    <Layout title="Profissionais menos produtivos" showToday backArrow>
      <div style={{ margin: '0 0px 0 30px', backgroundColor: '#EDEDED' }}>
        <div
          data-cy="div_professional_filters"
          className={classes.inputContainer}
        >
          <InputContainer>
            <SearchInputDebounce
              value={filterProfessionals.search}
              className={classes.inputWidth}
              placeholder="Busque por nome ou CPF"
              onChange={handleSearch}
              style={{
                flex: 1,
                alignItems: 'center',
                height: 48,
                width: '100%',
              }}
            />
          </InputContainer>

          <InputSelect>
            <CustomSelect
              isProf={false}
              name="status"
              loading={false}
              placeholder="Selecione o status do profissional"
              handleChange={handleChangeProfessionalStatus}
              className={classes.withoutBorder}
              value={profissionalStatus}
              options={typeProfessionalStatus}
            />
          </InputSelect>
        </div>

        <div className={classes.root}>
          <TabPanel>
            <TableControl data-cy="dataTable">
              <DefaultTable
                permissions={permissions}
                dataList={dataProfessional}
                pageByProps={filterProfessionals.page}
                columns={columns()}
                loading={loadingProfessionals}
                totalList={totalProfessional}
                perPage={filterProfessionals?.perPage}
                handleChangePage={handleChangePage}
                showExportButton={false}
                showActions={false}
                useTimeOut={false}
              />
            </TableControl>
          </TabPanel>
        </div>
      </div>
    </Layout>
  );
}
