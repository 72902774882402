import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import styles from './style';
import { ContainerLogin } from './style';
import LoginCarousel from './component/carousel';
import LoginForm from './component/form';
import { useAuth } from 'hook/auth';
import { useHistory } from 'react-router-dom';
const logoTemporary = require('asset/image/logo_temporary.png');

export default function LoginPage() {
  const classes = styles();
  const history = useHistory();
  const { loading, error, isAuth, login } = useAuth();

  React.useEffect(() => {
    if (isAuth) {
      history.replace('/inicio');
    }
  }, [isAuth]);

  return (
    <Grid container component="main" className="h-[100vh]">
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        className="bg-primary bg-onboard_bg_img bg-no-repeat bg-cover bg-center"
      >
        <LoginCarousel />
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <ContainerLogin>
            <img alt="" src={logoTemporary} className="mb-11 h-20 w-[100%]" />
            <LoginForm handleSubmit={login} error={error} loading={loading} />
          </ContainerLogin>
        </div>
      </Grid>
    </Grid>
  );
}
