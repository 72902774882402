import React, { useState } from 'react';
import Layout from 'shared/component/Layout';
import { useAdministrators } from 'hook/administrators';
import AdminForm from 'shared/forms/AdminForm';

function AdminPageCreate() {
  const { getCreate, loading, status } = useAdministrators();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);

  const formatRequest = (data) => ({
    name: data?.name,
    email: data?.email,
    cpf: data?.cpf,
    cell_phone: data?.cell_phone,
    company: { id: data?.company.id },
    group: { id: data?.group.id },
    units: data?.units,
    check_email_admin: true,
  });

  const formSubmit = ({ data, fileLogo }) => {
    getCreate({
      data: formatRequest(data),
      fileLogo,
      permissions: data?.permissions.map((item) => ({ id: item?.id })),
    })
      .then(() => {
        setMessage('Administrador criado com sucesso!');
        setError(false);
      })
      .catch((response) => {
        if (
          response.status === 400 &&
          response.data.msg.indexOf(
            'There is already a registered user with that email'
          ) !== -1
        )
          setMessage('Erro ao criar: já existe um usuário com este email.');
        else setMessage('Erro ao tentar criar o administrador.');
        setError(true);
      });
  };

  return (
    <Layout
      title="Administradores"
      isLoading={false}
      calendarWeek={false}
      showToday
      backArrow
    >
      <>
        <AdminForm
          formSubmit={formSubmit}
          loading={loading}
          status={status}
          error={error}
          setError={setError}
          message={message}
        />
      </>
    </Layout>
  );
}

export default AdminPageCreate;
