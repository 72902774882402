import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { useStyles, TableLegendItem, ErrorMessage } from './styles';
import ScheduleForm from './ScheduleFormCreate';
import CalendarFormCreate from './CalendarFormCreate';
import { ScheduleWeekPageContext } from 'page/Schedule/WeekSchedule/index';
import { useAgendaStatus } from 'hook/agenda/status';
import moment from 'moment';
import { useAgendaDetails } from 'hook/agenda/details';
import { useAgenda } from 'hook/agenda';
import { ModalCardDaysDetails } from '../../ModalCardDaysDetails';
import { ModalResponseAlertSchedule } from './ScheduleFormCreate/ModalResponseAlertSchedule';

function GridModalCalendarCombo() {
  const classes = useStyles();
  const { loading: loadingAgenda, createAgenda, data } = useAgenda();
  const {
    data: agendaDetails,
    loading: loadingAgendaDetails,
    getAgendaDetails,
  } = useAgendaDetails();
  const { data: agendaStatus, getAgendaStatus } = useAgendaStatus();
  const {
    selectedUnit,
    detailsSchedule = {},
    mainFilter = {},
    handleGetAgenda = () => {},
    handleCloseModalScheduleCall = () => {},
  } = useContext(ScheduleWeekPageContext);

  const [openModalDayDetails, setModalDayDetails] = useState(false);

  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [responseMessage, setResponseMessage] = useState();
  const [responseStatusError, setResponseStatusError] = useState(false);

  const [selectedDays, setSelectedDays] = useState([]);
  const [blockedDays, setBlockedDays] = useState();
  const [disabledDays, setDisabledDays] = useState([]);
  const [repeatType, setRepeatType] = useState(null);
  const [errorDate, setErrorDate] = useState(false);
  const [yearMonth, setYearMonth] = useState(null);
  const [alreadyScaled, setAlreadyScaled] = useState({
    ALREADY_SCALED_THIS_HOSPITAL: false,
    ALREADY_SCALED_OTHER_HISPITAL: false,
    SAME_HOSPITAL_OTHER_SCALE: false,
    SAME_HOSPITAL_OTHER_SCALE_CREATED: false,
  });

  useEffect(() => {
    if (detailsSchedule?.daySelected)
      setSelectedDays([detailsSchedule?.daySelected]);
  }, [detailsSchedule]);

  useEffect(() => {
    const { week_day, blocked_days } = agendaStatus;
    if (!!blocked_days || (blocked_days && blocked_days.length > 0))
      handleMarkBlockedDays(blocked_days);
    if (!!week_day && week_day.length > 0) handleDisableWeekDays(week_day);
  }, [agendaStatus]);

  const handleMarkBlockedDays = (blocked_days) => {
    const result = blocked_days.map((item) =>
      moment(item.day_start, 'yyyy-MM-DD').toDate()
    );
    setBlockedDays(result);
  };

  const handleRemoveBlockedDays = () => {
    if (!!blockedDays && blockedDays.length > 0)
      setSelectedDays((old) => [...old.filter((item) => !isDayBloqued(item))]);
  };

  const handleRemoveBlockedWeekDays = () => {
    if (!!disabledDays && disabledDays.length > 0)
      setSelectedDays((old) => [
        ...old.filter((item) => {
          const weekDay = moment(item).day();
          return !disabledDays.includes(weekDay);
        }),
      ]);
  };

  useEffect(handleRemoveBlockedDays, [blockedDays]);
  useEffect(handleRemoveBlockedWeekDays, [disabledDays]);

  const isDayBloqued = (day) => {
    return !!blockedDays.find(
      (element) =>
        moment(element, 'YYYY-MM-DD').format('YYYY-MM-DD') ===
        moment(day).format('YYYY-MM-DD')
    );
  };

  const handleDisableWeekDays = (weekDays) => {
    const result = weekDays
      .filter((item) => item.status === 0)
      .map((item) => (item.day < 6 ? item.day + 1 : 0));
    setDisabledDays(result);
  };

  const handleCheckAgendaStatus = useCallback(
    ({ schedule, professional }) => {
      const payload = {
        hour_start: schedule?.hour_start,
        hour_end: schedule?.hour_end,
        user_id: professional?.value,
        year: yearMonth?.year || new Date().getFullYear(),
        month: yearMonth?.month || new Date().getMonth() + 1,
        date_start:
          selectedDays && selectedDays?.length > 0
            ? moment(selectedDays[0]).startOf('month').format('YYYY-MM-DD')
            : moment().startOf('month').format('YYYY-MM-DD'),
        date_end:
          selectedDays && selectedDays?.length > 0
            ? moment(selectedDays[0]).endOf('month').format('YYYY-MM-DD')
            : moment().startOf('month').format('YYYY-MM-DD'),
        scale_id: detailsSchedule?.scaleData.id,
      };

      getAgendaStatus(payload);
    },
    [selectedDays, yearMonth, detailsSchedule]
  );

  const handleCloseSendFinish = async () => {
    setOpenAlertMessage(false);
    if (!responseStatusError) {
      await handleGetAgenda();
    }
    handleCloseModalScheduleCall(false);
  };

  const onSubmit = (form) => {
    setErrorDate(!selectedDays.length > 0);
    if (selectedDays.length > 0) {
      const formData = {
        price: detailsSchedule?.selected_schedule?.price,
        hour_start: form?.schedule.hour_start,
        hour_end: form?.schedule.hour_end,
        subsequent: '',
        user: {
          id: form?.professional.id,
        },
        unit: {
          id: selectedUnit?.id,
        },
        scale: {
          id: detailsSchedule?.scaleData.id,
        },
        schedule: {
          id: detailsSchedule?.selected_schedule.id,
        },
        selectedDays: [
          ...selectedDays.map((item, index) => ({
            id: index,
            date_start: moment(item).format('YYYY-MM-DD'),
            date_end: moment(item).format('YYYY-MM-DD'),
          })),
        ],
        confirm: form?.confirm ? form.confirm : null,
        yearMonth,
      };

      createAgenda(formData)
        .then(async (response) => {
          if (response?.data?.data?.fails?.length > 0) {
            if (response?.data?.data?.success?.length > 0) {
              setResponseStatusError(false);
              await handleGetAgenda();
            } else {
              setResponseStatusError(true);
            }

            const responseMsg = response?.data?.data?.fails?.map(
              (item) =>
                `${moment(item.day).format('DD/MM/yyyy')}: ${item.reason}`
            );

            setResponseMessage(responseMsg);
            setOpenAlertMessage(true);
          } else {
            setResponseStatusError(false);
            await handleGetAgenda();
            handleCloseModalScheduleCall(false);
          }
        })
        .catch((err) => {
          if (Object.keys(alreadyScaled).includes(err)) {
            setAlreadyScaled({
              ...alreadyScaled,
              [err]: true,
            });
            handleGetAgenda({ ...mainFilter, scale_name: null });
          }
        });
    }
  };
  useEffect(() => {
    if (data.length != 0) {
      getAgendaDetails({ agenda_id: data.success[0] });
    }
  }, [data]);

  useEffect(() => {
    if (data?.success?.length > 0 && data?.fails?.length > 0) {
      handleCloseModalScheduleCall(false);
    }
  }, [data]);
  useEffect(() => {
    handleGetAgenda({ ...mainFilter, scale_name: null });
  }, [data]);

  return (
    <div className={classes.root}>
      <Grid container alignItems="flex-start" spacing={0}>
        <Grid item xs>
          <CalendarFormCreate
            repeatType={repeatType}
            selectedDays={selectedDays}
            setSelectedDays={setSelectedDays}
            setYearMonth={setYearMonth}
            blockedDays={blockedDays}
            disabledDays={disabledDays}
            detailsSchedule={detailsSchedule}
          />
          {!errorDate || selectedDays.length > 0 ? (
            <>
              <TableLegendItem color="#1F437F">
                <div />
                <p id="legenda3">Dias em que o profissional já está escalado</p>
              </TableLegendItem>
              <TableLegendItem color="#646464">
                <div />
                Não há um horário para este dia
              </TableLegendItem>
            </>
          ) : (
            <ErrorMessage>
              <div />
              Selecione pelo menos um dia!
            </ErrorMessage>
          )}
        </Grid>
        <Grid item xs>
          <ScheduleForm
            onSubmit={onSubmit}
            handleCheckAgendaStatus={handleCheckAgendaStatus}
            yearMonth={yearMonth}
            setRepeatType={setRepeatType}
            loading={loadingAgenda}
          />
        </Grid>
      </Grid>
      <ModalCardDaysDetails
        agendaDetails={agendaDetails}
        loading={loadingAgendaDetails}
        openModalDayDetails={openModalDayDetails}
        handleCloseDayDetails={() => setModalDayDetails(false)}
      />
      <ModalResponseAlertSchedule
        openSendFinish={openAlertMessage}
        messageTitleAlert={
          responseStatusError
            ? 'Erro na criação de algum(s) plantão(ões)'
            : 'Plantões agendados com sucesso, exceto os seguintes:'
        }
        subtitles={responseMessage}
        handleCloseSendFinish={handleCloseSendFinish}
        error={responseStatusError}
      />
    </div>
  );
}

export default GridModalCalendarCombo;
