export const initialValues = {
  name: '',
  cpf: '',
  cell_phone: '',
  email: '',
  company: { id: '' },
  group: { id: '' },
  units: [],
  permissions: [],
};
