import appColors from 'utils/appColors';
import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100vh',
    minwidth: '100%',
    marginTop: 124,
    backgroundColor: appColors.BACKGROUND_COLOR,
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export const TableControl = styled.div`
  margin-right: 20px;
  margin-top: 50px;
`;

export const InputSelectHospital = styled.div`
  width: 200px;
  background-color: white;
  height: 40px;
  border-radius: 16px;
  @media (max-width: 1920px) {
    width: 350px;
  }
  @media (max-width: 1440px) {
    width: 200px;
  }
  @media (max-width: 1600px) {
    width: 250px;
  }
  @media (max-width: 1660px) {
    width: 250px;
  }
`;
