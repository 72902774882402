import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  deleteMany: {
    display: 'flex',
    gap: 10,
    justifyContent: 'end',
    marginBottom: 10,
    marginTop: -55,
    marginRight: 5,
  },
  expandButton: {
    color: 'white',
    fontSize: 13,
    width: 150,
    borderRadius: 4,
    background: '#0F83AD',
    textTransform: 'none',
    '&:disabled': {
      color: '#FFF',
      opacity: 0.5,
    },
    '&:hover': {
      border: ' 1px solid #0F83AD',
      color: '#0F83AD',
    },
  },
  deleteButton: {
    color: 'white',
    fontSize: 13,
    width: 100,
    borderRadius: 4,
    background: '#0F83AD',
    textTransform: 'none',
    '&:hover': {
      border: ' 1px solid #0F83AD',
      color: '#0F83AD',
    },
  },
  confirmButton: {
    color: 'white',
    width: 152,
    borderRadius: 4,
    background: '#0F83AD',
    textTransform: 'none',
    '&:hover': {
      border: ' 1px solid #0F83AD',
      color: '#0F83AD',
    },
    '&:disabled': {
      color: 'white',
      opacity: 0.5,
    },
  },
  cancelButton: {
    color: 'white',
    width: 152,
    borderRadius: 4,
    background: 'red',
    textTransform: 'none',
    '&:hover': {
      border: ' 1px solid red',
      color: 'red',
    },
  },
  selectAllButton: {
    width: 152,
    borderRadius: 4,
    textTransform: 'none',
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 600,
    borderBottom: '2px dashed #a2a5a8',
    marginBottom: 10,
    paddingTop: 5,
  },
  schedule: {
    fontSize: 18,
    fontWeight: 400,
  },
  remaining_vacancies: {
    fontSize: 12,
    marginTop: 16,
    '@media (max-width: 1024px)': {
      marginBottom: 10,
    },
  },
}));

export const TableContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 32px;
  @media (max-width: 1024px) {
    overflow-x: scroll;
  }
`;
