import React from 'react';
import { useStyles, InputContainer, InputItem } from './styles';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import { FastField, Field, Form, Formik } from 'formik';
import CustomTextField from 'shared/component/forms/CustomTextField';
import CustomMaskField from 'shared/component/forms/CustomMaskField';
import { initialValues } from './initialValues';
import HeaderForm from './components/HeaderForm';
import FooterButton from './components/FooterButton';
import AddressForm from 'shared/component/forms/AddressForm';
import CustomRichText from 'shared/customRichText';
import * as Yup from 'yup';
import validators from 'utils/validators';
import { useAuth } from 'hook/auth';
import CustomSelectCompanies from 'shared/component/forms/CustomSelectCompanies';

export default function SubsidiaryForm({
  formInitialValues = initialValues,
  isEdit = false,
  formSubmit = () => {},
}) {
  const classes = useStyles();
  const { userLogged } = useAuth();

  const [fileLogo, setFileLogo] = React.useState(null);

  const schema = Yup.object().shape({
    name: Yup.string().required('Obrigatório'),
    email: Yup.string().email('Email inválido').required('Obrigatório'),
    company: Yup.object()
      .shape({
        id: Yup.string(),
      })
      .test('company', 'Companhia obrigatória', (value) =>
        userLogged?.group?.id === 1 ? !!value : true
      ),
    social_name: Yup.string().required('Obrigatório'),
    cnpj: Yup.string()
      .min(13, 'CNPJ incompleto')
      .test(...validators.cnpjInvalid('CNPJ inválido'))
      .required('Obrigatório'),
    cell_phone: Yup.string()
      .min(10, 'Telefone Incompleto')
      .required('Obrigatório'),
    description: Yup.string().required('Obrigatório'),
    contract: Yup.string(),
    address: Yup.object().shape({
      code_post: Yup.string().required('Obrigatório'),
      street: Yup.string().required('Obrigatório'),
      number: Yup.number().typeError('Apenas números').required('Obrigatório'),
      district: Yup.string().required('Obrigatório'),
      complement: Yup.string().required('Obrigatório'),
      city: Yup.object().shape({
        id: Yup.number().typeError('Obrigatório').required('Obrigatório'),
        name: Yup.string().required('Obrigatório'),
        state: Yup.object().shape({
          id: Yup.number().typeError('Obrigatório').required('Obrigatório'),
          name: Yup.string().required('Obrigatório'),
        }),
      }),
    }),
  });

  const getFormattedLabel = (item) => {
    return {
      value: item.id,
      label: item.name,
    };
  };

  const handlePrint = (text) => {
    const newWindow = window.open('', '', 'width=800, height=500');

    const document = newWindow.document.open();

    const pageContent = `${
      '<!DOCTYPE html>' +
      '<html>' +
      '<head>' +
      '<meta charset="utf-8" />' +
      '<title>Termo de Privacidade</title>' +
      '</head>' +
      '<body>'
    }${text}</body></html>`;
    try {
      document.write(pageContent);
      document.close();
      newWindow.print();
      // newWindow.close();
    } catch (error) {
      newWindow.close();
    }
  };

  const handleFormSubmit = (data) => {
    formSubmit({ data, fileLogo });
  };

  return (
    <>
      <Typography className={classes.dataHospital}>
        {!isEdit ? 'Cadastrar nova filial' : 'Editar filial'}
      </Typography>
      <HeaderForm
        formInitialValues={formInitialValues}
        setFileLogo={setFileLogo}
      />
      <Formik
        onSubmit={handleFormSubmit}
        validationSchema={schema}
        initialValues={formInitialValues}
        validateOnBlur
        enableReinitialize
      >
        {({
          setFieldValue,
          setFieldTouched,
          handleChange,
          values,
          errors,
          isSubmitting,
        }) => (
          <Form>
            <Typography className={classes.dataHospital}>
              Dados da filial
            </Typography>
            <InputContainer>
              <InputItem style={{ flexGrow: 1.75 }}>
                <FastField
                  data-cy="input_nome"
                  value={values?.name}
                  label="Nome da filial"
                  style={{ width: '100%' }}
                  name="name"
                  variant="outlined"
                  component={CustomTextField}
                />
              </InputItem>
              {userLogged?.group?.id === 1 && (
                <InputItem>
                  <Field
                    name="company.id"
                    style={{ width: '100%' }}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    placeholder={'Companhia responsável'}
                    errors={errors && errors.company && errors.company.id}
                    label={'Companhia'}
                    value={
                      values && values.company && values.company.id !== ''
                        ? getFormattedLabel(values.company)
                        : null
                    }
                    handleChange={(e) => {
                      if (e) {
                        setFieldValue('company.id', e.value);
                        setFieldValue('company.name', e.label);
                      } else {
                        setFieldValue('company', null);
                      }
                    }}
                    menuList={() => ({
                      height: 116,
                      overflowY: 'auto',
                    })}
                    variant="outlined"
                    component={CustomSelectCompanies}
                  />
                </InputItem>
              )}
              <InputItem>
                <FastField
                  data-cy="input_email"
                  value={values?.email}
                  label="E-mail"
                  style={{ width: '100%' }}
                  name="email"
                  variant="outlined"
                  component={CustomTextField}
                />
              </InputItem>
            </InputContainer>
            <InputContainer>
              <InputItem>
                <FastField
                  data-cy="input_razao_social"
                  value={values?.social_name}
                  label="Razão Social"
                  style={{ width: '100%' }}
                  name="social_name"
                  variant="outlined"
                  component={CustomTextField}
                  marginTop={0}
                />
              </InputItem>
              <InputItem>
                <FastField
                  data-cy="input_cnpj"
                  value={values?.cnpj}
                  label="CNPJ"
                  style={{ width: '100%' }}
                  mask=""
                  format="##.###.###/####-##"
                  name="cnpj"
                  variant="outlined"
                  component={CustomMaskField}
                  onChange={handleChange}
                />
              </InputItem>
              <InputItem>
                <FastField
                  data-cy="input_cell_phone"
                  value={values?.cell_phone}
                  name="cell_phone"
                  id="cell_phone"
                  style={{ width: '100%' }}
                  label="Telefone"
                  mask=""
                  format="(##) #####-####"
                  variant="outlined"
                  component={CustomMaskField}
                  onChange={handleChange}
                />
              </InputItem>
            </InputContainer>

            <Typography className={classes.address}>Endereço</Typography>
            <AddressForm values={values} setFieldValue={setFieldValue} />

            <InputContainer style={{ marginTop: 25, height: 128 }}>
              <div style={{ height: 128, width: '100%' }}>
                <Typography className={classes.title_geolocation}>
                  Observações
                </Typography>
                <FastField
                  data-cy="input_description"
                  value={values?.description}
                  style={{ width: '100%' }}
                  name="description"
                  variant="outlined"
                  multiline
                  rows={3}
                  component={CustomTextField}
                />
              </div>
            </InputContainer>
            <InputContainer
              style={{
                marginTop: 40,
                marginRight: 0,
                height: 'auto',
              }}
            >
              <InputItem style={{ margin: 0 }}>
                <FastField
                  value={values?.description}
                  style={{ width: '100%' }}
                  name="contract"
                  variant="outlined"
                  label="Termo de privacidade"
                  multiline
                  rows={3}
                  component={CustomRichText}
                />
              </InputItem>
            </InputContainer>
            <div>
              <IconButton
                style={{ padding: 0 }}
                onClick={() =>
                  values?.contract &&
                  values?.contract !== '' &&
                  handlePrint(values?.contract)
                }
                aria-label="Imprimir"
              >
                <Tooltip title="Imprimir" arrow>
                  <PrintIcon color="primary" />
                </Tooltip>
              </IconButton>
            </div>
            <div data-cy="footerButton_btns" style={{ marginRight: 15 }}>
              <FooterButton
                id={values?.id}
                type="submit"
                loading={isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
