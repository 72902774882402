import React from 'react';
import { Typography, Icon } from '@material-ui/core';
import { useStyles, StyledDetailsReportCard } from './styles';
import appColors from 'utils/appColors';
import moment from 'moment';
import { SituationContainer } from 'page/Schedule/WeekSchedule/components/ModalProfessionalSchedule/LeftGridInfo/styles';
import SwitchSituation from '../SwitchSituation';

const DailyScaleCard = ({ title, details }) => {
  const classes = useStyles();

  const reasons = [
    {
      value: 0,
      label: 'Não compareceu',
    },
    {
      value: 1,
      label: 'Não cumpriu o plantão',
    },
    {
      value: 2,
      label: 'Se ausentou',
    },
    {
      value: 4,
      label: 'Atestado',
    },
    {
      value: 5,
      label: 'Substituição por Terceiro',
    },
    {
      value: 3,
      label: 'Outros',
    },
  ];

  function findLabelByValue(value) {
    const item = reasons.find((item) => item.value === value);
    return item ? item.label : 'Valor não encontrado';
  }

  return (
    <StyledDetailsReportCard elevation={0}>
      <Typography className="text-lg text-center mb-5">{title}</Typography>
      {details &&
        details.length > 0 &&
        details.map((detail, i) => (
          <div
            style={{
              margin: '10px 0',
              paddingTop: 10,
              paddingBottom: 10,
              borderColor: 'gray',
              backgroundColor: appColors.BACKGROUND_COLOR,
              borderRadius: 10,
              marginLeft: 10,
              marginRight: 10,
            }}
            key={i}
          >
            <div className="flex px-3">
              <div>
                <Typography className="text-sm pt-1 text-left">
                  {detail?.user?.name}
                </Typography>
                <Typography className="pt-1 text-left" style={{ fontSize: 12 }}>
                  {detail?.user?.regulation_agency} -{' '}
                  {detail?.user?.crm?.number} - {detail?.user?.crm?.state?.name}
                </Typography>
              </div>

              <div className="mx-6 flex">
                {detail.date_start && (
                  <div className="flex">
                    <Icon
                      style={{
                        marginRight: 5,
                        marginTop: 5,
                        fontSize: 15,
                      }}
                    >
                      query_builder
                    </Icon>
                    <Typography className="text-sm text-left pt-1">
                      Início
                      <br />
                      {moment(detail.date_start).format('DD/MM - HH[h]mm')}
                    </Typography>
                  </div>
                )}
                {detail.date_end && (
                  <div className="flex">
                    <Icon
                      style={{
                        marginRight: 5,
                        marginTop: 5,
                        fontSize: 15,
                      }}
                    >
                      query_builder
                    </Icon>
                    <Typography className="text-sm text-left pt-1">
                      Fim
                      <br />
                      {moment(detail.date_end).format('DD/MM - HH[h]mm')}
                    </Typography>
                  </div>
                )}
              </div>

              <SituationContainer
                style={{
                  marginTop: 10,
                }}
              >
                {detail?.situation && <SwitchSituation item={detail} />}
              </SituationContainer>
            </div>
            {detail?.validated_pendency > 0 && (
              <Typography
                style={{
                  textAlign: 'center',
                  color: 'red',
                  marginTop: 10,
                  fontSize: 14,
                }}
              >
                Motivo: {findLabelByValue(detail?.validated_pendency)}
                <br />
                {detail?.validated_description}
              </Typography>
            )}
          </div>
        ))}
      {details.length === 0 && (
        <div className="text-center mt-[12%]">
          <Icon style={{ fontSize: 100, color: '#D8D8DA' }}>content_paste</Icon>
          <Typography className={classes.emptyMessage}>
            Não há plantões definidos <br />
          </Typography>
        </div>
      )}
    </StyledDetailsReportCard>
  );
};

export default DailyScaleCard;
