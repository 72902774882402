import React, { memo } from 'react';
import { eachDayOfInterval } from 'date-fns';
import HeaderDays from '../HeaderDays';
import { useStyles } from './styles';
import moment from 'moment';

const ScheduleInfo = ({ schedule }) => {
  const classes = useStyles();

  const result = eachDayOfInterval({
    start: moment(schedule?.date_start).toDate(),
    end: moment(schedule?.date_end).toDate(),
  });

  return (
    <div className={classes.root}>
      <HeaderDays result={result} />
    </div>
  );
};

export default memo(ScheduleInfo);
