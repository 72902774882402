import React from 'react';
import { Button, Modal, Typography } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import useStyles from './style';

export function ModalEmailalert({
  openSendFinish = false,
  messageTitleAlert = '',
  options,
  handleCloseSendFinish = () => {},
  error,
  alert,
}) {
  const classes = useStyles();

  return (
    <Modal
      disableAutoFocus
      disableEnforceFocus
      open={openSendFinish}
      onClose={handleCloseSendFinish}
      className={classes.modalEmailalert}
    >
      <div data-cy="modal_success_send_alert" className={classes.modalRoot}>
        {!alert && error && (
          <HighlightOffIcon
            alt="alert completed"
            className={classes.iconError}
          />
        )}
        {!error && !alert && (
          <EmailIcon alt="alert completed" className={classes.iconSucess} />
        )}

        <p style={{ fontSize: '18px', marginBottom: 20 }}>
          {messageTitleAlert}
        </p>

        {options.map((item, i) => (
          <div
            key={i}
            style={{
              border: ' 1px solid #0F83AD',
              borderRadius: 4,
              marginBottom: 15,
            }}
          >
            <p className={classes.email}>
              Email : {''}
              {item.email}
            </p>
            <p className={classes.company}>
              Companhia : {''}
              {item && item.company.length > 15 ? (
                <Typography>{item.company.substring(0, 20)}...</Typography>
              ) : (
                <Typography>{item.company}</Typography>
              )}
            </p>
            <p className={classes.group}>
              Grupo : {''}
              {item.group}
            </p>
          </div>
        ))}
        <Button
          data-cy="btn_fechar_modal"
          className={classes.buttonClose}
          onClick={handleCloseSendFinish}
        >
          Fechar janela
        </Button>
      </div>
    </Modal>
  );
}
