import React, { useState, useContext } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import {
  useStyles,
  ContainerHeader,
  ContainerCenter,
  Container,
  ContainerBottom,
} from './styles';
import IconButton from '@material-ui/core/IconButton';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import { Button, Tooltip } from '@material-ui/core';
import { ModalCancelSchedule } from '../../ModalCancelSchedule';
import { ModalResponseAlert } from '../../ModalResponseAlert';
import { ModalSubstutionSchedule } from '../../ModalSubstutionSchedule';
import { ModalPaymentSchedule } from '../../ModalPaymentSchedule';
import { ModalAcceptValidation } from '../../ModalAcceptValidation';
import { ModalRecuseValidation } from '../../ModalRecuseValidation';
import { ModalTogglePriceSchedule } from '../../ModalTogglePriceSchedule';

import { ScheduleWeekPageContext } from 'page/Schedule/WeekSchedule/index';
import moment from 'moment';
import { ModalSignature } from '../../ModalSignature';
import { useAuth } from 'hook/auth';
import HasPermission from 'utils/checkPermission';
import { ModalImage } from '../../ModalImage';
// import { ModalConfirmSchedule } from '../../ModalScheduleCreate/GridModalCalendarCombo/ModalConfirmSchedule';

function RightGridInfo({ onClose, permissions }) {
  const classes = useStyles();

  const {
    agendaDetails = {},
    setOpenModalSchedule = {},
    selectedUnit = {},
    setAgendaDetailsData = () => {},
  } = useContext(ScheduleWeekPageContext);
  const [openModalValidate, setOpenModalValidate] = useState(false);
  const [openModalRecuse, setOpenModalRecuse] = useState(false);
  const [openModalToggle, setOpenModalToggle] = useState(false);
  const [openModalSignature, setOpenModalSignature] = useState(false);
  const [openModalImageCheckin, setOpenModalImageCheckin] = useState(false);
  const [openModalImageCheckout, setOpenModalImageCheckout] = useState(false);

  const [openModalCancellation, setOpenModalCancellation] = useState(false);
  const [openModalChangePro, setOpenModalChangePro] = useState(false);
  const [openModalCash, setOpenModalCash] = useState(false);

  const [openAlertMessage, setOpenAlertMessage] = useState(false);
  const [responseMessage, setResponseMessage] = useState();
  const [titleMessage, setTitleMessage] = useState();
  const [statusError, setStatusError] = useState(false);
  // const [submitConfirm, setSubmitConfirm] = useState(false);
  const handleCloseSendFinish = () => {
    setOpenAlertMessage(false);
    setStatusError(false);
    setOpenModalCancellation(false);
  };
  // const [openModalConfirmation, setOpenModalConfirmation] = useState(false);

  const handleFormatDate = (date) => {
    return moment.parseZone(date).format('DD/MM/YYYY HH:mm:ss');
  };

  const dataObj = moment(new Date()).format('MM');
  const dayModal = moment(agendaDetails?.date_end).format('MM');
  const [blockButtons, setBlockButtons] = useState(false);
  React.useEffect(() => {
    if (moment(dataObj).isAfter(dayModal)) setBlockButtons(true);
  }, []);

  const { userLogged } = useAuth();
  const HasPermissionChangedProfessional = HasPermission(
    permissions.changeProfessional
  );
  const HasPermissionChangeValue = HasPermission(permissions.changeValue);
  const statusAgendamento =
    agendaDetails?.situation.toString() +
    '' +
    +agendaDetails?.situation_status.toString();

  return (
    <div className="sm:ml-10 lg:ml-14 xl:ml-0 flex flex-col flex-1">
      <ContainerHeader>
        <div>
          <h3 className={classes.name_schedule}>
            {agendaDetails?.scale?.name}
          </h3>
          <h6 className={classes.name_unit}>{selectedUnit?.name}</h6>
        </div>
        <div>
          <IconButton
            onClick={() => {
              setOpenModalSchedule(false);
              setAgendaDetailsData(null);
            }}
            aria-label="delete"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </ContainerHeader>

      <ContainerCenter>
        <Container>
          {!!agendaDetails?.user_old &&
            !!agendaDetails?.user_old?.id !== agendaDetails?.user?.id && (
              <div>
                <span className={classes.scheduled_by_title}>Recebido de </span>
                <span className={classes.scheduled_by_name}>
                  {agendaDetails?.user_old?.name}
                </span>
              </div>
            )}
        </Container>
        <Container>
          {userLogged.group.id === 1 && statusAgendamento == 10 && (
            <p
              style={{
                padding: '10px 10px',
                background: '#FFFACD',
                fontSize: '12px',
                color: '#505255',
                borderRadius: '4px',
              }}
            >
              Profissional
              <b> FALTOU</b>, deseja registrar entrada do profissional?
            </p>
          )}
        </Container>

        <Container style={{ marginTop: 20 }}>
          <div className={classes.scheduled_by}>
            <p className={classes.scheduled_by_title}>Agendado por</p>
            <span className={classes.scheduled_by_name}>
              {agendaDetails?.user_created?.name}
            </span>
          </div>
          <div className={classes.edited_by}>
            <p className={classes.edited_by_title}>Editado por</p>
            <span className={classes.edited_by_name}>
              {agendaDetails?.user_updated?.name}
            </span>
          </div>
        </Container>

        <Container style={{ marginTop: 20 }}>
          <div className={classes.scheduled_by}>
            <p className={classes.scheduled_by_title}>Data de criação</p>
            <span className={classes.scheduled_by_name}>
              {moment(agendaDetails?.created_at).format(
                'DD/MM/yyyy [às] HH:mm:ss'
              )}
            </span>
          </div>
          <div className={classes.edited_by} style={{ marginLeft: 64 }}>
            <p className={classes.edited_by_title}>Validado por</p>
            <span className={classes.edited_by_name}>
              {agendaDetails?.user?.name}
            </span>
          </div>
        </Container>

        {agendaDetails?.validated_description && (
          <Container>
            <p
              style={{
                padding: '10px 10px',
                background: '#FFFACD',
                fontSize: '12px',
                color: '#505255',
                borderRadius: '4px',
              }}
            >
              <b>Observação: </b>
              {agendaDetails?.validated_description}
            </p>
          </Container>
        )}

        <Container style={{ marginTop: 30, justifyContent: 'space-between' }}>
          {!agendaDetails?.validated_status ? (
            <>
              <Button
                className={classes.validade_button}
                onClick={() => setOpenModalValidate(true)}
                color="primary"
                startIcon={<CheckIcon className={classes.icon} />}
                disabled={
                  [1, 2, 59, 7].indexOf(userLogged.group.id) === -1 &&
                  blockButtons
                }
              >
                Validar Plantão
              </Button>

              <Button
                disabled={
                  [1, 2, 59, 7].indexOf(userLogged.group.id) === -1 &&
                  blockButtons
                }
                className={classes.recuse_button}
                onClick={() => setOpenModalRecuse(true)}
                color="primary"
                startIcon={<NotInterestedIcon className={classes.icon} />}
              >
                Cancelar Plantão
              </Button>

              <Tooltip
                title={HasPermissionChangeValue ? '' : 'Você não tem permissão'}
                placement="bottom"
                arrow
              >
                <Button
                  id="BtnAlterarValor"
                  disabled={
                    [1, 2, 59, 7].indexOf(userLogged.group.id) === -1 &&
                    blockButtons
                  }
                  className={classes.toggle_shift}
                  style={{
                    color: HasPermissionChangeValue ? '#5AC17F' : '#666666',
                  }}
                  onClick={
                    HasPermissionChangeValue
                      ? () => setOpenModalToggle(true)
                      : undefined
                  }
                  color="primary"
                  startIcon={
                    <MonetizationOnOutlinedIcon className={classes.icon} />
                  }
                >
                  Alterar valor plantão
                </Button>
              </Tooltip>
            </>
          ) : (
            <>
              {agendaDetails?.validated_status === 1 ? (
                <div className={classes.validated_status_recuse}>
                  <NotInterestedIcon className={classes.icon} />
                  <div style={{ marginLeft: 10 }}>Plantão cancelado!</div>
                </div>
              ) : (
                <div className={classes.validated_status_accepted}>
                  <CheckIcon className={classes.icon} />
                  <div style={{ marginLeft: 10 }}>Plantão Validado!</div>
                </div>
              )}
            </>
          )}
        </Container>

        <Container className="flex flex-col py-4 pr-4">
          <div className={classes.container_checkin_cards}>
            <div className="flex flex-wrap gap-3 justify-between">
              <div className={classes.card_checking_checkout}>
                <p style={{ color: '#8B8E93', marginBottom: '8px' }}>
                  Check-in
                </p>
                <span style={{ color: '#505255', marginBottom: '25px' }}>
                  {agendaDetails?.user_date_start
                    ? handleFormatDate(agendaDetails?.user_date_start)
                    : '---'}
                </span>
              </div>

              <div
                className={classes.card_checking_checkout}
                style={{ marginLeft: 40 }}
              >
                <p
                  style={{
                    color: '#8B8E93',
                    marginBottom: '8px',
                  }}
                >
                  Check-out
                </p>
                <span style={{ color: '#505255', marginBottom: '25px' }}>
                  {agendaDetails?.user_date_end
                    ? handleFormatDate(agendaDetails?.user_date_end)
                    : '---'}
                </span>
              </div>

              <div
                style={{
                  flexDirection: 'column',
                  display: 'flex',
                }}
              >
                {agendaDetails?.signature !== null ? (
                  <Button
                    style={{
                      color: '#8B8E93',
                      textTransform: 'none',
                      padding: 0,
                    }}
                    onClick={() => {
                      setOpenModalSignature(true);
                    }}
                  >
                    Assinatura eletrônica
                  </Button>
                ) : (
                  <Button
                    disabled
                    style={{
                      color: '#8B8E93',
                      textTransform: 'none',
                      padding: 0,
                    }}
                  >
                    Assinatura eletrônica
                  </Button>
                )}
                <span style={{ color: '#505255', marginBottom: '25px' }}>
                  {agendaDetails?.signature !== null
                    ? 'Possui assinatura'
                    : 'Não possui assinatura'}
                </span>
              </div>
            </div>

            <div className="flex gap-3">
              {!!agendaDetails?.checkin_image && (
                <Button
                  className={classes.button}
                  onClick={() => {
                    setOpenModalImageCheckin(true);
                  }}
                >
                  Ver foto de check-in
                </Button>
              )}
              {!!agendaDetails?.checkout_image && (
                <Button
                  className={classes.button}
                  onClick={() => {
                    setOpenModalImageCheckout(true);
                  }}
                >
                  Ver foto de check-out
                </Button>
              )}
            </div>
          </div>
        </Container>
      </ContainerCenter>
      <ContainerBottom>
        {[1, 2, 59, 7].indexOf(userLogged.group.id) !== -1 ? (
          <>
            <Button
              data-cy="btn_cancelar_plantao"
              className={classes.button}
              onClick={() => setOpenModalCancellation(!openModalCancellation)}
            >
              Remover plantão
            </Button>
            {!agendaDetails?.validated_status && (
              <>
                <Tooltip
                  title={
                    HasPermissionChangedProfessional
                      ? ''
                      : 'Você não tem permissão'
                  }
                  placement="bottom"
                  arrow
                >
                  <Button
                    data-cy="btn_substituir_plantao"
                    className={classes.button}
                    onClick={
                      HasPermissionChangedProfessional
                        ? () => setOpenModalChangePro(!openModalChangePro)
                        : undefined
                    }
                  >
                    Substituir
                  </Button>
                </Tooltip>
                <Button
                  data-cy="btn_pagar_plantao"
                  className={classes.button}
                  onClick={() => setOpenModalCash(!openModalCash)}
                >
                  Pagar à vista
                </Button>
              </>
            )}
          </>
        ) : (
          <>
            <Button
              data-cy="btn_cancelar_plantao_disabled"
              disabled={blockButtons}
              className={classes.button}
              onClick={() => setOpenModalCancellation(!openModalCancellation)}
            >
              Cancelar plantão
            </Button>
            <Tooltip
              title={
                HasPermissionChangedProfessional ? '' : 'Você não tem permissão'
              }
              placement="bottom"
              arrow
            >
              <Button
                disabled={blockButtons}
                data-cy="btn_substituir_plantao_disabled"
                className={classes.button}
                onClick={
                  HasPermissionChangedProfessional
                    ? () => setOpenModalChangePro(!openModalChangePro)
                    : undefined
                }
              >
                Substituir
              </Button>
            </Tooltip>
            <Button
              data-cy="btn_pagar_plantao_disabled"
              disabled={blockButtons}
              className={classes.button}
              onClick={() => setOpenModalCash(!openModalCash)}
            >
              Pagar à vista
            </Button>
          </>
        )}
      </ContainerBottom>

      <ModalAcceptValidation
        open={openModalValidate}
        close={() => setOpenModalValidate(!openModalValidate)}
        setTitleMessage={setTitleMessage}
        setResponseMessage={setResponseMessage}
        setOpenAlertMessage={setOpenAlertMessage}
        setStatusError={setStatusError}
        title="Validar Plantão"
      />
      <ModalRecuseValidation
        open={openModalRecuse}
        close={() => setOpenModalRecuse(!openModalRecuse)}
        setTitleMessage={setTitleMessage}
        setResponseMessage={setResponseMessage}
        setOpenAlertMessage={setOpenAlertMessage}
        setStatusError={setStatusError}
        title="Cancelar Plantão"
      />
      <ModalTogglePriceSchedule
        open={openModalToggle}
        close={onClose}
        setResponseMessage={setResponseMessage}
        setOpenAlertMessage={setOpenAlertMessage}
        setStatusError={setStatusError}
        title="Alterar valor do Plantão"
      />

      <ModalSignature
        open={openModalSignature}
        close={() => setOpenModalSignature(!openModalSignature)}
        setResponseMessage={setResponseMessage}
        setOpenAlertMessage={setOpenAlertMessage}
        setStatusError={setStatusError}
        title="Assinatura eletrônica"
        image={agendaDetails?.signature}
      />
      <ModalImage
        open={openModalImageCheckin}
        close={() => setOpenModalImageCheckin(!openModalImageCheckin)}
        title="Imagem de Check-In"
        image={agendaDetails?.checkin_image}
      />
      <ModalImage
        open={openModalImageCheckout}
        close={() => setOpenModalImageCheckout(!openModalImageCheckout)}
        title="Imagem de Check-Out"
        image={agendaDetails?.checkout_image}
      />
      <ModalCancelSchedule
        open={openModalCancellation}
        close={() => setOpenModalCancellation(!openModalCancellation)}
        title="Remover agendamento"
        textButton="Confirmar"
        setTitleMessage={setTitleMessage}
        setResponseMessage={setResponseMessage}
        setOpenAlertMessage={setOpenAlertMessage}
        setStatusError={setStatusError}
      />
      <ModalSubstutionSchedule
        open={openModalChangePro}
        close={() => {
          setOpenModalChangePro(!openModalChangePro);
        }}
        title="Selecione o profissional substituto"
        placeholderSelect="Busque o profissional"
        textButton="Substituir"
        setStatusError={setStatusError}
        setTitleMessage={setTitleMessage}
        setResponseMessage={setResponseMessage}
        // setOpenModalConfirmation={setOpenModalConfirmation}
        setOpenAlertMessage={setOpenAlertMessage}
        // submitConfirm={submitConfirm}
        // setSubmitConfirm={setSubmitConfirm}
      />
      <ModalPaymentSchedule
        open={openModalCash}
        close={() => setOpenModalCash(!openModalCash)}
        title="Colocar plantão com pagamento à vista"
        textButton="Confirmar"
        setOpenAlertMessage={setOpenAlertMessage}
        setTitleMessage={setTitleMessage}
        setResponseMessage={setResponseMessage}
        setStatusError={setStatusError}
      />
      <ModalResponseAlert
        openSendFinish={openAlertMessage}
        messageTitleAlert={titleMessage}
        subtitle={responseMessage}
        handleCloseSendFinish={() => {
          handleCloseSendFinish();
          onClose();
        }}
        error={statusError}
      />
      {/* <ModalConfirmSchedule
          openSendFinish={openModalConfirmation}
          messageTitleAlert={responseMessage}
          onSubmit={() => {
            setResponseMessage('Profissional substituído com sucesso!');
            setOpenAlertMessage(true);
            setOpenModalConfirmation(false);
            setSubmitConfirm(true);
          }}
          handleCloseSendFinish={() => {
            handleCloseSendFinish();
            setOpenModalChangePro(false);
            onClose();
          }}
        /> */}
      {/* <MessageNotification
          openNotification={statusError}
          closeNotification={() => setStatusError(false)}
          type={'error'}
          message={responseMessage}
          vertical={10}
          horizontal="40vw"
        /> */}
    </div>
  );
}

export default RightGridInfo;
