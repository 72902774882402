const { makeStyles } = require('@material-ui/core');

const useStyles = makeStyles((theme) => ({
  text: {
    minHeight: 100,
    minWidth: 300,
  },
  error: {
    height: 40,
    textAlign: 'center',
    color: 'red',
  },
  submit: {
    color: 'white',
    background: theme.palette.primary.main,
    height: 50,
    marginBottom: 20,
    '&:hover': {
      background: theme.palette.primary.dark,
    },
  },
  forget_password: {
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
}));

export default useStyles;
