/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import MaskedInput from 'shared/component/maskedField/customMakField';
import {
  RadioInput,
  RadioInputGroup,
} from 'shared/component/maskedField/checkBox';
import CustomSelectCompanies from 'shared/component/forms/CustomSelectCompanies';
import CustomSelectUnits from 'shared/component/forms/CustomSelectUnits';
import CustomButton from 'shared/formButtonTerms/components/customButtom';
import Layout from 'shared/component/Layout';
import {
  InputContainer,
  FormContainer,
  InputItem,
  FormSectionTitle,
  FormFieldLabel,
} from './styles';
import CustomMaskField from 'shared/component/forms/CustomMaskField';
import { Container } from '@material-ui/core';

export const formInitialValues = {
  company: null,
  unit: null,
  employ: 'none',
  group: 'unit',
  type: '2',
  start_date: '',
  end_date: '',
};

const pdfFormSchema = Yup.object().shape({
  company: Yup.mixed().required('Selecione uma Companhia'),
  unit: Yup.mixed(),
  employ: Yup.string(),
  type: Yup.string(),
  group: Yup.string(),
  start_date: Yup.string(),
  // .test(
  //   'start_date',
  //   'Campo obrigatório',
  //   function (value) {
  //     return (
  //       this.parent.employ !== 'none' ||
  //       (this.parent.employ === 'none' && value)
  //     );
  //   }
  // ),
  end_date: Yup.string(),
  // .test(
  //   'start_date',
  //   'Campo obrigatório',
  //   function (value) {
  //     return (
  //       this.parent.employ !== 'none' ||
  //       (this.parent.employ === 'none' && value)
  //     );
  //   }
  // ),
});

const InvoicesForm = ({ onSubmit, submitText, loadingExportPdf }) => {
  return (
    <Container
      style={{
        backgroundColor: 'white',
        padding: '10px',
        borderRadius: '5px',
        boxShadow: '0px 5px 10px rgba(80, 82, 85, 0.1)',
      }}
    >
      <Formik
        initialValues={formInitialValues}
        validationSchema={pdfFormSchema}
        onSubmit={onSubmit}
        render={({
          handleSubmit,
          values,
          errors,
          setFieldValue,
          setFieldTouched,
          handleChange,
          isSubmitting,
        }) => (
          <FormContainer>
            <form onSubmit={handleSubmit}>
              {values?.employ === 'none' && (
                <>
                  <FormSectionTitle>Data de exportação</FormSectionTitle>
                  <InputContainer>
                    <InputItem>
                      <Field
                        id="start_date"
                        label="Data de Inicial"
                        style={{ width: '100%' }}
                        name="start_date"
                        mask=""
                        format="##/##/####"
                        variant="outlined"
                        component={CustomMaskField}
                        onChange={handleChange}
                      />
                    </InputItem>

                    <InputItem>
                      <Field
                        id="end_date"
                        label="Data de Final"
                        style={{ width: '100%' }}
                        name="end_date"
                        mask=""
                        format="##/##/####"
                        variant="outlined"
                        component={CustomMaskField}
                        onChange={handleChange}
                      />
                    </InputItem>
                  </InputContainer>
                </>
              )}

              <FormSectionTitle>Relatório de Faturamento</FormSectionTitle>
              <InputContainer style={{ marginTop: 20 }}>
                <InputItem>
                  <FormFieldLabel>Tipo de relatório</FormFieldLabel>
                  <RadioInputGroup
                    name="employ"
                    row
                    value={String(values.employ)}
                    handleChange={handleChange}
                  >
                    <RadioInput label="Faturamento" value="none" />
                    <RadioInput label="Faturamento Agrupado" value="employ" />
                  </RadioInputGroup>
                </InputItem>
              </InputContainer>

              <InputContainer>
                <InputItem>
                  <FormFieldLabel>Agrupamento</FormFieldLabel>
                  <RadioInputGroup
                    name="group"
                    row
                    value={String(values.group)}
                    handleChange={(event) => {
                      if (event.target.defaultValue === 'general') {
                        setFieldValue('unit', null);
                      }
                      handleChange(event);
                    }}
                  >
                    <RadioInput label="Unidade" value="unit" />
                    {values.employ === 'none' && (
                      <RadioInput label="Geral" value="general" />
                    )}
                  </RadioInputGroup>
                </InputItem>
              </InputContainer>

              <InputContainer>
                <InputItem>
                  <FormFieldLabel>Grupo de usuário</FormFieldLabel>
                  <RadioInputGroup
                    name="type"
                    row
                    value={String(values.type)}
                    handleChange={handleChange}
                  >
                    <RadioInput label="Médicos" value="2" />
                    <RadioInput label="Multi-profissionais" value="3" />
                    {values.employ === 'none' && (
                      <RadioInput label="Todos" value="1" />
                    )}
                  </RadioInputGroup>
                </InputItem>
              </InputContainer>

              <InputContainer>
                <InputItem>
                  <Field
                    name="company"
                    style={{ width: '100%' }}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    placeholder={'Companhia'}
                    errors={errors && errors.company}
                    label={'Companhia'}
                    value={values && values.company}
                    handleChange={(value) => {
                      if (value === null) {
                        setFieldValue('unit', null);
                        setFieldValue('company', null);
                      } else {
                        setFieldValue('unit', null);
                        setFieldValue('company', value);
                      }
                    }}
                    menuList={() => ({ height: 116, overflowY: 'auto' })}
                    variant="outlined"
                    component={CustomSelectCompanies}
                  />
                </InputItem>
              </InputContainer>

              {values.group === 'unit' && values?.company?.value && (
                <InputContainer>
                  <InputItem>
                    <Field
                      name="unit"
                      style={{ width: '100%' }}
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                      placeholder={'Selecione o hospital'}
                      label={'Selecione o hospital'}
                      errors={errors && errors?.unit}
                      handleChange={(data) => {
                        if (data?.value) setFieldValue('unit', data);
                        else setFieldValue('unit', null);
                      }}
                      value={values.unit}
                      companyId={values.company.value}
                      menuList={() => ({ height: 116, overflowY: 'auto' })}
                      variant="outlined"
                      component={CustomSelectUnits}
                      isClearable
                    />
                  </InputItem>
                </InputContainer>
              )}

              <InputContainer
                style={{
                  justifyContent: 'flex-end',
                  marginRight: 10,
                  marginTop: 20,
                }}
              >
                <CustomButton
                  type="submit"
                  style={{ width: 140 }}
                  label={submitText}
                  isLoading={loadingExportPdf || isSubmitting}
                />
              </InputContainer>
            </form>
          </FormContainer>
        )}
      />
    </Container>
  );
};

export default InvoicesForm;
