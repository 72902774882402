import { ChakraProvider } from '@chakra-ui/react';
import { ThemeProvider } from '@material-ui/core';
import React from 'react';
import theme from 'theme';
import { theme as chakraTheme } from 'theme/chakraTheme';
import { AuthProvider } from './auth';
import { ConfigProvider } from './config';

const AppProvider = ({ children }) => (
  <ChakraProvider theme={chakraTheme}>
    <AuthProvider>
      <ConfigProvider>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </ConfigProvider>
    </AuthProvider>
  </ChakraProvider>
);

export default AppProvider;
