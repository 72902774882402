import React, {
  memo,
  useMemo,
  // useEffect
} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { useStyles } from './styles';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import CheckIcon from '@material-ui/icons/Check';
import SwitchSituation from '../../../../../shared/component/SwitchSituation';
// import { useAgendaDetails } from 'hook/agenda/details';

const CardTooltip = ({
  children,
  color,
  // scaleData,
  schedule,
  selectedUnit,
  item,
  agenda,
}) => {
  const classes = useStyles();

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: `1px solid ${color}`,
    },
  }))(Tooltip);

  // const { data, getAgendaDetails } = useAgendaDetails();
  const formatHour = (hour) => moment(hour, 'HHmmss').format('HH:mm');
  const dateFormat = moment(agenda[0].date_start).format('DD/MM/YYYY');
  const mountShedulesPeriod = useMemo(
    () =>
      `${formatHour(schedule[0]?.hour_start)} - ${formatHour(
        schedule[0]?.hour_end
      )}`,
    [schedule]
  );

  // useEffect(() => {
  //   getAgendaDetails({ agenda_id: item?.id });
  // }, []);

  return (
    <HtmlTooltip
      placement="right"
      title={
        <div className={classes.container_tooltip}>
          <Typography className={classes.name_schedule}>
            {item?.user.name}
          </Typography>
          <Typography className={classes.hour_schedule}>
            {dateFormat} - {mountShedulesPeriod}
          </Typography>
          {/* {data?.hour_send_scheduling && !data?.hour_received_scheduling && (
            <>
              <Typography className={classes.hour_schedule}>
                {'Encaminhado às: '}
                {moment(data?.hour_send_scheduling).format('HH:mm')}
              </Typography>
              <Typography className={classes.hour_schedule}>
                {'De: '}
                {data?.user?.name}
              </Typography>
              <Typography className={classes.hour_schedule}>
                {'Para: '}
                {data?.user_new?.name}
              </Typography>
            </>
          )} */}
          {/* {data?.hour_send_scheduling && data?.hour_received_scheduling && (
            <>
              <Typography className={classes.hour_schedule}>
                {'Encaminhado às: '}
                {moment(data?.hour_send_scheduling).format('HH:mm')}
              </Typography>
              <Typography className={classes.hour_schedule}>
                {'De: '}
                {data?.user_old?.name}
              </Typography>
              <Typography className={classes.hour_schedule}>
                {'Para: '}
                {data?.user?.name}
              </Typography>
            </>
          )} */}
          <Typography className={classes.unit_name}>
            {selectedUnit?.name}
          </Typography>
          <Typography className={classes.unit_name}>
            <div>
              <SwitchSituation item={item} />
              {item?.validated_status && item?.validated_status !== 1 && (
                <div className={classes.validatedShift}>
                  <CheckIcon className={classes.icon} />
                  Plantão Validado
                </div>
              )}
            </div>
            {/* {data?.hour_received_scheduling && (
              <div>
                {moment(data?.hour_received_scheduling).format('HH:mm')}
              </div>
            )} */}
          </Typography>
        </div>
      }
      arrow
    >
      {children}
    </HtmlTooltip>
  );
};

export default memo(CardTooltip);
