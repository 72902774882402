import { useState } from 'react';
import api from 'service/api';
import { useAuth } from 'hook/auth';
import { useSubsidiaryDocuments } from './documents';

export const useSubsidiary = () => {
  const { requestIntercept } = useAuth();
  const { uploadImage } = useSubsidiaryDocuments();

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const getList = async (params) => {
    try {
      setLoading(true);
      const response = await requestIntercept(
        api.get,
        `/v1/companies/subsidiaries`,
        params
      );
      if (response.status !== 200) {
        throw response;
      }
      setData(response.data.data);
      setTotal(response.data.pagination.total_items);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getOne = async ({ id }) => {
    try {
      setLoading(true);
      const response = await requestIntercept(
        api.get,
        `/v1/companies/subsidiaries/${id}`
      );

      if (response.status !== 200) {
        throw response;
      }
      setData(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getCreate = async ({ data, fileLogo }) =>
    new Promise((resolve, reject) => {
      (async () => {
        try {
          setLoading(true);
          const response = await requestIntercept(
            api.post,
            `/v1/companies/subsidiaries`,
            data,
            null,
            true
          );
          if (response.status !== 200 && response.status !== 201) {
            throw response;
          }
          if (fileLogo) {
            uploadImage({
              subsidiary_id: response.data.data.id,
              file: fileLogo,
            });
          }
          setData(response.data.data);
          setStatus(response.status);
          setTimeout(() => {
            resolve();
            setLoading(false);
          }, 500);
        } catch (error) {
          setLoading(false);
          setStatus(error.status);
          reject(error);
        }
      })();
    });

  const getUpdate = async ({ id, data }) =>
    new Promise((resolve, reject) => {
      (async () => {
        try {
          setLoading(true);
          const response = await requestIntercept(
            api.put,
            `/v1/companies/subsidiaries/${id}`,
            data,
            null,
            true
          );
          if (response.status !== 200 && response.status !== 201) {
            throw response;
          }
          setStatus(response.status);
          setTimeout(() => {
            setLoading(false);
            resolve();
          }, 500);
        } catch (error) {
          setLoading(false);
          setStatus(error.status);
          reject(error);
        }
      })();
    });

  const getDelete = async ({ id }) => {
    try {
      setDeleteLoading(true);
      const response = await requestIntercept(
        api.delete,
        `/v1/companies/subsidiaries/${id}`
      );
      if (response.status !== 200) {
        throw response;
      }
      setData((oldData) => [...oldData.filter((item) => item.id !== id)]);
      setTotal(total - 1);
      setDeleteLoading(false);
    } catch (error) {
      setDeleteLoading(false);
    }
  };

  return {
    data,
    total,
    loading,
    status,
    deleteLoading,
    getList,
    getOne,
    getDelete,
    getCreate,
    getUpdate,
  };
};
