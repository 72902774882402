import React, { createContext, useState, useEffect, useRef } from 'react';
import useUnit from 'hook/unit';
import useScales from 'hook/scales';
import { useAuth } from 'hook/auth';
import { useDashboard } from 'hook/dashboard';
import useCompanies from 'hook/companies';
import Layout from 'shared/component/Layout';
import TopSelectsGrid from 'page/dashboardV2/components/TopSelectsGrid';
import CenterReportCardsGrid from 'page/dashboardV2/components/CenterReportCardsGrid';
import BottomDetailsReportGrid from 'page/dashboardV2/components/BottomDetailsReportGrid';
import { useConfig } from 'hook/config';
import { useAgenda } from 'hook/agenda';
import moment from 'moment';

export const DashboardV2PageContext = createContext({});

export default function DashboardV2Page() {
  const { userLogged } = useAuth();
  const pageRef = useRef(1);

  const {
    loading: loadingDashboard,
    dashboardData,
    getDashboardInfo,
  } = useDashboard();
  const { units, getUnits, setUnits } = useUnit();
  const {
    getFilterScales,
    scalesFilter,
    loading: loadingScales,
    setScalesFilter,
  } = useScales();
  const {
    loading: loadingAgenda,
    data: dataUnitAgenda,
    setData,
    getAgenda,
  } = useAgenda();
  const { config, setConfig } = useConfig();

  const { loadingUnits, dataUnits, totalUnits } = units;
  const { companies, getCompanies } = useCompanies();
  const { dataCompany, loadingCompanies, totalCompanies } = companies;

  const [company, setCompany] = useState();
  const [unitSelected, setUnitSelected] = React.useState({
    label: 'Todos',
    value: 'all',
  });

  const [dashboardFilter, setDashboardFilter] = useState({
    date_start: null,
    date_end: null,
    group_type: null,
    status: null,
    unit_id: null,
    company_id: userLogged.group.id !== 1 ? userLogged?.company?.id : null,
  });
  const [filterCompany, setFilterCompany] = React.useState({
    companyPage: 1,
    companyPerPage: 30,
    companySearch: '',
    companiesOptions: [],
  });
  const { companyPage, companyPerPage, companySearch } = filterCompany;

  const [filterUnit, filterStateUnit] = useState({
    unitPage: 1,
    unitPerPage: 30,
    unitSearch: '',
    unitsOptions: [],
  });
  const { unitPerPage, unitSearch } = filterUnit;

  useEffect(() => {
    if (userLogged.group.id === 1)
      getCompanies(companyPage, companyPerPage, companySearch);
  }, [companyPage, companySearch]);

  useEffect(() => {
    if (userLogged.group.id !== 1)
      getUnits(1, unitPerPage, unitSearch, userLogged?.company?.id);
  }, []);

  const companyPagination = () => {
    const totalPage = Math.ceil(totalCompanies / companyPerPage);
    companyPage < totalPage &&
      setFilterCompany({ ...filterCompany, companyPage: companyPage + 1 });
  };

  const topCompanyPagination = () => {
    companyPage > 1 &&
      setFilterCompany({ ...filterCompany, companyPage: companyPage - 1 });
  };

  const searchCompany = (companyValue) => {
    setFilterCompany({ ...filterCompany, companySearch: companyValue });
  };

  const handleChangeCompany = (val) => {
    setCompany(val);
    setUnits((oldState) => ({ oldState, dataUnits: [] }));
    setScalesFilter((oldState) => ({ oldState, dataScalesFilter: [] }));
    setData([]);
    setDashboardFilter((oldState) => ({ ...oldState, company_id: val?.value }));
    if (val && val.value) getUnits(1, unitPerPage, unitSearch, val?.value);
  };

  const handleChangeScale = (val) => {
    const dataAnterior = moment().subtract(1, 'days').toDate();
    const dataSeguinte = moment().add(1, 'days').toDate();

    setDashboardFilter((oldState) => ({ ...oldState, scale_id: val?.value }));
    getAgenda({
      date_start: moment(dataAnterior).format('YYYY-MM-DD'),
      date_end: moment(dataSeguinte).format('YYYY-MM-DD'),
      scale_name: null,
      unit_id: dashboardFilter?.unit_id,
      scale_id: val.value,
    });
  };

  const handleChangeUnit = (val) => {
    setUnitSelected(val);
    const standardCompany = dataUnits?.find(
      (unit) => unit.id === val?.value
    )?.company;
    if (standardCompany) {
      const standardCompanySelect = {
        label: standardCompany?.name,
        value: standardCompany?.id,
      };
      handleChangeCompany(standardCompanySelect);
    }

    setDashboardFilter((oldState) => ({ ...oldState, unit_id: val?.value }));
    getFilterScales(
      1,
      30,
      val?.value,
      moment().subtract(1, 'days').format('yyyy-MM-DD'),
      moment().add(1, 'days').format('yyyy-MM-DD')
    );
  };

  const searchUnit = (unitValue) => {
    filterStateUnit({ ...filterUnit, unitSearch: unitValue });
  };

  const unitPagination = () => {
    const nextPage = pageRef.current + 1;
    const totalPage = Math.ceil(totalUnits / unitPerPage);
    if (pageRef.current < totalPage) {
      filterStateUnit({ ...filterUnit, unitPage: nextPage });

      pageRef.current = nextPage;
    }
  };

  const topUnitPagination = () => {
    const previousPage = pageRef.current - 1;
    if (pageRef.current > 1) {
      filterStateUnit({ ...filterUnit, unitPage: previousPage });

      pageRef.current = previousPage;
    }
  };

  useEffect(() => {
    const { date_start, date_end } = dashboardFilter;
    if ((date_start, date_end)) getDashboardInfo(dashboardFilter);
  }, [dashboardFilter]);

  useEffect(() => {
    const jsonHospitais = localStorage.getItem('plantãoExtra@hospital');
    setConfig({
      ...config,
      hospitalData: jsonHospitais && JSON.parse(jsonHospitais),
    });
  }, []);
  return (
    <Layout
      title="TEMPO REAL"
      showToday
      isLoading={
        loadingDashboard || loadingAgenda || loadingScales || units.loadingUnits
      }
    >
      <div style={{ marginRight: 20, marginTop: 10, minHeight: '100vh' }}>
        <DashboardV2PageContext.Provider
          value={{
            userLogged,
            loadingCompanies,
            company,
            dataCompany,
            loadingUnits,
            dataUnits,
            unitSelected,
            dashboardData,
            loadingDashboard,
            handleChangeCompany,
            searchCompany,
            companyPagination,
            topCompanyPagination,
            handleChangeUnit,
            searchUnit,
            unitPagination,
            topUnitPagination,
            setDashboardFilter,
            dashboardFilter,
            scalesFilter,
            handleChangeScale,
            dataUnitAgenda,
          }}
        >
          <TopSelectsGrid />
          {scalesFilter && Object.keys(scalesFilter).length > 0 && (
            <>
              <CenterReportCardsGrid />
            </>
          )}
          {dataUnitAgenda && Object.keys(dataUnitAgenda).length > 0 && (
            <>
              <BottomDetailsReportGrid />
            </>
          )}
        </DashboardV2PageContext.Provider>
      </div>
    </Layout>
  );
}
