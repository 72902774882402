import React, { useContext } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { useStyles } from './styles';

import { ScheduleWeekPageContext } from 'page/Schedule/WeekSchedule/index';

function ModalButtons({ loading }) {
  const classes = useStyles();

  const { handleCloseModalScheduleCall = () => {} } = useContext(
    ScheduleWeekPageContext
  );

  let button = (
    <Button type="submit" className={classes.button_agendar}>
      Agendar
    </Button>
  );
  if (loading) {
    button = (
      <Button type="submit" className={classes.button_agendar} disabled>
        <CircularProgress color="primary" size={20} />
      </Button>
    );
  }

  return (
    <div className={classes.container_buttons}>
      <Button
        className={classes.button_cancel}
        onClick={handleCloseModalScheduleCall}
      >
        Cancelar
      </Button>
      {button}
    </div>
  );
}

export default ModalButtons;
