import React, { useState, useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import FooterButton from './FooterButton';
import { ContainerFormModal, InputContainer, InputItem } from './styles';
import { useRegulationAgencies } from 'hook/regulationAgencies/index';
import { ModalResponseAlert } from 'page/Schedule/WeekSchedule/components/ModalResponseAlert';
import CustomTextField from 'shared/component/forms/CustomTextField';

const FormRegulationAgencies = ({
  handleCloseModal,
  initialValues,
  handleGetPermissions,
}) => {
  const { loading, status, getUpdate, getCreate } = useRegulationAgencies();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);
  const [openModalSendNotification, setOpenModalSendNotification] =
    useState(false);

  useEffect(() => {
    if (status === 200 || status === 201 || error)
      setOpenModalSendNotification(true);
  }, [status, error]);

  const handleSubmit = (data) => {
    if (data?.id) {
      getUpdate({ id: data?.id, data: { name: data.name.toUpperCase() } })
        .then(() => {
          setMessage('Conselho regulador editado com sucesso!');
          setError(false);
          setTimeout(() => {
            handleGetPermissions();
          }, 500);
        })
        .catch((response) => {
          if (
            response.status === 400 &&
            response.data.msg.indexOf('agency already registered') !== -1
          )
            setMessage('Erro ao editar: conselho regulador já existente.');
          else setMessage('Erro ao editar o conselho regulador.');
          setError(true);
        });
    } else {
      getCreate({ data: { name: data.name.toUpperCase() } })
        .then(() => {
          setMessage('Conselho regulador criado com sucesso!');
          setError(false);
          setTimeout(() => {
            handleGetPermissions();
          }, 500);
        })
        .catch((response) => {
          if (
            response.status === 400 &&
            response.data.msg.indexOf('agency already registered') !== -1
          )
            setMessage('Erro ao criar: conselho regulador já existente.');
          else setMessage('Erro ao criar o conselho regulador.');
          setError(true);
        });
    }
  };

  const schema = Yup.object().shape({
    name: Yup.string().typeError('Obrigatório!').required('Obrigatório!'),
  });

  return (
    <ContainerFormModal>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={schema}
        initialValues={initialValues}
        validateOnBlur
        values
      >
        {() => (
          <Form>
            <InputContainer>
              <InputItem>
                <Field
                  data-cy="input_name"
                  label="Nome"
                  style={{ width: '100%' }}
                  name="name"
                  variant="outlined"
                  component={CustomTextField}
                />
              </InputItem>
            </InputContainer>
            <FooterButton
              loading={loading}
              handleCloseModal={handleCloseModal}
            />
          </Form>
        )}
      </Formik>
      <ModalResponseAlert
        messageTitleAlert={message}
        openSendFinish={openModalSendNotification}
        handleCloseSendFinish={handleCloseModal}
        error={error && status !== 400}
        alert={error && status === 400}
      />
    </ContainerFormModal>
  );
};

export default FormRegulationAgencies;
