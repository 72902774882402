import React from 'react';
import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import { colors } from 'theme/chakraTheme';

export const Stepper = ({ stepsLength = 0, position = 0 }) => {
  const steps = Array.from({ length: stepsLength });

  return (
    <Box w="100%" margin="0 auto">
      <Flex justifyContent="space-between">
        {steps.map((_, index, array) => (
          <Box
            width={index === array.length - 1 ? '5%' : '100%'}
            position="relative"
            key={String(index)}
          >
            <Box
              _after={
                index < array.length - 1
                  ? {
                      width: '100%',
                      height: '4px',
                      content: '""',
                      position: 'absolute',
                      bgColor: `${
                        position > index
                          ? colors.theme.light.primary
                          : colors.text.light.tertiary
                      }`,
                      top: '50%',
                      transform: 'translateY(-50%)',
                      zIndex: 0,
                    }
                  : { display: 'hidden' }
              }
            >
              <Stack
                align="center"
                justify="center"
                position="relative"
                width="44px"
                height="44px"
                borderRadius="50%"
                border={
                  position >= index
                    ? `2px solid ${colors.theme.light.primary}`
                    : `2px solid ${colors.text.light.tertiary}`
                }
                backgroundColor={`${
                  position > index ? colors.theme.light.primary : 'background'
                }`}
                zIndex={1}
              >
                <Text
                  lineHeight="16px"
                  color={
                    position == index
                      ? colors.text.light.primary
                      : position > index
                      ? 'white'
                      : colors.text.light.tertiary
                  }
                  fontWeight="bold"
                >
                  {index + 1}
                </Text>
              </Stack>
            </Box>
          </Box>
        ))}
      </Flex>
    </Box>
  );
};
