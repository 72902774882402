import logoPdf from './logoPdf';

export default function bodyEmployPdf(dataExportPdf) {
  let HeaderRelatory = '';
  HeaderRelatory += `
  <div style="display: flex; margin-top: 20px; margin-bottom: 40px; justify-content: space-between; align-items: center; color: white;">   
    ${logoPdf()}
  </div>`;

  HeaderRelatory += `<div class="divHeader">
      <div class="columnHeader">RELATÓRIO DE PROFISSIONAIS ATIVOS</div>

    </div>`;

  HeaderRelatory += `<div class="divHeader">
      <div class="columnLeft">Empresa</div>
      <div class="columnRight">${dataExportPdf.name || ''}</div>
    </div>`;

  HeaderRelatory += `<div class="divHeader">
      <div class="columnLeft">TIPO</div>
      <div class="columnRight">TOTAL</div>
    </div>`;

  HeaderRelatory += '</br>';

  let TableRelatory = '';

  dataExportPdf.groups.map((item) => {
    TableRelatory += '</br></table></div>  ';
    TableRelatory += `<div class="divHeader2">
      <div style="padding-top: 8px; margin-right: 2px" class="columnLeft3">Tipo</div>
      <div style="padding-top: 8px" class="columnRight3">Total</div>
    </div>`;
    TableRelatory += `<div class="divHeader2">
      <div style="padding-top: 8px; margin-right: 2px" class="columnLeft2">${
        item.name || ''
      }</div>
      <div style="padding-top: 8px" class="columnRight2">${
        item.quantity || ''
      }</div>
    </div>`;
  });

  TableRelatory += '</table>';

  const Style = `
    <style>
      table {
        font-family: Roboto, sans-serif;
        border: none;
        width: 100%;
      }
  
      td, th {
        border: none;
        text-align: left;
        padding: 5px 3px;
      }
  
      tr:nth-child(even) {
        background-color: rgba(70, 130, 180, 0.1);
        box-shadow: 0 0 0 1000px #DBEEF4 inset;
      }
  
      .divHeader {
        display: flex; width: 100%; margin-bottom: 5px; min-height: 40px; font-size: 20px; font-weight: bold; color: #18191A;
      }

      .columnLeft {
        width: 40%; background-color: #2aabe2; box-shadow: 0 0 0 1000px #2aabe2 inset; text-align: center; color: #18191A; padding-top: 5px; color: #18191A;
      }

      .columnHeader {
        width: 100%; background-color: #2aabe2; box-shadow: 0 0 0 1000px #2aabe2 inset; text-align: center; color: #18191A; padding-top: 5px; color: #18191A;
      }
  
      .columnRight {
        width: 60%; background-color: #DBEEF4; box-shadow: 0 0 0 1000px #DBEEF4 inset; text-align: center; color: #18191A; padding-top: 5px;
      }

      .divHeader2 {
        display: flex; width: 100%; margin-bottom: 5px; min-height: 20; font-size: 16px; font-weight: bold; color: #18191A;
      }
      
      .columnLeft2 {
        width: 40%; background-color: #E4E4E7; box-shadow: 0 0 0 1000px #E4E4E7 inset; text-align: center; padding-top: 5px; color: #18191A;
      }
  
      .columnRight2 {
        width: 60%; background-color: #E4E4E7; box-shadow: 0 0 0 1000px #E4E4E7 inset; text-align: center; color: #18191A; padding-top: 5px;
      }

      .columnLeft3 {
        width: 40%; background-color: #2aabe2; box-shadow: 0 0 0 1000px #2aabe2 inset; text-align: center; padding-top: 5px; color: #18191A;
      }
  
      .columnRight3 {
        width: 60%; background-color: #2aabe2; box-shadow: 0 0 0 1000px #2aabe2 inset; text-align: center; color: #18191A; padding-top: 5px;
      }
    </style>`;

  const bodyContent = HeaderRelatory + TableRelatory + Style;
  return bodyContent;
}
