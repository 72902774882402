import React, { useState, useEffect } from 'react';
import { Field, FastField, Form, Formik } from 'formik';
import * as Yup from 'yup';
import FooterButton from './FooterButton';
import {
  ContainerFormModal,
  InputContainer,
  InputItem,
  TextAgency,
} from './styles';
import { ModalResponseAlert } from 'page/Schedule/WeekSchedule/components/ModalResponseAlert';
import CustomSelectAgencies from 'shared/component/forms/CustomSelectAgencies';
import CustomTextField from 'shared/component/forms/CustomTextField';
import {
  RadioInput,
  RadioInputGroup,
} from 'shared/component/maskedField/checkBox';
import SwitchComponent from 'shared/component/switchComponent';
import { useGroupsUser } from 'hook/groupsUser';

const GroupForm = ({ handleCloseModal, initialValues, handleGetGroups }) => {
  const { loading, status, getUpdate, getCreate, getSyncAgency } =
    useGroupsUser();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);
  const [openModalSendNotification, setOpenModalSendNotification] =
    useState(false);

  useEffect(() => {
    if ((status === 200 || status === 201 || error) && message)
      setOpenModalSendNotification(true);
  }, [status, error, message]);

  const handleSubmit = (data) => {
    if (data?.id) {
      getUpdate({
        id: data?.id,
        data: { ...data, agency: null, regulation_agencies: data.agency },
      })
        .then(() => {
          if (data.need_regulation_agency) {
            const agencies = [
              ...(data.regulation_agencies?.map((item) => ({
                id: item?.value,
              })) ?? []),
            ];
            getSyncAgency({ id: data?.id, data: [...agencies] })
              .then(() => {
                setMessage('Grupo editado com sucesso!');
                setError(false);
                setTimeout(() => {
                  handleGetGroups();
                }, 500);
              })
              .catch(() => {
                setMessage('Erro ao editar o grupo.');
                setError(true);
              });
          } else {
            setMessage('Grupo editado com sucesso!');
            setError(false);
            setTimeout(() => {
              handleGetGroups();
            }, 500);
          }
        })
        .catch(() => {
          setMessage('Erro ao editar o grupo.');
          setError(true);
        });
    } else {
      getCreate({
        data: { ...data, regulation_agencies: null },
      })
        .then(({ id }) => {
          if (data.need_regulation_agency) {
            const agencies = [
              ...(data.regulation_agencies?.map((item) => ({
                id: item?.value,
              })) ?? []),
            ];
            getSyncAgency({ id, data: [...agencies] })
              .then(() => {
                setMessage('Grupo criado com sucesso!');
                setError(false);
                setTimeout(() => {
                  handleGetGroups();
                }, 500);
              })
              .catch(() => {
                setMessage('Erro ao criar o grupo.');
                setError(true);
              });
          } else {
            setMessage('Grupo criado com sucesso!');
            setError(false);
            setTimeout(() => {
              handleGetGroups();
            }, 500);
          }
        })
        .catch(() => {
          setMessage('Erro ao criar o grupo.');
          setError(true);
        });
    }
  };

  const getFormattedLabel = (item) => {
    return {
      value: item.id ?? item.value,
      label: item.name ?? item.label,
    };
  };

  const getInitialAgency = (agencies) => {
    return [...(agencies?.map((item) => getFormattedLabel(item)) ?? [])];
  };

  const schema = Yup.object().shape({
    name: Yup.string().typeError('Obrigatório!').required('Obrigatório!'),
    status: Yup.boolean().required('Obrigatório!'),
    type: Yup.number().required('Obrigatório!'),
    need_regulation_agency: Yup.boolean().nullable(),
    regulation_agencies: Yup.mixed().test(
      'needsAgency',
      'Obrigatório!',
      function (value) {
        return this.parent.need_regulation_agency ? value?.length > 0 : true;
      }
    ),
  });

  return (
    <ContainerFormModal>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={schema}
        initialValues={initialValues}
        validateOnBlur
        enableReinitialize
        values
      >
        {({ setFieldValue, setFieldTouched, errors, values }) => (
          <Form>
            <InputContainer>
              <InputItem>
                <Field
                  data-cy="input_name"
                  label="Nome"
                  style={{ width: '100%' }}
                  name="name"
                  variant="outlined"
                  component={CustomTextField}
                />
              </InputItem>
            </InputContainer>
            <InputContainer>
              <InputItem>
                <RadioInputGroup
                  name="type"
                  row
                  handleChange={(value) => {
                    setFieldValue('type', parseInt(value.target.value));
                  }}
                  value={values?.type}
                >
                  <RadioInput value={1} label="Admin" />
                  <RadioInput value={2} label="Profissional" />
                </RadioInputGroup>
              </InputItem>

              <InputItem>
                <FastField
                  value={values?.status}
                  name="status"
                  setFieldValue={setFieldValue}
                  active="Ativo"
                  inactive="Inativo"
                  component={SwitchComponent}
                />
              </InputItem>
            </InputContainer>

            <TextAgency>Necessita de conselho regulador?</TextAgency>
            <InputContainer>
              <InputItem>
                <FastField
                  value={values?.need_regulation_agency}
                  name="need_regulation_agency"
                  setFieldValue={setFieldValue}
                  active="Sim"
                  inactive="Não"
                  component={SwitchComponent}
                />
              </InputItem>
            </InputContainer>

            {!!values?.need_regulation_agency && (
              <InputContainer>
                <InputItem>
                  <Field
                    name="regulation_agencies"
                    style={{ width: '100%' }}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                    placeholder={'Selecione o conselho regulador'}
                    errors={errors && errors?.regulation_agencies}
                    value={
                      values &&
                      values?.regulation_agencies &&
                      values?.regulation_agencies.length > 0
                        ? getInitialAgency(values.regulation_agencies)
                        : null
                    }
                    handleChange={(data) => {
                      setFieldValue('regulation_agencies', data);
                    }}
                    label={'Conselho regulador'}
                    menuList={() => ({
                      height: 116,
                      overflowY: 'auto',
                    })}
                    variant="outlined"
                    isMulti
                    component={CustomSelectAgencies}
                  />
                </InputItem>
              </InputContainer>
            )}

            <FooterButton
              loading={loading}
              handleCloseModal={handleCloseModal}
            />
          </Form>
        )}
      </Formik>
      <ModalResponseAlert
        messageTitleAlert={message}
        openSendFinish={openModalSendNotification}
        handleCloseSendFinish={handleCloseModal}
        error={error}
      />
    </ContainerFormModal>
  );
};

export default GroupForm;
