import React from 'react';
import { Box, Button, Grid, Modal, Typography } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

export function ModalResponseAlertSchedule({
  openSendFinish = false,
  messageTitleAlert = '',
  subtitles = [],
  handleCloseSendFinish = () => {},
  error,
}) {
  return (
    <Modal
      disableAutoFocus
      disableEnforceFocus
      open={openSendFinish}
      onClose={handleCloseSendFinish}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        overflow: 'auto',
      }}
    >
      <div
        data-cy="modal_success_send_alert"
        style={{
          minWidth: '360px',
          maxWidth: '50vw',
          minHeight: '296px',
          maxHeight: '90vh',
          padding: '2rem',
          background: '#FFFFFF',
          borderRadius: '10px',
          display: 'flex',
          gap: 5,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          overflow: 'auto',
        }}
      >
        {error && (
          <HighlightOffIcon
            alt="alert completed"
            style={{
              width: '118px',
              height: '118px',
              marginBottom: '19px',
              color: 'red',
            }}
          />
        )}

        {!error && (
          <CheckCircleOutlineIcon
            alt="alert completed"
            style={{
              width: '118px',
              height: '118px',
              marginBottom: '19px',
              color: 'green',
            }}
          />
        )}

        {messageTitleAlert && (
          <Box mb={2}>
            <p style={{ fontSize: '18px' }}>{messageTitleAlert}</p>
          </Box>
        )}

        {subtitles.length > 0 && (
          <Box mb={2}>
            <Grid container spacing={2} justify="center">
              {subtitles.map((item, index) => (
                <Grid item xs direction="row" key={index}>
                  <EventBusyIcon
                    style={{
                      width: '20px',
                      height: '20px',
                      color: 'red',
                      marginRight: '5px',
                    }}
                  />
                  <Typography
                    style={{
                      fontSize: 12,
                      fontWeight: 400,
                      color: '#505255',
                      fontFamily: 'Poppins',
                    }}
                  >
                    {item}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}

        <Box>
          <Button
            data-cy="btn_fechar_modal"
            style={{
              background: '#0F83AD',
              width: '136px',
              height: '32px',
              fontSize: '12px',
              borderRadius: '4px',
              color: '#ffffff',
              textTransform: 'unset',
              marginTop: '32px',
            }}
            onClick={handleCloseSendFinish}
          >
            Fechar janela
          </Button>
        </Box>
      </div>
    </Modal>
  );
}
