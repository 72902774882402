import { icons } from 'asset';

export const Tabs = [
  {
    title: 'Plantões',
    info: 'Confira e exporte o histórico de plantões de cada profissional escalado',
    icon: icons.localHospital,
    link: '/relatorios/plantoes',
    permission: 'report/r',
  },
  {
    title: 'Check-In/Check-Out',
    info: 'Confira e exporte os registros de entrada e saída dos plantões',
    icon: icons.doneAll,
    link: '/relatorios/checkin',
    permission: 'report/r',
  },
  {
    title: 'Profissionais sem aplicativo',
    info: 'Confira os registros de profissionais que nào baixaram o App',
    icon: icons.download,
    link: '/relatorios/downloads',
    permission: 'report/r',
  },
  {
    title: 'Profissionais menos produtivos',
    info: 'Confira os registros dos profissionais menos produtivos',
    icon: icons.personRemove,
    link: '/relatorios/profissionais-improdutivos',
    permission: 'report/r',
  },
];
