/* eslint-disable no-unused-vars */
import React, {
  useState,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react';
import { Icon, IconButton, Modal } from '@material-ui/core';
import {
  useStyles,
  TitleModal,
  CloseModal,
  ContainerModal,
  ContainerTopModal,
} from './styles';
import FormRegulationAgencies from './FormRegulationAgencies';
import FormSyncUsers from './FormSyncUsers';
import FormClearSyncUsers from './FormClearSyncUsers';

export const ModalForm = forwardRef(({ handleGetPermissions }, ref) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const [initialValues, setInitialValues] = useState({
    name: null,
    permission: null,
  });

  const openModalPermissions = useCallback((item) => {
    setInitialValues(item);
    setOpen(true);
  }, []);

  useImperativeHandle(
    ref,
    () => {
      return {
        openModalPermissions,
      };
    },
    []
  );

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <ContainerModal className={classes.paper}>
        <ContainerTopModal>
          <TitleModal>
            {initialValues.name ? 'Editar Conselho' : 'Cadastrar Conselho'}
          </TitleModal>
          <CloseModal>
            <IconButton
              onClick={() => setOpen(false)}
              className={classes.closeButton}
            >
              <Icon>close</Icon>
            </IconButton>
          </CloseModal>
        </ContainerTopModal>
        <FormRegulationAgencies
          initialValues={initialValues}
          handleCloseModal={() => setOpen(false)}
          handleGetPermissions={handleGetPermissions}
        />
      </ContainerModal>
    </Modal>
  );
});

export const ModalSyncForm = forwardRef(({ syncItem }, ref) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);

  const openModalSync = useCallback((item) => {
    setValue(item);
    setOpen(true);
  }, []);

  useImperativeHandle(
    ref,
    () => {
      return {
        openModalSync,
      };
    },
    []
  );

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <ContainerModal className={classes.paper}>
        <ContainerTopModal>
          <TitleModal>Sincronizar informações de usuários</TitleModal>
          <CloseModal>
            <IconButton
              onClick={() => setOpen(false)}
              className={classes.closeButton}
            >
              <Icon>close</Icon>
            </IconButton>
          </CloseModal>
        </ContainerTopModal>
        <FormSyncUsers id={value?.id} handleCloseModal={() => setOpen(false)} />
      </ContainerModal>
    </Modal>
  );
});

export const ModalClearSyncForm = forwardRef(({ syncItem }, ref) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const openModalClearSync = useCallback(() => {
    setOpen(true);
  }, []);

  useImperativeHandle(
    ref,
    () => {
      return {
        openModalClearSync,
      };
    },
    []
  );

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <ContainerModal className={classes.paper}>
        <ContainerTopModal>
          <TitleModal>Limpar sincronização de usuários</TitleModal>
          <CloseModal>
            <IconButton
              onClick={() => setOpen(false)}
              className={classes.closeButton}
            >
              <Icon>close</Icon>
            </IconButton>
          </CloseModal>
        </ContainerTopModal>
        <FormClearSyncUsers handleCloseModal={() => setOpen(false)} />
      </ContainerModal>
    </Modal>
  );
});
