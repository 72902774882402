/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import {
  useStyles,
  SearchContainer,
  InputContainer,
  TableControl,
} from './styles';
import Layout from 'shared/component/Layout';
import { Button, Typography, Divider, Tooltip } from '@material-ui/core';
import { useSystems } from 'hook/system';
import DefaultTable from 'shared/component/DefaultTable';
import SearchInputDebounce from 'shared/component/forms/SearchInputDebounce';
import { AvatarNameComponent } from 'shared/component/AvatarNameComponent';
import '../../../shared/fixedtab.css';
import { useHistory } from 'react-router-dom';
import HasPermission from 'utils/checkPermission';
import usePersistedState from 'hook/usePersistedState';
import useUnit from 'hook/unit';

export default function SystemPageList() {
  const { push } = useHistory();
  const { units, getUnits } = useUnit();

  const permissions = {
    read: 'user/r',
    create: 'user/c',
    delete: 'user/d',
    update: 'user/u',
  };

  const columns = [
    { id: 'id', label: '#', minWidth: 10 },
    {
      id: 'name',
      label: 'Nome',
      minWidth: 100,
      render: (rowData, index) => (
        <AvatarNameComponent key={index} rowData={rowData} />
      ),
    },
    { id: 'cpf', label: 'CPF', minWidth: 100 },
    { id: 'group.name', label: 'Grupo', minWidth: 100 },
  ];

  const SpecialtiesNames = ({ specialties }) => {
    if (!specialties || !specialties.length > 0) return <div>...</div>;
    const concatedNames = specialties.map((item) => item?.name).join(' , ');
    return <div>{concatedNames}</div>;
  };
  const classes = useStyles();
  const {
    data: dataList,
    loading: loadingAdm,
    total: totalAdm,
    deleteLoading,
    getList,
    getDelete,
  } = useSystems();
  const [filterUnit, filterStateUnit] = React.useState({
    unitPage: 1,
    unitPerPage: 100,
    unitSearch: '',
  });
  const { unitPage, unitPerPage, unitSearch } = filterUnit;

  const [unitSelected, setUnitSelected] = React.useState('');

  const { loadingUnits, dataUnits, totalUnits } = units;

  React.useEffect(() => {
    getUnits(unitPage, unitPerPage, unitSearch);
  }, [unitPage.unitSearch]);
  const [filter, setFilter] = usePersistedState('plantaoextra@adminList', {
    page: 1,
    per_page: 10,
    search: '',
    status: 1,
    group_id: 2,
    unit_id: '',
  });

  useEffect(() => {
    getList(filter);
  }, [filter]);

  const handleChangePage = (page) => {
    setFilter({ ...filter, page: page });
  };
  useEffect(() => {
    if (dataList.length < 1) {
      setFilter({ ...filter, page: 1 });
    }
  }, []);

  const handleDeleteProfessional = (id) => {
    dataList.length > 1
      ? getDelete({ id, params: filter })
      : getDelete({
          id,
          params: {
            ...filter,
            page: 1,
          },
        });
  };

  const handleSearch = (search) => {
    typeof search === 'string'
      ? setFilter((old) => ({ ...old, page: 1, search }))
      : false;
  };

  const searchUnit = (unitValue) => {
    filterStateUnit({ ...filterUnit, unitSearch: unitValue });
  };

  const handleChangeUnit = (val) => {
    setUnitSelected(val);

    setFilter((oldState) => ({
      ...oldState,
      unit_id: val?.value,
    }));
  };
  useEffect(() => {
    const { date_start, date_end } = filter;
    if ((date_start, date_end)) getList(filter);
  }, [filter]);
  const unitPagination = () => {
    const totalPage = Math.ceil(totalUnits / unitPerPage);
    unitPage < totalPage &&
      filterStateUnit({ ...filterUnit, unitPage: unitPage + 1 });
  };

  const topUnitPagination = () => {
    unitPage > 1 && filterStateUnit({ ...filterUnit, unitPage: unitPage - 1 });
  };

  const formatUnitsOptions = dataUnits.map((company) => ({
    label: company.name,
    value: company.id,
  }));

  useEffect(() => {
    if (unitSelected?.value !== 0) {
      setFilter((oldState) => ({
        ...oldState,
        page: 1,
      }));
    }
  }, [unitSelected]);
  return (
    <Layout title="Sistema" showToday backArrow>
      <div id="fixed-tab">
        <Divider style={{ paddingTop: 2, marginRight: 20 }} />
      </div>
      <SearchContainer>
        <InputContainer>
          <SearchInputDebounce
            value={filter.search}
            className={classes.inputWidth}
            onChange={handleSearch}
            placeholder="Busque por nome"
            style={{
              alignItems: 'center',
              height: 48,
              width: '90%',
            }}
          />
        </InputContainer>
        <div className={classes.rowContainer}>
          <Tooltip
            title={
              HasPermission(permissions.create) ? '' : 'Você não tem permissão'
            }
            placement="bottom"
            arrow
          >
            <Button
              data-cy="btn_register"
              onClick={
                HasPermission(permissions.create)
                  ? () => push('/cadastros/sistemas/cadastrar')
                  : undefined
              }
              className={classes.registerButton}
            >
              <Typography className={classes.registerText}>
                Cadastrar
              </Typography>
            </Button>
          </Tooltip>
        </div>
      </SearchContainer>
      <TableControl data-cy="dataTable">
        <DefaultTable
          dataList={dataList}
          pageByProps={filter.page}
          permissions={permissions}
          columns={columns}
          loading={loadingAdm}
          totalList={totalAdm}
          redirectTo="/cadastros/sistemas/editar"
          perPage={filter?.per_page}
          handleChangePage={handleChangePage}
          deleteLoading={deleteLoading}
          onDeleteRequest={handleDeleteProfessional}
          showExportButton={false}
        />
      </TableControl>
    </Layout>
  );
}
