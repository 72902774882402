import React from 'react';
import Layout from 'shared/component/Layout';
import { NewProfessionalForm } from 'shared/forms/NewProfessionalForm';

export default function ProfessionalRegister() {
  return (
    <Layout title="Profissionais" showToday backArrow>
      <NewProfessionalForm />
    </Layout>
  );
}
