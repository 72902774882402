import { useState } from 'react';
import api from 'service/api';
import { useAuth } from 'hook/auth';

export const useRegulationAgencies = () => {
  const { requestIntercept } = useAuth();

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const getList = async (params) => {
    try {
      setLoading(true);
      const response = await requestIntercept(
        api.get,
        `/v2/admin/regulation_agencies`,
        params
      );
      if (response.status !== 200) {
        throw response;
      }
      setData(response.data.data);
      setTotal(response.data.pagination.total_items);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getOne = async ({ id }) => {
    try {
      setLoading(true);
      const response = await requestIntercept(
        api.get,
        `/v2/admin/regulation_agencies/${id}`
      );

      if (response.status !== 200) {
        throw response;
      }
      setData(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getCreate = async ({ data }) =>
    new Promise((resolve, reject) => {
      (async () => {
        try {
          setLoading(true);
          const response = await requestIntercept(
            api.post,
            `/v2/admin/regulation_agencies`,
            data,
            null,
            true
          );
          if (response.status !== 200 && response.status !== 201) {
            throw response;
          }
          setData(response.data.data);
          setStatus(response.status);
          setTimeout(() => {
            resolve();
            setLoading(false);
          }, 500);
        } catch (error) {
          setLoading(false);
          setStatus(error.status);
          reject(error);
        }
      })();
    });

  const getUpdate = async ({ id, data }) =>
    new Promise((resolve, reject) => {
      (async () => {
        try {
          setLoading(true);
          const response = await requestIntercept(
            api.put,
            `/v2/admin/regulation_agencies/${id}`,
            data,
            null,
            true
          );
          if (response.status !== 200 && response.status !== 201) {
            throw response;
          }
          setStatus(response.status);
          setTimeout(() => {
            setLoading(false);
            resolve();
          }, 500);
        } catch (error) {
          setLoading(false);
          setStatus(error.status);
          reject(error);
        }
      })();
    });

  const getDelete = async ({ id }) => {
    try {
      setDeleteLoading(true);
      const response = await requestIntercept(
        api.delete,
        `/v2/admin/regulation_agencies/${id}`
      );
      if (response.status !== 200) {
        throw response;
      }
      setData((oldData) => [...oldData.filter((item) => item.id !== id)]);
      setTotal(total - 1);
      setDeleteLoading(false);
    } catch (error) {
      setDeleteLoading(false);
    }
  };

  const getSynchronization = async ({ id, data }) => {
    new Promise((resolve, reject) => {
      (async () => {
        try {
          setLoading(true);
          const response = await requestIntercept(
            api.put,
            `/v2/admin/regulation_agencies/${id}/sincronization`,
            data,
            null,
            true
          );
          if (response.status !== 200 && response.status !== 201) {
            throw response;
          }
          setStatus(response.status);
          setTimeout(() => {
            setLoading(false);
            resolve();
          }, 500);
        } catch (error) {
          setStatus(error.status);
          setLoading(false);
          reject(error);
        }
      })();
    });
  };

  const getSyncClear = async ({ data }) => {
    new Promise((resolve, reject) => {
      (async () => {
        try {
          setLoading(true);
          const response = await requestIntercept(
            api.put,
            `/v2/admin/regulation_agencies/sincronization/clear`,
            data,
            null,
            true
          );
          if (response.status !== 200 && response.status !== 201) {
            throw response;
          }
          setStatus(response.status);
          setTimeout(() => {
            setLoading(false);
            resolve();
          }, 500);
        } catch (error) {
          setStatus(error.status);
          setLoading(false);
          reject(error);
        }
      })();
    });
  };

  return {
    data,
    total,
    loading,
    status,
    deleteLoading,
    getList,
    getOne,
    getDelete,
    getCreate,
    getUpdate,
    getSynchronization,
    getSyncClear,
  };
};
