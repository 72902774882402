import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  useStyles,
  SearchContainer,
  TableControl,
  InputContainer,
  ClearButtonContainer,
} from './styles';
import Layout from 'shared/component/Layout';
import DefaultTable from 'shared/component/DefaultTable';
import SearchInputDebounce from 'shared/component/forms/SearchInputDebounce';
import { useRegulationAgencies } from 'hook/regulationAgencies/index';
import { ModalForm, ModalSyncForm, ModalClearSyncForm } from './Components';
import { Button, Typography, Tooltip, IconButton } from '@material-ui/core';
import HasPermission from 'utils/checkPermission';
import SyncIcon from '@material-ui/icons/Sync';

export default function RegulationAgencies() {
  const classes = useStyles();

  const permissions = {
    read: 'country/r',
    create: 'country/c',
    delete: 'country/d',
    update: 'country/u',
  };

  const columns = [
    { id: 'id', label: '#', minWidth: 10 },
    { id: 'name', label: 'Nome', minWidth: 170 },
    {
      id: 'sync',
      label: 'Sincronizar',
      minWidth: 10,
      render: (rowData) => (
        <Tooltip
          title={
            HasPermission(permissions.delete)
              ? 'Sincronizar'
              : 'Você não tem permissão'
          }
        >
          <IconButton
            className={classes.button}
            onClick={() => handleOpenModalSync(rowData)}
            aria-label="delete"
          >
            <SyncIcon className={classes.icon} />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  const { data, total, loading, deleteLoading, getList, getDelete } =
    useRegulationAgencies();

  const [filter, setFilter] = useState({
    page: 1,
    per_page: 10,
    search: '',
    statusNotification: false,
  });

  const handleGetPermissions = useCallback(() => {
    getList(filter);
  }, [filter]);

  useEffect(() => {
    handleGetPermissions();
  }, [filter]);

  const handleSearch = (search) => {
    setFilter((old) => ({ ...old, page: 1, search }));
  };

  const handleChangePage = (page) => {
    setFilter((old) => ({ ...old, page }));
  };

  const handleDeleteUnit = (id) => {
    getDelete({ id });
  };

  const ModalPermissionsRef = useRef(null);

  const handleOpenModal = useCallback((item) => {
    ModalPermissionsRef.current.openModalPermissions(item);
  }, []);

  const ModalSyncRef = useRef(null);

  const handleOpenModalSync = useCallback((item) => {
    ModalSyncRef.current.openModalSync(item);
  }, []);

  const ModalClearSyncRef = useRef(null);

  const handleOpenModalClearSync = useCallback(() => {
    ModalClearSyncRef.current.openModalClearSync();
  }, []);

  return (
    <Layout title="Conselhos reguladores" showToday backArrow>
      <>
        <SearchContainer>
          <InputContainer>
            <SearchInputDebounce
              className={classes.inputWidth}
              onChange={handleSearch}
              placeholder="Busque por nome"
              style={{
                alignItems: 'center',
                height: 48,
                width: '90%',
              }}
            />
          </InputContainer>

          <Tooltip
            title={
              HasPermission(permissions.delete)
                ? 'Cadastrar'
                : 'Você não tem permissão'
            }
            placement="bottom"
            arrow
          >
            <Button
              data-cy="btn_register"
              onClick={
                HasPermission(permissions.delete)
                  ? () => handleOpenModal({ name: '' })
                  : null
              }
              className={classes.registerButton}
            >
              <Typography className={classes.registerText}>
                Cadastrar conselho
              </Typography>
            </Button>
          </Tooltip>
        </SearchContainer>

        <ClearButtonContainer>
          <Tooltip
            title={
              HasPermission(permissions.delete)
                ? 'Limpar'
                : 'Você não tem permissão'
            }
            placement="bottom"
            arrow
          >
            <Button
              data-cy="btn_clear"
              onClick={
                HasPermission(permissions.delete)
                  ? handleOpenModalClearSync
                  : null
              }
              className={classes.registerButton}
            >
              <Typography className={classes.registerText}>
                Limpar conselhos
              </Typography>
            </Button>
          </Tooltip>
        </ClearButtonContainer>

        <TableControl data-cy="dataTable_regulation_agencies">
          <DefaultTable
            permissions={permissions}
            dataList={data}
            columns={columns}
            loading={loading}
            deleteLoading={deleteLoading}
            totalList={total}
            pageByProps={filter.page}
            perPage={filter?.per_page}
            redirectTo="/"
            handleChangePage={handleChangePage}
            onDeleteRequest={handleDeleteUnit}
            showExportButton={false}
            handleOpenModal={handleOpenModal}
          />
        </TableControl>
        <ModalForm
          ref={ModalPermissionsRef}
          handleGetPermissions={handleGetPermissions}
        />
        <ModalSyncForm ref={ModalSyncRef} />
        <ModalClearSyncForm ref={ModalClearSyncRef} />
      </>
    </Layout>
  );
}
