import { Radio, RadioGroup, Stack } from '@chakra-ui/react';
import InputComponent from 'components/forms/input';
import SelectComponent from 'components/forms/select';
import useBanks from 'hook/banks';
import React from 'react';
import { useFormContext } from 'react-hook-form';

function BankDataStep() {
  const form = useFormContext();
  const doc_type = form.watch('bank.doc_type')?.value;
  const pix_type = form.watch('bank.pix_type');

  const {
    data: dataBanks,
    total: totalBanks,
    loading: loadingBanks,
    getList: getBanks,
  } = useBanks();

  return (
    <div className="flex flex-col pt-5 gap-4">
      <div className="flex justify-center py-5">
        <span>
          Observação: As informações bancárias NÃO são obrigatórias para o
          cadastro do profissional.
        </span>
      </div>

      <div className="flex flex-col gap-4">
        <span className="opacity-75 font-bold text-lg">Dados bancários</span>

        <div className="flex flex-1">
          <SelectComponent
            name="bank.bank"
            label="Banco"
            placeholder="Selecione o banco"
            isLoading={loadingBanks}
            total={totalBanks}
            getOptions={getBanks}
            options={dataBanks ?? []}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
          />
        </div>

        <div className="flex flex-1 gap-4">
          <div className="flex flex-[3]">
            <InputComponent
              name="bank.agency"
              label="Agência"
              placeholder="Agência"
            />
          </div>

          <div className="flex flex-1">
            <InputComponent
              name="bank.agency_check"
              label="Dígito da Agência"
              placeholder="Dígito da Agência"
            />
          </div>
        </div>

        <div className="flex flex-1 gap-4">
          <div className="flex flex-[3]">
            <InputComponent
              name="bank.account"
              label="Conta"
              placeholder="Conta"
            />
          </div>

          <div className="flex flex-1">
            <InputComponent
              name="bank.account_check"
              label="Dígito da Conta"
              placeholder="Dígito da Conta"
            />
          </div>
        </div>

        <div className="flex flex-1 gap-4">
          <div className="flex flex-1">
            <InputComponent
              name="bank.account_holder"
              label="Nome do titular"
              placeholder="Nome do titular"
            />
          </div>

          <div className="flex flex-1">
            <SelectComponent
              name="bank.type"
              label="Operação"
              placeholder="Selecione a operação"
              options={[
                { label: 'Corrente', value: 'Corrente' },
                { label: 'Poupança', value: 'Poupana' },
              ]}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <span className="opacity-75 font-bold text-lg">Tipo da Conta</span>

        <div className="flex flex-1 gap-4">
          <div className="flex flex-1">
            <SelectComponent
              name="bank.doc_type"
              label="Tipo da conta"
              placeholder="Tipo da conta"
              options={[
                { label: 'PESSOA JURÍDICA', value: 'CNPJ' },
                { label: 'PESSOA FÍSICA', value: 'CPF' },
              ]}
            />
          </div>

          <div className="flex flex-1">
            <InputComponent
              name="bank.doc_number"
              label={doc_type === 'CNPJ' ? 'CNPJ' : 'CPF'}
              placeholder={doc_type === 'CNPJ' ? 'CNPJ' : 'CPF'}
              mask={
                doc_type === 'CNPJ' ? '99.999.999/9999-99' : '999.999.999-99'
              }
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <span className="opacity-75 font-bold text-lg">
          Chave Pix (opcional)
        </span>

        <div>
          <RadioGroup
            onChange={(value) => {
              form.setValue('bank.pix_type', value);
            }}
            value={pix_type}
          >
            <Stack direction="row" gap={5}>
              <Radio value="email">Email</Radio>
              <Radio value="cellphone">Celular</Radio>
              <Radio value="cpf">CPF</Radio>
              <Radio value="cnpj">CNPJ</Radio>
            </Stack>
          </RadioGroup>
        </div>

        <div>
          {pix_type === 'email' && (
            <InputComponent
              name="bank.pix_key"
              label="Email"
              placeholder="Email"
              type="email"
            />
          )}

          {pix_type === 'cellphone' && (
            <InputComponent
              name="bank.pix_key"
              label="Telefone"
              placeholder="Telefone"
              mask="(99) 9 9999-9999"
            />
          )}

          {pix_type === 'cpf' && (
            <InputComponent
              name="bank.pix_key"
              label="CPF"
              placeholder="CPF"
              mask="999.999.999-99"
            />
          )}

          {pix_type === 'cnpj' && (
            <InputComponent
              name="bank.pix_key"
              label="CNPJ"
              placeholder="CNPJ"
              mask="99.999.999/9999-99"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default BankDataStep;
