import { Box, Text } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import _ from 'lodash';
import Select from 'react-select';
// import { IPagination } from '../../../hooks/interfaces/pagination'

const SelectComponent = ({
  name,
  label,
  options,
  optionAll,
  onValueChange,
  total,
  getOptions,
  customFilter = {},
  ...rest
}) => {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const error = _.get(errors, name);

  const [optionsList, setOptionsList] = useState([]);
  const [filter, setFilter] = useState({
    page: 1,
    per_page: 30,
    search: '',
    ...customFilter,
  });
  const pageRef = useRef(1);

  const searchItem = (inputValue) => {
    setFilter((oldState) => ({
      ...oldState,
      page: 1,
      search: inputValue,
    }));
  };

  const onMenuScrollToBottom = () => {
    const totalPage = Math.ceil(total / filter.per_page);
    const nextPage = pageRef.current + 1;
    if (filter.page < totalPage) {
      setFilter((oldState) => ({
        ...oldState,
        page: nextPage,
      }));

      pageRef.current = nextPage;
    }
  };

  // const onMenuScrollToTop = () => {
  //   if (pagination?.has_prev) {
  //     let prevPage = pageRef.current - 1;
  //     prevPage = prevPage > 0 ? prevPage : 1;
  //     setFilter(oldState => ({
  //       ...oldState,
  //       page: prevPage,
  //     }));

  //     pageRef.current = prevPage;
  //   }
  // };

  useEffect(() => {
    if (getOptions) getOptions(filter);
  }, [filter]);

  useEffect(() => {
    if (filter.page === 1)
      optionAll
        ? setOptionsList([optionAll, ...options])
        : setOptionsList(options);
    else setOptionsList((old) => [...old, ...options]);
  }, [options]);

  return (
    <Box display="flex" flexDirection="column" flex={1} w="100%">
      {label && (
        <Text fontSize="12px" pb="2px" fontWeight="500">
          {label}
        </Text>
      )}

      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange, ref, onBlur } }) => {
          return (
            <Select
              options={optionsList}
              ref={ref}
              onChange={(newOptions) => {
                onChange(newOptions);
                onValueChange && onValueChange(newOptions);
              }}
              onBlur={onBlur}
              isClearable
              isSearchable
              onInputChange={getOptions ? searchItem : () => {}}
              value={value}
              noOptionsMessage={() => 'Nenhum resultado encontrado'}
              loadingMessage={() => 'Carregando...'}
              onMenuClose={() => {
                pageRef.current = 1;
              }}
              onMenuScrollToBottom={
                getOptions ? onMenuScrollToBottom : () => {}
              }
              // onMenuScrollToTop={getOptions ? onMenuScrollToTop : () => {}}
              // chakraStyles={{
              //   control: (styles, { isDisabled }) => ({
              //     ...styles,
              //     w: controlWidth ?? '100%',
              //     opacity: isDisabled ? 0.9 : 1,
              //     backgroundColor: '#FFF',
              //     border: error ? '2px solid red' : '',
              //     borderColor: 'inherit',
              //   }),
              //   menu: (provided) => ({
              //     ...provided,
              //     zIndex: 9999,
              //     width: '100%',
              //     height: '150px',
              //   }),
              //   dropdownIndicator: (provided) => ({
              //     ...provided,
              //     bg: 'transparent',
              //     p: 0,
              //     w: 3,
              //     mx: 2,
              //     cursor: 'inherit',
              //   }),
              //   indicatorSeparator: () => ({
              //     display: 'none',
              //   }),
              // }}
              {...rest}
            />
          );
        }}
      />

      {error && (
        <Text
          pl="10px"
          textAlign="start"
          marginTop="4px !important"
          lineHeight="12px"
          fontSize="10px"
          color={'red.400'}
          textStyle="body.small"
        >
          {error?.message}
        </Text>
      )}
    </Box>
  );
};

export default SelectComponent;
