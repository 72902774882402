import React, { useContext, memo, useMemo } from 'react';
import CardTooltip from '../CardTooltip';
import { useStyles, LightTooltip } from './styles';
import { ScheduleWeekPageContext } from 'page/Schedule/WeekSchedule/index';
import { formatPrice } from 'utils/formatPrice';
import CardAddButton from '../CardAddButton';
import { StatusSituationType } from 'utils/StatusSituationType';
import CheckIcon from '@material-ui/icons/Check';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { Icon, Checkbox, Tooltip } from '@material-ui/core';
import HasPermission from 'utils/checkPermission';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import { useAuth } from 'hook/auth';
import SwitchSituation from 'shared/component/SwitchSituation';

const CardCellSchedule = ({
  isExpanded,
  isDeletingMany,
  selectedCards,
  setSelectedCards,
  item = {},
  index,
  scaleData = {},
  schedule = {},
  agenda = [],
  permissions,
}) => {
  const { userLogged } = useAuth();
  const classes = useStyles();

  const { selectedUnit = {}, handleOpenSchedule = () => {} } = useContext(
    ScheduleWeekPageContext
  );

  const getGroup = (name) => {
    if (!name || name === '') return <span>...</span>;
    return <span>{name}</span>;
  };

  const memoizedValueQuantity = useMemo(() => {
    const quantityAgenda = item?.agenda.length;
    const quantityRemaining = item?.quantity_professional - quantityAgenda;
    return quantityRemaining > 0 ? quantityRemaining : 0;
  }, [item]);

  return (
    <div className={classes.container}>
      {agenda.map((item, index) => {
        let color = StatusSituationType(
          classes,
          item.user_old,
          item.user_new,
          item.validated_description
        ).find(
          (x) => x.id === `${item.situation}${item.situation_status}`
        ).color;
        if (!color) color = 'white';
        return (
          <Tooltip
            title={
              HasPermission(permissions.update) ? '' : 'Você não tem permissão'
            }
            placement="bottom"
            arrow
          >
            <div
              id="borderTop"
              className={classes.day_cell}
              style={{
                borderTop: '5px solid ' + color,
                opacity: selectedCards?.find((sel) => sel === item.id)
                  ? 0.5
                  : 1,
                height: isExpanded && 120,
              }}
              data-cy="container_card_agendamento"
              key={index}
            >
              <CardTooltip
                item={item}
                scaleData={scaleData}
                schedule={schedule}
                selectedUnit={selectedUnit}
                agenda={agenda}
                color="#24B8EC"
              >
                <label
                  htmlFor={`checkbox${item.id}`}
                  className={classes.div_controler}
                  onClick={
                    HasPermission(permissions.create)
                      ? () =>
                          isDeletingMany
                            ? () => {}
                            : handleOpenSchedule({ item, scaleData })
                      : null
                  }
                >
                  <div>
                    <div className={classes.signatureContainer}>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <div className={classes.name}>{item?.user.name}</div>
                        {isDeletingMany && (
                          <Checkbox
                            id={`checkbox${item.id}`}
                            inputProps={{ 'aria-label': 'controlled' }}
                            color="primary"
                            checked={
                              !!selectedCards?.find((sel) => sel === item.id)
                            }
                            icon={<CircleUnchecked />}
                            checkedIcon={<CircleCheckedFilled />}
                            onChange={(event) => {
                              if (event.target.checked) {
                                setSelectedCards((old) => [...old, item.id]);
                              } else {
                                setSelectedCards((old) =>
                                  old.filter((selected) => selected !== item.id)
                                );
                              }
                            }}
                          />
                        )}
                      </div>
                      {scaleData.signature_required &&
                        item?.user?.signature && (
                          <LightTooltip
                            title={
                              item?.user?.signature && (
                                <img
                                  alt="signature"
                                  style={{ width: 160, height: 85 }}
                                  src={item?.user?.signature}
                                />
                              )
                            }
                            placement="top-end"
                          >
                            <Icon
                              data-cy="span_icon_assignment"
                              className={classes.assignmentIcon}
                            >
                              assignment
                            </Icon>
                          </LightTooltip>
                        )}
                    </div>

                    {isExpanded && (
                      <div className={classes.specialty}>
                        {getGroup(item?.user?.group?.name)}
                      </div>
                    )}
                  </div>

                  {isExpanded && (
                    <div>
                      {<SwitchSituation item={item} />}
                      {item?.validated_status &&
                        item?.validated_status !== 1 && (
                          <div className={classes.validatedShift}>
                            <CheckIcon className={classes.icon} />
                            Plantão Validado
                          </div>
                        )}
                    </div>
                  )}
                  <div className={classes.atSightIconContainer}>
                    {HasPermission(permissions.value) &&
                    userLogged?.company?.id !== 59 ? (
                      <div className={classes.value}>
                        Valor:{' '}
                        {formatPrice(item?.price <= -1 ? 0 : item?.price)}
                      </div>
                    ) : (
                      <></>
                    )}
                    {item && item.price !== item.price_default && (
                      <LightTooltip
                        title="Valor do plantão alterado"
                        placement=" bottom-e nd"
                      >
                        <Icon style={{ color: '#ffd700' }}>info</Icon>
                      </LightTooltip>
                    )}
                    {item && item.at_sight === 1 && (
                      <LightTooltip title="Pago à vista" placement="bottom-end">
                        <AttachMoneyIcon className={classes.atSightIcon} />
                      </LightTooltip>
                    )}
                  </div>
                </label>
              </CardTooltip>
            </div>
          </Tooltip>
        );
      })}
      {Array(memoizedValueQuantity)
        .fill()
        .map((item, i) => (
          <CardAddButton
            key={i}
            isExpanded={isExpanded}
            permissions={permissions}
            scaleData={scaleData}
            schedule={schedule}
            agenda={item?.agenda}
            index={index}
          />
        ))}
    </div>
  );
};

export default memo(CardCellSchedule);
