/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import {
  useColorModeValue,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Text,
  Image,
  Avatar,
} from '@chakra-ui/react';
import { BiImageAdd } from 'react-icons/bi';
import { useFormContext } from 'react-hook-form';
import _ from 'lodash';

export const handleImgError = (e) => {
  e.target.src = '/no-image.jpg';
};

export const ImageInputComponent = ({
  defaultVal,
  name,
  label,
  isRequired,
}) => {
  const [preview, setPreview] = useState(undefined);

  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  const error = _.get(errors, name);

  useEffect(() => {
    if (watch(name)?.length > 0 && typeof watch(name) === 'object') {
      const objectUrl = URL.createObjectURL(watch(name)[0]);
      setPreview(objectUrl);

      return () => URL.revokeObjectURL(objectUrl);
    }
    setPreview(undefined);
  }, [watch(name)]);

  return (
    <FormControl isRequired={isRequired} isInvalid={!!error} mb={5}>
      <FormLabel fontWeight="bold" mb={2} htmlFor={name}>
        {label}
      </FormLabel>

      <div className="relative flex flex-col items-center">
        <Avatar
          mb="15px"
          w="120px"
          h="120px"
          src={preview ?? defaultVal?.original}
        />
        <label htmlFor={name} style={{ display: 'block', width: '200px' }}>
          <Flex
            w="200px"
            h="40px"
            p="5px"
            bg={useColorModeValue(
              'theme.light.bodyInput',
              'theme.dark.bodyInput'
            )}
            cursor="pointer"
            transition="0.2s ease"
            align="center"
            justify="center"
            borderRadius="5px"
            _hover={{ opacity: 0.6 }}
            textTransform="uppercase"
            boxShadow="0px 2px 4px #00000050"
          >
            <Icon as={BiImageAdd} mr="5px" fontSize="25px" />
            <Text fontWeight="bold">Alterar foto</Text>
          </Flex>
        </label>
        <input
          id={name}
          type="file"
          hidden
          accept="image/*"
          {...register(name)}
        />
        {error && <FormErrorMessage>{String(error?.message)}</FormErrorMessage>}
      </div>
    </FormControl>
  );
};
